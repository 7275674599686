import { Nullable } from "@core/domain/types/nullable.type";
import {
    convertEmptyToUndefined,
    convertValueField,
} from "@core/domain/types/undefinable.type";
import { CreateTechnicalProposalIndicatorBody } from "@project/data/dto/technical-proposal/create-technical-proposal.body";
import { EditTechnicalProposalIndicatorBody } from "@project/data/dto/technical-proposal/edit-technical-proposal.body";
import { TechnicalProposalIndicatorDto } from "@project/data/dto/technical-proposal/technical-proposal.dto";
import {
    CreateIndicator,
    EditIndicator,
    Indicator,
} from "@project/domain/models/technical-proposals.model";
import { IndicatorFormValuesValidated } from "@project/presentation/components/project-form/technical-proposal/indicator/indicator-form.view";
import { validateSync } from "class-validator";
import { injectable } from "inversify";

@injectable()
export class IndicatorMapper {
    map(indicatorDto: TechnicalProposalIndicatorDto): Nullable<Indicator> {
        const errors = validateSync(indicatorDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }

        const indicator = new Indicator(
            indicatorDto.id,
            indicatorDto.activity,
            indicatorDto.estimated_indicator,
            indicatorDto.achieved_indicator,
            indicatorDto.verification_source,
            indicatorDto.status ?? "",
        );

        if (indicatorDto.activity_slots) {
            indicator.slots = indicatorDto.activity_slots;
        }

        if (indicatorDto.activity_name) {
            indicator.activityName = indicatorDto.activity_name;
        }

        return indicator;
    }

    mapFromFormToCreate(
        indicatorForm: IndicatorFormValuesValidated,
    ): CreateIndicator {
        if (!indicatorForm.activityId)
            throw new Error("activityId is required");

        return new CreateIndicator(
            indicatorForm.activityId,
            indicatorForm.estimatedIndicator,
            indicatorForm.achievedIndicator,
            indicatorForm.verificationSource,
        );
    }

    mapFromFormToEdit(
        indicatorForm: IndicatorFormValuesValidated,
    ): EditIndicator {
        if (!indicatorForm.id) throw new Error("Indicator id is required");
        if (!indicatorForm.activityId)
            throw new Error("activityId is required");

        const indicator = new EditIndicator(
            indicatorForm.id,
            indicatorForm.activityId,
            indicatorForm.estimatedIndicator,
            indicatorForm.achievedIndicator,
            indicatorForm.verificationSource,
        );

        return indicator;
    }

    mapToCreateDto(
        indicator: CreateIndicator,
    ): CreateTechnicalProposalIndicatorBody {
        return {
            activity: indicator.activityId,
            estimated_indicator: indicator.estimatedIndicator,
            achieved_indicator: indicator.achievedIndicator,
            verification_source: indicator.verificationFont,
        };
    }

    mapToEditDto(indicator: EditIndicator): EditTechnicalProposalIndicatorBody {
        return {
            id: indicator.id,
            activity: indicator.activityId,
            estimated_indicator: indicator.estimatedIndicator,
            achieved_indicator: indicator.achievedIndicator,
            verification_source: indicator.verificationFont,
            activity_slots: convertEmptyToUndefined(indicator.slots),
            status: convertValueField(indicator.status),
        };
    }
}
