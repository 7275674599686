import { DisabilityTypeEnumDto } from "@beneficiary/data/dto/disability-type-enum.dto";
import { DisabilityTypeEnum } from "@beneficiary/domain/models/disability-type.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class DisabilityTypesEnumMapper {
    map(
        disabilityTypeDto: DisabilityTypeEnumDto,
    ): Nullable<DisabilityTypeEnum> {
        switch (disabilityTypeDto) {
            case DisabilityTypeEnumDto.Dependence1:
                return DisabilityTypeEnum.Dependence1;
            case DisabilityTypeEnumDto.Dependence2:
                return DisabilityTypeEnum.Dependence2;
            case DisabilityTypeEnumDto.Dependence3:
                return DisabilityTypeEnum.Dependence3;
            case DisabilityTypeEnumDto.DependenceNotRecognized:
                return DisabilityTypeEnum.DependenceNotRecognized;
            case DisabilityTypeEnumDto.Disability33:
                return DisabilityTypeEnum.Disability33;
            case DisabilityTypeEnumDto.Disability65:
                return DisabilityTypeEnum.Disability65;
            case DisabilityTypeEnumDto.DisabilityNotRecognized:
                return DisabilityTypeEnum.DisabilityNotRecognized;

            default:
                return null;
        }
    }

    mapToDto(disabilityType: DisabilityTypeEnum): DisabilityTypeEnumDto {
        // eslint-disable-next-line default-case
        switch (disabilityType) {
            case DisabilityTypeEnum.Dependence1:
                return DisabilityTypeEnumDto.Dependence1;
            case DisabilityTypeEnum.Dependence2:
                return DisabilityTypeEnumDto.Dependence2;
            case DisabilityTypeEnum.Dependence3:
                return DisabilityTypeEnumDto.Dependence3;
            case DisabilityTypeEnum.DependenceNotRecognized:
                return DisabilityTypeEnumDto.DependenceNotRecognized;
            case DisabilityTypeEnum.Disability33:
                return DisabilityTypeEnumDto.Disability33;
            case DisabilityTypeEnum.Disability65:
                return DisabilityTypeEnumDto.Disability65;
            case DisabilityTypeEnum.DisabilityNotRecognized:
                return DisabilityTypeEnumDto.DisabilityNotRecognized;
        }
    }
}
