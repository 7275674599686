import { type Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class BudgetFinancialEntityDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;
}

export enum BudgetSpreadsheetValidationTypeEnumDto {
    Amount = "amount",
    Percentage = "percentage",
}

export class BudgetSpreadsheetColumnDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsEnum(BudgetSpreadsheetValidationTypeEnumDto)
    @IsNotEmpty()
    type!: BudgetSpreadsheetValidationTypeEnumDto;
}

export class BudgetSpreadSheetValidationDto {
    @IsEnum(BudgetSpreadsheetValidationTypeEnumDto)
    @IsNotEmpty()
    readonly type!: BudgetSpreadsheetValidationTypeEnumDto;

    @IsString()
    @IsOptional()
    validation_message!: string;

    @IsNumber()
    @IsNotEmpty()
    readonly value!: number;

    @IsNumber()
    @IsNotEmpty()
    readonly column!: number;

    @IsNumber()
    @IsOptional()
    readonly category!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    readonly group!: Nullable<number>;

    // Only in percentage type
    @IsBoolean()
    @IsNotEmpty()
    is_total_comparison!: boolean;

    @IsNumber()
    @IsOptional()
    category_to_compare!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    group_to_compare!: Nullable<number>;
}

export class BudgetSpreadsheetValueDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsNumber()
    @IsNotEmpty()
    value!: number;
}

export class BudgetSpreadsheetItemDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsArray()
    @Type(() => BudgetSpreadsheetValueDto)
    values!: BudgetSpreadsheetValueDto[];
}

export class BudgetSpreadsheetGroupDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsArray()
    @Type(() => BudgetSpreadsheetItemDto)
    items!: BudgetSpreadsheetItemDto[];

    @IsArray()
    @Type(() => BudgetSpreadSheetValidationDto)
    validations!: BudgetSpreadSheetValidationDto[];
}

export class BudgetSpreadsheetCategoryDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsArray()
    @Type(() => BudgetSpreadsheetGroupDto)
    groups!: BudgetSpreadsheetGroupDto[];

    @IsArray()
    @Type(() => BudgetSpreadsheetItemDto)
    items_without_group!: BudgetSpreadsheetItemDto[];

    @IsArray()
    @Type(() => BudgetSpreadSheetValidationDto)
    validations!: BudgetSpreadSheetValidationDto[];
}

export class BudgetSpreadsheetDto {
    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    description!: string;

    @IsNotEmpty()
    @Type(() => BudgetFinancialEntityDto)
    finantial_entity!: BudgetFinancialEntityDto;

    @IsArray()
    @Type(() => BudgetSpreadsheetColumnDto)
    columns!: BudgetSpreadsheetColumnDto[];

    @IsArray()
    @Type(() => BudgetSpreadsheetCategoryDto)
    categories!: BudgetSpreadsheetCategoryDto[];
}
