import { PaginatedDto } from "@core/data/dto/paginated.dto";
import { Type } from "class-transformer";
import { IsArray, IsNotEmpty, IsNumber, IsString } from "class-validator";

export class DistrictDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    city!: string;
}

export class DistrictsDto extends PaginatedDto {
    @IsArray()
    @Type(() => DistrictDto)
    results!: DistrictDto[];
}
