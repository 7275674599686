import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { BudgetColumnTypeEnumDto } from "@project/data/dto/budget/column/budget-column-type.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
} from "class-validator";

export class BudgetValidationDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsNotEmpty()
    @IsDateString()
    created!: string;

    @IsNotEmpty()
    @IsDateString()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsEnum(BudgetColumnTypeEnumDto)
    @IsOptional()
    type!: Nullable<BudgetColumnTypeEnumDto>;

    @IsString()
    @IsNotEmpty()
    value!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_total_comparison!: boolean;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsOptional()
    validation_message!: string;

    @IsNumber()
    @IsNotEmpty()
    budget!: number;

    @IsNumber()
    @IsNotEmpty()
    column!: number;

    @IsNumber()
    @IsNotEmpty()
    category!: number;

    @IsNumber()
    @IsOptional()
    group!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    category_to_compare!: Nullable<number>;

    @IsNumber()
    @IsOptional()
    group_to_compare!: Nullable<number>;
}
export class CategoryConceptBudgetDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNumber()
    @IsNotEmpty()
    category!: number;

    @IsNumber()
    @IsOptional()
    group!: Nullable<number>;
}
export class ColumnBudgetDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNumber()
    @IsNotEmpty()
    budget!: number;

    @IsEnum(BudgetColumnTypeEnumDto)
    @IsNotEmpty()
    type!: BudgetColumnTypeEnumDto;
}

export class CategoryGroupBudgetDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNumber()
    @IsNotEmpty()
    category!: number;
}

export class CategoryRowBudgetDto {
    @IsInt()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNumber()
    @IsNotEmpty()
    budget!: number;

    @IsArray()
    @Type(() => CategoryGroupBudgetDto)
    groups!: CategoryGroupBudgetDto[];
}

export class BudgetDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNotEmpty()
    @IsDateString()
    created!: string;

    @IsString()
    @IsNotEmpty()
    description!: string;

    @IsNumber()
    @IsNotEmpty()
    finantial_entity!: number;

    @IsArray()
    @IsOptional()
    @Type(() => ColumnBudgetDto)
    columns!: Nullable<ColumnBudgetDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => CategoryRowBudgetDto)
    categories!: Nullable<CategoryRowBudgetDto[]>;
}

export class BudgetsDto extends PaginatedDto {
    @IsArray()
    @Type(() => BudgetDto)
    results!: BudgetDto[];
}

export type BudgetsQuery = PaginatedQuery & {
    finantial_entity?: number;
};

export type BudgetColumnsQuery = PaginatedQuery & {
    budget: number;
};

export type BudgetCategoriesQuery = {
    budget: number;
};

export type CategoryGroupsQuery = {
    category: number;
};

export type BudgetCategoryConceptsQuery = {
    category: number;
};

export type BudgetValidationsQuery = {
    budget: number;
};

export class BudgetColumnsDto extends PaginatedDto {
    @IsArray()
    @Type(() => ColumnBudgetDto)
    results!: ColumnBudgetDto[];
}

export class BudgetCategoriesDto extends PaginatedDto {
    @IsArray()
    @Type(() => CategoryRowBudgetDto)
    results!: CategoryRowBudgetDto[];
}

export class BudgetCategoryGroupsDto extends PaginatedDto {
    @IsArray()
    @Type(() => CategoryGroupBudgetDto)
    results!: CategoryGroupBudgetDto[];
}

export class BudgetCategoryConceptsDto extends PaginatedDto {
    @IsArray()
    @Type(() => CategoryGroupBudgetDto)
    results!: CategoryConceptBudgetDto[];
}

export class BudgetValidationsDto extends PaginatedDto {
    @IsArray()
    @Type(() => BudgetValidationDto)
    results!: BudgetValidationDto[];
}

export interface CreateBudgetValidationBody {
    budget: BudgetValidationDto["budget"];
    name: BudgetValidationDto["name"];
    column: BudgetValidationDto["column"];
    type: Undefinable<NonNullable<BudgetValidationDto["type"]>>;
    validation_message: BudgetValidationDto["validation_message"];
    category: BudgetValidationDto["category"];
    group: Undefinable<NonNullable<BudgetValidationDto["group"]>>;
    value: BudgetValidationDto["value"];
    is_total_comparison: Undefinable<
        NonNullable<BudgetValidationDto["is_total_comparison"]>
    >;
    category_to_compare: Undefinable<
        NonNullable<BudgetValidationDto["category_to_compare"]>
    >;
    group_to_compare: Undefinable<
        NonNullable<BudgetValidationDto["group_to_compare"]>
    >;
}

export interface EditBudgetValidationBody {
    id: BudgetValidationDto["id"];
    budget: CreateBudgetValidationBody["budget"];
    name: CreateBudgetValidationBody["name"];
    column: CreateBudgetValidationBody["column"];
    type: CreateBudgetValidationBody["type"];
    validation_message: CreateBudgetValidationBody["validation_message"];
    category: CreateBudgetValidationBody["category"];
    group: CreateBudgetValidationBody["group"];
    value: CreateBudgetValidationBody["value"];
    is_total_comparison: CreateBudgetValidationBody["is_total_comparison"];
    category_to_compare: CreateBudgetValidationBody["category_to_compare"];
    group_to_compare: CreateBudgetValidationBody["group_to_compare"];
}
