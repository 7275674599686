import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { BudgetSpreadSheetValidationDto } from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetValidationTypeEnumMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-validation-type-enum.mapper";
import {
    BudgetSpreadsheetPercentageValidation,
    BudgetSpreadsheetValidation,
} from "@project/domain/models/budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetValidationMapper {
    constructor(
        @inject(BudgetSpreadsheetValidationTypeEnumMapper)
        private readonly budgetSpreadsheetValidationTypeEnumMapper: BudgetSpreadsheetValidationTypeEnumMapper,
    ) {}

    map(
        validationDto: BudgetSpreadSheetValidationDto,
    ): Nullable<BudgetSpreadsheetValidation> {
        const errors = validateSync(validationDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const validationType =
            this.budgetSpreadsheetValidationTypeEnumMapper.map(
                validationDto.type,
            );

        if (!validationType) return null;

        let percentageValidation: Undefinable<BudgetSpreadsheetPercentageValidation> =
            undefined;
        if (
            validationDto.type === "percentage" &&
            (validationDto.is_total_comparison !== null ||
                validationDto.category_to_compare !== null)
        ) {
            percentageValidation = new BudgetSpreadsheetPercentageValidation(
                validationDto.is_total_comparison,
                validationDto.category_to_compare ?? undefined,
                validationDto.group_to_compare ?? undefined,
            );
        }

        return new BudgetSpreadsheetValidation(
            validationDto.column,
            validationType,
            validationDto.value,
            validationDto.validation_message,
            percentageValidation,
        );
    }
}
