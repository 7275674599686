import { Nullable } from "@core/domain/types/nullable.type";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { EditEntity } from "@entity/domain/models/edit-entity.model";
import { Entity } from "@entity/domain/models/entity.model";
import { inject, injectable } from "inversify";

@injectable()
export class EditEntityUseCase {
    constructor(
        @inject(EntityRepository)
        private readonly entityRepository: EntityRepository,
    ) {}

    async execute(entity: EditEntity): Promise<Nullable<Entity>> {
        const editResult = await this.entityRepository.update(entity);

        if (editResult.isLeft()) {
            ToastManagerStore.error(editResult.getLeftOrThrow().message);
        }

        return editResult.fold(
            () => null,
            (editedEntity) => editedEntity,
        );
    }
}
