import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    ValidateNested,
} from "class-validator";
import { KanbanTaskDto } from "../kanban/task.dto";

export enum ActivityStatusEnumDto {
    toDo = "not_started",
    inProgress = "in_progress",
    inTesting = "validation",
    done = "done",
}

export interface ActivitySlotDto {
    name: string;
    value: string;
}

export class TechnicalProposalIndicatorDto {
    @IsInt()
    @IsNotEmpty()
    readonly id!: number;

    @IsDateString()
    @IsNotEmpty()
    readonly created!: string;

    @IsDateString()
    @IsNotEmpty()
    readonly modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    readonly is_active!: boolean;

    @IsString()
    @IsNotEmpty()
    readonly estimated_indicator!: string;

    @IsString()
    @IsNotEmpty()
    readonly achieved_indicator!: string;

    @IsString()
    @IsOptional()
    readonly status!: Undefinable<string>;

    @IsString()
    @IsNotEmpty()
    readonly verification_source!: string;

    @IsInt()
    @IsNotEmpty()
    readonly activity!: number;

    @IsString()
    @IsOptional()
    readonly activity_name!: Undefinable<string>;

    @IsArray()
    @ValidateNested({ each: true })
    @IsOptional()
    readonly activity_slots!: Undefinable<ActivitySlotDto[]>;
}

export class TechnicalProposalIndicatorsDto extends PaginatedDto {
    @IsArray()
    @IsNotEmpty()
    @Type(() => TechnicalProposalIndicatorDto)
    readonly results!: TechnicalProposalIndicatorDto[];
}

export interface IndicatorSearchFilters {
    project?: Undefinable<number>;
    objective?: Undefinable<number>;
    activity?: Undefinable<number>;
}

export type IndicatorsQuery = PaginatedQuery & {
    activity?: Undefinable<number>;
    project?: Undefinable<number>;
    objective?: Undefinable<number>;
    expand?: string;
};

export class TechnicalProposalActivityDto {
    @IsInt()
    @IsNotEmpty()
    readonly id!: number;

    @IsArray()
    @Type(() => TechnicalProposalIndicatorDto)
    readonly indicators_data!: TechnicalProposalIndicatorDto[];

    @IsDateString()
    @IsOptional()
    readonly created!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    readonly modified!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    readonly is_active!: Nullable<boolean>;

    @IsString()
    @IsNotEmpty()
    readonly name!: string;

    @IsString()
    @IsNotEmpty()
    readonly description!: string;

    @IsString()
    readonly needs!: string;

    @IsDateString()
    @IsOptional()
    readonly start_date_estimated!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    readonly end_date_estimated!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    readonly start_date_real!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    readonly end_date_real!: Nullable<string>;

    @IsInt()
    @IsNotEmpty()
    readonly objetive!: number;

    @IsArray()
    @IsInt({ each: true })
    @IsOptional()
    readonly employee!: Nullable<number[]>;

    @IsArray()
    @IsInt({ each: true })
    @IsOptional()
    readonly resources!: Nullable<number[]>;

    @IsEnum(ActivityStatusEnumDto)
    @IsOptional()
    readonly status!: Nullable<ActivityStatusEnumDto>;

    @IsInt()
    @IsOptional()
    readonly assigned_employee!: Nullable<number>;

    @IsString()
    @IsOptional()
    readonly deadline!: Nullable<string>;

    @IsOptional()
    readonly comments!: Nullable<string>;

    @IsInt()
    @IsOptional()
    readonly technical_proposal_id!: Nullable<number>;

    @IsArray()
    @IsOptional()
    @Type(() => KanbanTaskDto)
    readonly tasks_data!: Nullable<KanbanTaskDto[]>;
}

export interface EditProjectActivityBody {
    id: TechnicalProposalActivityDto["id"];

    name: TechnicalProposalActivityDto["name"];

    end_date_estimated: Undefinable<
        NonNullable<TechnicalProposalActivityDto["end_date_estimated"]>
    >;

    status: Undefinable<NonNullable<TechnicalProposalActivityDto["status"]>>;

    assigned_employee: Undefinable<
        NonNullable<TechnicalProposalActivityDto["assigned_employee"]>
    >;

    comments: Undefinable<
        NonNullable<TechnicalProposalActivityDto["comments"]>
    >;
}

export class TechnicalProposalActivitiesDto extends PaginatedDto {
    @IsArray()
    @IsNotEmpty()
    @Type(() => TechnicalProposalActivityDto)
    readonly results!: TechnicalProposalActivityDto[];
}

export interface ActivitySearchFilters {
    objetive?: Undefinable<number>;
    project?: Undefinable<number>;
}

export type ActivityQuery = PaginatedQuery & {
    objetive?: Undefinable<number>;
    project?: Undefinable<number>;
};

export class TechnicalProposalObjectiveDto {
    @IsNumber()
    @IsNotEmpty()
    readonly id!: number;

    @IsDateString()
    @IsNotEmpty()
    readonly created!: string;

    @IsDateString()
    @IsNotEmpty()
    readonly modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    readonly is_active!: boolean;

    @IsString()
    @IsNotEmpty()
    readonly name!: string;

    @IsString()
    @IsNotEmpty()
    readonly description!: string;

    @IsNumber()
    @IsNotEmpty()
    readonly technical_proposal!: number;

    @IsArray()
    @Type(() => TechnicalProposalActivityDto)
    readonly activities_data!: TechnicalProposalActivityDto[];
}

export class TechnicalProposalObjectivesDto extends PaginatedDto {
    @IsArray()
    @IsNotEmpty()
    @Type(() => TechnicalProposalObjectiveDto)
    readonly results!: TechnicalProposalObjectiveDto[];
}

export interface ObjectiveSearchFilters {
    technicalProposal?: Undefinable<number>;
    project?: Undefinable<number>;
}

export type ObjectiveQuery = PaginatedQuery & {
    technical_proposal?: Undefinable<number>;
    project?: Undefinable<number>;
};

export class TechnicalProposalDto {
    @IsNumber()
    @IsNotEmpty()
    readonly id!: number;

    @IsDateString()
    @IsNotEmpty()
    readonly created!: string;

    @IsDateString()
    @IsNotEmpty()
    readonly modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    readonly is_active!: boolean;

    @IsString()
    @IsNotEmpty()
    readonly overall_objetive!: string;

    @IsNumber()
    @IsNotEmpty()
    readonly project!: number;

    @IsArray()
    @IsOptional()
    @Type(() => TechnicalProposalObjectiveDto)
    readonly objetive_data!: Nullable<TechnicalProposalObjectiveDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    readonly documents_data!: Nullable<DocumentDto[]>;

    @IsArray()
    @IsOptional()
    readonly documents!: Nullable<number[]>;
}

export class TechnicalProposalsDto extends PaginatedDto {
    @IsArray()
    @IsNotEmpty()
    @Type(() => TechnicalProposalDto)
    readonly results!: TechnicalProposalDto[];
}

export type TechnicalProposalsQuery = PaginatedQuery & {
    project?: Undefinable<number>;
};
