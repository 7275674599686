import { FallbackError } from "@core/domain/errors/fallback.error";
import { Either } from "@core/domain/types/either";
import { GlobalEntityDatasource } from "@entity/data/datasources/global-entity.datasource";
import {
    GlobalEntity,
    GlobalEntitySearchFilters,
} from "@entity/domain/models/global-entity.model";
import { inject, injectable } from "inversify";

@injectable()
export class GlobalEntityRepository {
    constructor(
        @inject(GlobalEntityDatasource)
        private readonly globalEntityDatasource: GlobalEntityDatasource,
    ) {}

    async getAll(
        filters?: GlobalEntitySearchFilters,
    ): Promise<Either<FallbackError, GlobalEntity[]>> {
        return this.globalEntityDatasource.fetchAll(filters);
    }
}
