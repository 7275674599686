export enum DisabilityTypeEnum {
    Disability33 = "disability_33",
    Disability65 = "disability_65",
    Dependence1 = "dependence_1",
    Dependence2 = "dependence_2",
    Dependence3 = "dependence_3",
    DependenceNotRecognized = "dependence_not_recognized",
    DisabilityNotRecognized = "disability_not_recognized",
}

export class DisabilityType {
    constructor(
        public readonly id: DisabilityTypeEnum,
        public readonly label: string,
    ) {}
}
