import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedDto, PaginatedQuery } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Type } from "class-transformer";
import {
    ArrayMaxSize,
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
} from "class-validator";
import { ExpenseTypeEnumDto } from "./expense-type.dto";
import { InvestmentDto } from "./investment.dto";
import { MaintenanceDto } from "./maintenance.dto";
import { ManagementDto } from "./management.dto";
import { TravelDto } from "./travel.dto";

export class ExpenseDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsDateString()
    @IsNotEmpty()
    created!: string;

    @IsDateString()
    @IsNotEmpty()
    modified!: string;

    @IsBoolean()
    @IsNotEmpty()
    is_active!: boolean;

    @IsNumber()
    @IsNotEmpty()
    project!: number;

    @IsEnum(ExpenseTypeEnumDto)
    @IsNotEmpty()
    type_expense!: ExpenseTypeEnumDto;

    @IsArray()
    @ArrayMaxSize(1)
    @IsOptional()
    @Type(() => InvestmentDto)
    investment_data!: Nullable<InvestmentDto[]>;

    @IsArray()
    @ArrayMaxSize(1)
    @IsOptional()
    @Type(() => TravelDto)
    travel_data!: Nullable<TravelDto[]>;

    @IsArray()
    @ArrayMaxSize(1)
    @IsOptional()
    @Type(() => MaintenanceDto)
    maintenance_data!: Nullable<MaintenanceDto[]>;

    @IsArray()
    @ArrayMaxSize(1)
    @IsOptional()
    @Type(() => ManagementDto)
    management_data!: Nullable<ManagementDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;
}

export class ExpensesDto extends PaginatedDto {
    @IsArray()
    @Type(() => ExpenseDto)
    results!: ExpenseDto[];
}

export type ExpensesQuery = PaginatedQuery & {
    search?: Nullish<string>;
    type_expense?: Nullish<ExpenseTypeEnumDto>;
    project?: Nullish<number>;
};
