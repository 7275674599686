import {
    Undefinable,
    convertEmptyToUndefined,
    convertValueField,
} from "@core/domain/types/undefinable.type";
import {
    CreateProjectFinancialEntityBody,
    EditProjectBody,
    EditProjectFinancialEntityBody,
} from "@project/data/dto/edit-project.body";

import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { StatusEnumMapper } from "@project/data/mappers/status-enum.mapper";
import { EditProject } from "@project/domain/models/edit-project.model";
import {
    CreateProjectFinancialEntity,
    EditProjectFinancialEntity,
} from "@project/domain/models/project-financial-entities.model";
import { DesignPlanification } from "@project/domain/models/project.model";
import { EditProjectFormValuesValidated } from "@project/presentation/components/project-form/project-form.component";
import { inject, injectable } from "inversify";
import { ProjectFinancialEntityMapper } from "./project-financial-entity.mapper";
import { TerritorialScopeEnumMapper } from "./territorial-scope-enum.mapper";

@injectable()
export class EditProjectMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(ProjectFinancialEntityMapper)
        private readonly projectFinancialEntityMapper: ProjectFinancialEntityMapper,
        @inject(TerritorialScopeEnumMapper)
        private readonly territorialScopeEnumMapper: TerritorialScopeEnumMapper,
        @inject(CollectiveEnumMapper)
        private readonly collectiveEnumMapper: CollectiveEnumMapper,
        @inject(TypologyEnumMapper)
        private readonly typologyEnumMapper: TypologyEnumMapper,
        @inject(ActionLineEnumMapper)
        private readonly actionLineEnumMapper: ActionLineEnumMapper,
    ) {}

    mapFromProjectFormValues(
        projectId: number,
        projectForm: EditProjectFormValuesValidated,
    ): EditProject {
        let designPlanification: Undefinable<DesignPlanification> = undefined;
        if (
            Boolean(projectForm.general.designCreationDate) ||
            Boolean(projectForm.general.designResolutionDate)
        ) {
            designPlanification = new DesignPlanification(
                projectForm.general.designCreationDate,
                projectForm.general.designResolutionDate,
            );
        }

        const financialEntities: (
            | CreateProjectFinancialEntity
            | EditProjectFinancialEntity
        )[] = projectForm.budget.financialEntities.map((financialEntity) => {
            if (financialEntity.id) {
                return this.projectFinancialEntityMapper.mapToEdit(
                    financialEntity,
                );
            }
            return this.projectFinancialEntityMapper.mapToCreate(
                financialEntity,
            );
        });

        const newProject = new EditProject(
            projectId,
            projectForm.general.name,
            projectForm.general.year,
            projectForm.general.statusId,
        );

        newProject.notes = projectForm.general.notes;
        newProject.description = projectForm.general.description;
        newProject.territorialScopeId = projectForm.general.territorialScopeId;
        newProject.catalogueIds = projectForm.general.serviceIds;
        newProject.documents = projectForm.general.documents;
        newProject.isActive = projectForm.general.isActive;
        newProject.startDate = projectForm.general.projectInitDate;
        newProject.endDate = projectForm.general.projectEndDate;
        newProject.designPlanification = designPlanification;
        newProject.totalCost = projectForm.budget.totalCost;
        newProject.projectFinantialEntities = financialEntities;
        newProject.collectives = projectForm.general.collectives;
        newProject.actionLines = projectForm.general.actionLines;

        return newProject;
    }

    mapToDto(entityId: number, editProject: EditProject): EditProjectBody {
        const catalogue = editProject.catalogueIds?.map((catalogueDto) =>
            this.catalogueEnumMapper.mapToDto(catalogueDto),
        );
        let projectFinancialEntities: Undefinable<
            (
                | CreateProjectFinancialEntityBody
                | EditProjectFinancialEntityBody
            )[]
        > = undefined;
        if (editProject.projectFinantialEntities) {
            projectFinancialEntities = editProject.projectFinantialEntities.map(
                (projectFinancialEntity) => {
                    if (
                        projectFinancialEntity instanceof
                        EditProjectFinancialEntity
                    ) {
                        return this.projectFinancialEntityMapper.mapToEditDto(
                            editProject.id,
                            projectFinancialEntity,
                        );
                    }

                    return this.projectFinancialEntityMapper.mapToCreateDto(
                        editProject.id,
                        projectFinancialEntity,
                    );
                },
            );
        }

        const status = this.statusEnumMapper.mapToDto(editProject.statusId);

        const territorialScopeId =
            editProject.territorialScopeId &&
            this.territorialScopeEnumMapper.mapToDto(
                editProject.territorialScopeId,
            );

        const collectives = editProject.collectives?.map((collective) =>
            this.collectiveEnumMapper.mapToDto(collective),
        );

        const actionLines = editProject.actionLines?.map((actionLine) =>
            this.actionLineEnumMapper.mapToDto(actionLine),
        );

        const projectBody: EditProjectBody = {
            entity: entityId,
            name: editProject.name,
            year: editProject.year,
            is_active: editProject.isActive,
            notes: convertValueField(editProject.notes),
            description: convertValueField(editProject.description),
            territorial_scope: convertEmptyToUndefined(territorialScopeId),
            catalogue: catalogue,
            status,
            start_date: convertEmptyToUndefined(
                editProject.startDate?.toISODate(),
            ),
            end_date: convertEmptyToUndefined(editProject.endDate?.toISODate()),
            design_date: convertEmptyToUndefined(
                editProject.designPlanification?.designDate?.toISODate(),
            ),
            design_resolution_date: convertEmptyToUndefined(
                editProject.designPlanification?.designResolutionDate?.toISODate(),
            ),
            documents: editProject.documents?.map((document) => document.id),
            total_cost: convertEmptyToUndefined(
                editProject.totalCost?.toString(),
            ),
            collectives: collectives,
            action_lines: actionLines,
            project_finantial_entity: projectFinancialEntities,
        };

        return projectBody;
    }
}
