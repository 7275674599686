import { Pagination } from "@core/domain/models/pagination";
import { Nullable } from "@core/domain/types/nullable.type";
import type { Undefinable } from "@core/domain/types/undefinable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { BudgetValidationMapper } from "@project/data/mappers/budget/validations/budget-validation.mapper";
import {
    BudgetColumns,
    BudgetValidation,
    BudgetValidations,
    CreateBudgetValidation,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { GetAllBudgetColumnsByBudgetIdUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-columns-by-budget-id.usecase";
import { CreateBudgetValidationUseCase } from "@project/domain/usecases/budget/validations/create-budget-validation.usecase";
import { DeleteBudgetValidationUseCase } from "@project/domain/usecases/budget/validations/delete-budget-validation.usecase";
import { EditBudgetValidationUseCase } from "@project/domain/usecases/budget/validations/edit-budget-validation.usecase";
import { GetAllValidationsByBudgetIdUseCase } from "@project/domain/usecases/budget/validations/get-all-validations-by-budget-id.usecase";
import { BudgetValidationFormValuesValidated } from "@project/presentation/components/budget-form/budget-validations/budget-validation-form.component";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface BudgetValidationTable {
    id: number;
    column: string;
    value: Nullable<string>;
}

interface BudgetValidationsTable {
    budgetValidations: BudgetValidationTable[];
    count: number;
}
@injectable()
export class BudgetValidationsViewModel extends BaseViewModel {
    _budgetId: Nullable<number> = null;

    constructor(
        @inject(BudgetValidationMapper)
        private readonly budgetValidationMapper: BudgetValidationMapper,
        @inject(CreateBudgetValidationUseCase)
        private readonly createBudgetValidationUseCase: CreateBudgetValidationUseCase,
        @inject(DeleteBudgetValidationUseCase)
        private readonly deleteBudgetValidationUseCase: DeleteBudgetValidationUseCase,
        @inject(EditBudgetValidationUseCase)
        private readonly editBudgetValidationUseCase: EditBudgetValidationUseCase,
        @inject(GetAllValidationsByBudgetIdUseCase)
        private readonly getAllValidationsByBudgetIdUseCase: GetAllValidationsByBudgetIdUseCase,
        @inject(GetAllBudgetColumnsByBudgetIdUseCase)
        private readonly getAllBudgetColumnsByBudgetIdUseCase: GetAllBudgetColumnsByBudgetIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    @observable
    budgetValidations: BudgetValidations = new BudgetValidations([], 0);

    @observable
    budgetColumns: BudgetColumns = new BudgetColumns([], 0);

    @computed
    get budgetValidationsTable(): BudgetValidationsTable {
        return {
            count: this.budgetValidations.count,
            budgetValidations: this.budgetValidations.budgetValidations.map(
                (validation) => {
                    const columnFound = this.budgetColumns.budgetColumns.find(
                        (column) => column.id === validation.columnId,
                    );
                    return {
                        id: validation.id,
                        name: validation.name,
                        column: columnFound?.name ?? "",
                        value: validation.value ?? "",
                    };
                },
            ),
        };
    }

    @observable
    pagination: Pagination = new Pagination();

    @observable
    openAddBudgetValidationModal: boolean = false;

    @observable
    openEditBudgetValidationModal: boolean = false;

    @observable
    validationToEdit: Undefinable<BudgetValidation>;

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.getBudgetValidations(),
            this.getBudgetColumns(),
        ]);
    }

    async getBudgetValidations(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const result = await this.getAllValidationsByBudgetIdUseCase.execute(
            this._budgetId,
        );
        runInAction(() => {
            this.budgetValidations = result;
        });
    }

    async getBudgetColumns(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const budgetColumns =
            await this.getAllBudgetColumnsByBudgetIdUseCase.execute(
                this._budgetId,
            );

        runInAction(() => {
            this.budgetColumns = budgetColumns;
        });
    }

    handleOpenAddBudgetValidationModal(): void {
        runInAction(() => {
            this.openAddBudgetValidationModal = true;
        });
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        // TODO: add call to search by page
    }

    handleCloseAddBudgetValidationModal(): void {
        runInAction(() => {
            this.openAddBudgetValidationModal = false;
        });
    }

    handleOpenEditBudgetValidationModal(validation: BudgetValidation): void {
        runInAction(() => {
            this.validationToEdit = validation;
            this.openEditBudgetValidationModal = true;
        });
    }

    handleCloseEditBudgetValidationModal(): void {
        runInAction(() => {
            this.validationToEdit = undefined;
            this.openEditBudgetValidationModal = false;
        });
    }

    async createBudgetValidation(
        budgetId: number,
        form: BudgetValidationFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const budgetValidation: CreateBudgetValidation =
            this.budgetValidationMapper.mapFormToCreateBudgetValidation(
                budgetId,
                form,
            );

        const result =
            await this.createBudgetValidationUseCase.execute(budgetValidation);

        if (result) {
            await this.getBudgetValidations();
            this.handleCloseAddBudgetValidationModal();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async editBudgetValidation(
        budgetValidationId: number,
        form: BudgetValidationFormValuesValidated,
    ): Promise<void> {
        LoadLayoutStore.start();

        const budgetValidation = this.budgetValidationMapper.mapFromForm(
            budgetValidationId,
            this._budgetId!,
            form,
        );

        const result =
            await this.editBudgetValidationUseCase.execute(budgetValidation);

        if (result) {
            await this.getBudgetValidations();
            this.handleCloseEditBudgetValidationModal();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }

    async deleteBudgetValidation(id: number): Promise<void> {
        LoadLayoutStore.start();

        const deleted = await this.deleteBudgetValidationUseCase.execute(id);

        if (deleted) {
            await this.getBudgetValidations();
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();
    }
}
