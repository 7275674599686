/* eslint-disable max-lines */
import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { AddressDatasource } from "@entity/data/datasources/address.datasource";
import { AffiliationDatasource } from "@entity/data/datasources/affiliation.datasource";
import { AuditoryDatasource } from "@entity/data/datasources/auditory.datasource";
import { AuthorityManagerDatasource } from "@entity/data/datasources/authority-manager.datasource";
import { CollectiveDatasource } from "@entity/data/datasources/collective.datasource";
import { ContractTerminationDatasource } from "@entity/data/datasources/contract-termination.datasource";
import { ContractsDatasource } from "@entity/data/datasources/contracts.datasource";
import { EntityDatasource } from "@entity/data/datasources/entity.datasource";
import { GlobalEntityDatasource } from "@entity/data/datasources/global-entity.datasource";
import { PolicyProtocolDatasource } from "@entity/data/datasources/policy-protocol.datasource";
import { ProjectAuditoryDatasource } from "@entity/data/datasources/project-auditory.datasource";
import { PublicInterestDatasource } from "@entity/data/datasources/public-interest.datasource";
import { QualityCertificateDatasource } from "@entity/data/datasources/quality-certificate.datasource";
import { RegconRegisterDatasource } from "@entity/data/datasources/regcon-register.datasource";
import { RegisteredRecordDatasource } from "@entity/data/datasources/registered-record.datasource";
import { ServiceDatasource } from "@entity/data/datasources/service.datasource";
import { StatutoryTerritorialScopeDatasource } from "@entity/data/datasources/statutory-territorial-scope.datasource";
import { TitularityTypesDatasource } from "@entity/data/datasources/titularity-types.datasource";
import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import { ActionLinesMapper } from "@entity/data/mappers/action-lines.mapper";
import { AddressesMapper } from "@entity/data/mappers/address/addresses.mapper";
import { CreateAddressMapper } from "@entity/data/mappers/address/create-address.mapper";
import { EditAddressMapper } from "@entity/data/mappers/address/edit-address.mapper";
import { AdministrativeRecordScopeEnumMapper } from "@entity/data/mappers/administrative-record-scope-enum.mapper";
import { AdministrativeRecordScopeMapper } from "@entity/data/mappers/administrative-record-scope.mapper";
import { AffiliationMapper } from "@entity/data/mappers/affiliation/affiliation.mapper";
import { AffiliationsMapper } from "@entity/data/mappers/affiliation/affiliations.mapper";
import { CreateAffiliationMapper } from "@entity/data/mappers/affiliation/create-affiliation.mapper";
import { EditAffiliationMapper } from "@entity/data/mappers/affiliation/edit-affiliation.mapper";
import { AuthorityManagerMapper } from "@entity/data/mappers/authority-manager/authority-manager.mapper";
import { AuthorityManagersMapper } from "@entity/data/mappers/authority-manager/authority-managers.mapper";
import { CreateAuthorityManagerMapper } from "@entity/data/mappers/authority-manager/create-authority-manager.mapper";
import { EditAuthorityManagerMapper } from "@entity/data/mappers/authority-manager/edit-authority-manager.mapper";
import { CategoryTypeEnumMapper } from "@entity/data/mappers/category-type-enum.mapper";
import { CCAAEnumMapper } from "@entity/data/mappers/ccaa-enum.mapper";
import { CCAAMapper } from "@entity/data/mappers/ccaa.mapper";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import { CollectiveMapper } from "@entity/data/mappers/collective.mapper";
import { CollectivesMapper } from "@entity/data/mappers/collectives.mapper";
import { CrosscuttingScopeEnumMapper } from "@entity/data/mappers/crosscutting-scope-enum.mapper";
import { CrosscuttingScopesMapper } from "@entity/data/mappers/crosscutting-scope.mapper";
import { CreateEmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/create-employee-contract.mapper";
import { EditEmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/edit-employee-contract.mapper";
import { EmployeeContractMapper } from "@entity/data/mappers/employee/contracts/employee/employee-contract.mapper";
import { EmployeeContractsMapper } from "@entity/data/mappers/employee/contracts/employee/employee-contracts.mapper";
import { CreateFreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/create-freelance-contract.mapper";
import { EditFreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/edit-freelance-contract.mapper";
import { FreelanceContractMapper } from "@entity/data/mappers/employee/contracts/freelance/freelance-contract.mapper";
import { FreelanceContractsMapper } from "@entity/data/mappers/employee/contracts/freelance/freelance-contracts.mapper";
import { ContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/contract-termination.mapper";
import { ContractTerminationsMapper } from "@entity/data/mappers/employee/contracts/termination/contract-terminations.mapper";
import { CreateContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/create-contract-termination.mapper";
import { EditContractTerminationMapper } from "@entity/data/mappers/employee/contracts/termination/edit-contract-termination.mapper";
import { CreateVolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/create-volunteer-contract.mapper";
import { EditVolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/edit-volunteer-contract.mapper";
import { VolunteerContractMapper } from "@entity/data/mappers/employee/contracts/volunteer/volunteer-contract.mapper";
import { VolunteerContractsMapper } from "@entity/data/mappers/employee/contracts/volunteer/volunteer-contracts.mapper";
import { CreateEmployeeMapper } from "@entity/data/mappers/employee/create-employee.mapper";
import { DisabilityTypeEnumMapper } from "@entity/data/mappers/employee/disability-type-enum.mapper";
import { DisabilityTypeMapper } from "@entity/data/mappers/employee/disability-type.mapper";
import { EditEmployeeMapper } from "@entity/data/mappers/employee/edit-employee.mapper";
import { EmployeesCountMapper } from "@entity/data/mappers/employee/employees-count.mapper";
import { ProfessionalGroupEnumMapper } from "@entity/data/mappers/employee/professional-group-enum.mapper";
import { ProfessionalGroupMapper } from "@entity/data/mappers/employee/professional-group.mapper";
import { SubsidyReasonEnumMapper } from "@entity/data/mappers/employee/subsidy-reason-enum.mapper";
import { SubsidyReasonMapper } from "@entity/data/mappers/employee/subsidy-reason.mapper";
import { TerminationReasonEnumMapper } from "@entity/data/mappers/employee/termination-reason-enum.mapper";
import { TerminationReasonMapper } from "@entity/data/mappers/employee/termination-reason.mapper";
import { VolunteerInfoMapper } from "@entity/data/mappers/employee/volunteer-info.mapper";
import { EntitiesSummaryMapper } from "@entity/data/mappers/entities-summary.mapper";
import { EntitySummaryMapper } from "@entity/data/mappers/entity-summary.mapper";
import { EntityTerritorialScopeEnumMapper } from "@entity/data/mappers/entity-territorial-scope-enum.mapper";
import { EntityTerritorialScopeMapper } from "@entity/data/mappers/entity-territorial-scope.mapper";
import { MandateTerminationReasonEnumMapper } from "@entity/data/mappers/mandate-termination-reason-enum.mapper";
import { MandateTerminationReasonMapper } from "@entity/data/mappers/mandate-termination-reason.mapper";
import { PolicyProtocolTypeEnumMapper } from "@entity/data/mappers/policy-protocol-type-enum.mapper";
import { PolicyProtocolTypeMapper } from "@entity/data/mappers/policy-protocol-type.mapper";
import { PositionTypeEnumMapper } from "@entity/data/mappers/position-type-enum.mapper";
import { PositionTypeMapper } from "@entity/data/mappers/position-type.mapper";
import { CreatePublicInterestMapper } from "@entity/data/mappers/public-interest/create-public-interest.mapper";
import { EditPublicInterestMapper } from "@entity/data/mappers/public-interest/edit-public-interest.mapper";
import { PublicInterestMapper } from "@entity/data/mappers/public-interest/public-interest.mapper";
import { PublicInterestsMapper } from "@entity/data/mappers/public-interest/public-interests.mapper";
import { QualityCertificateTypeEnumMapper } from "@entity/data/mappers/quality-certificate-type-enum.mapper";
import { QualityCertificateTypeMapper } from "@entity/data/mappers/quality-certificate-type.mapper";
import { AuditoriesMapper } from "@entity/data/mappers/quality-framework/auditory/auditories.mapper";
import { AuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/auditory.mapper";
import { CreateAuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/create-auditory.mapper";
import { EditAuditoryMapper } from "@entity/data/mappers/quality-framework/auditory/edit-auditory.mapper";
import { CreatePolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/create-policy-protocol.mapper";
import { EditPolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/edit-policy-protocol.mapper";
import { PolicyProtocolMapper } from "@entity/data/mappers/quality-framework/policy-protocol/policy-protocol.mapper";
import { PolicyProtocolsMapper } from "@entity/data/mappers/quality-framework/policy-protocol/policy-protocols.mapper";
import { CreateProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/create-project-auditory.mapper";
import { EditProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/edit-project-auditory.mapper";
import { ProjectAuditoriesMapper } from "@entity/data/mappers/quality-framework/project-auditory/project-auditories.mapper";
import { ProjectAuditoryMapper } from "@entity/data/mappers/quality-framework/project-auditory/project-auditory.mapper";
import { CreateQualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/create-quality-certificate.mapper";
import { EditQualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/edit-quality-certificate.mapper";
import { QualityCertificateMapper } from "@entity/data/mappers/quality-framework/quality-certificate/quality-certificate.mapper";
import { QualityCertificatesMapper } from "@entity/data/mappers/quality-framework/quality-certificate/quality-certificates.mapper";
import { CreateRegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/create-regcon-register.mapper";
import { EditRegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/edit-regcon-register.mapper";
import { RegconRegisterMapper } from "@entity/data/mappers/quality-framework/regcon-register/regcon-register.mapper";
import { RegconRegistersMapper } from "@entity/data/mappers/quality-framework/regcon-register/regcon-registers.mapper";
import { CreateRegisteredRecordMapper } from "@entity/data/mappers/registered-record/create-registered-record.mapper";
import { EditRegisteredRecordMapper } from "@entity/data/mappers/registered-record/edit-registered-record.mapper";
import { RegisteredRecordMapper } from "@entity/data/mappers/registered-record/registered-record.mapper";
import { RegisteredRecordsMapper } from "@entity/data/mappers/registered-record/registered-records.mapper";
import { ServiceMapper } from "@entity/data/mappers/service.mapper";
import { ServicesMapper } from "@entity/data/mappers/services.mapper";
import { CreateStatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/create-statutory-territorial-scope.mapper";
import { EditStatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/edit-statutory-territorial-scope.mapper";
import { StatutoryTerritorialScopeMapper } from "@entity/data/mappers/statutory-territorial-scope/statutory-territorial-scope.mapper";
import { StatutoryTerritorialScopesMapper } from "@entity/data/mappers/statutory-territorial-scope/statutory-territorial-scopes.mapper";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import { ServiceProvidedMapper } from "@entity/data/mappers/supplier/service-provided.mapper";
import { SupplierIdentificationEnumMapper } from "@entity/data/mappers/supplier/supplier-identification-enum.mapper";
import { SupplierIdentificationTypeMapper } from "@entity/data/mappers/supplier/supplier-identification-type.mapper";
import { SupplierTypeMapper } from "@entity/data/mappers/supplier/supplier-type.mapper";
import { TitularityTypeEnumMapper } from "@entity/data/mappers/titularity-type-enum.mapper";
import { TitularityTypeMapper } from "@entity/data/mappers/titularity-type.mapper";
import { TypologiesMapper } from "@entity/data/mappers/typologies.mapper";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { TypologyMapper } from "@entity/data/mappers/typology.mapper";
import { AffiliationRepository } from "@entity/data/repositories/affiliation.repository";
import { AuditoryRepository } from "@entity/data/repositories/auditory.repository";
import { AuthorityManagerRepository } from "@entity/data/repositories/authority-manager.repository";
import { CollectiveRepository } from "@entity/data/repositories/collective.repository";
import { ContractTerminationRepository } from "@entity/data/repositories/contract-termination.repository";
import { ContractsRepository } from "@entity/data/repositories/contracts.repository";
import { EntityRepository } from "@entity/data/repositories/entity.repository";
import { GlobalEntityRepository } from "@entity/data/repositories/global-entity.repository";
import { PolicyProtocolRepository } from "@entity/data/repositories/policy-protocol.repository";
import { ProjectAuditoryRepository } from "@entity/data/repositories/project-auditory.repository";
import { PublicInterestRepository } from "@entity/data/repositories/public-interest.repository";
import { QualityCertificateRepository } from "@entity/data/repositories/quality-certificate.repository";
import { RegconRegisterRepository } from "@entity/data/repositories/regcon-register.repository";
import { RegisteredRecordRepository } from "@entity/data/repositories/registered-record.repository";
import { ServiceRepository } from "@entity/data/repositories/service.repository";
import { StatutoryTerritorialScopeRepository } from "@entity/data/repositories/statutory-territorail-scope.repository";
import { TitularityTypesRepository } from "@entity/data/repositories/titularity-types.repository";
import { CreateVolunteerContract } from "@entity/domain/models/employee/contracts/volunteer/create-volunteer-contract.model";
import { CreateAddressUseCase } from "@entity/domain/usecases/address/create-address.usecase";
import { DeleteAddressUseCase } from "@entity/domain/usecases/address/delete-address.usecase";
import { EditAddressUseCase } from "@entity/domain/usecases/address/edit-address.usecase";
import { GetAllAddressesUsecase } from "@entity/domain/usecases/address/get-all-addresses.usecase";
import { CreateAffiliationUseCase } from "@entity/domain/usecases/affiliation/create-affiliation.usecase";
import { DeleteAffiliationUseCase } from "@entity/domain/usecases/affiliation/delete-affiliation.usecase";
import { EditAffiliationUseCase } from "@entity/domain/usecases/affiliation/edit-affiliation.usecase";
import { GetAllAffiliationsUseCase } from "@entity/domain/usecases/affiliation/get-all-affiliations.usecase";
import { CreateAuthorityManagerUseCase } from "@entity/domain/usecases/authority-manager/create-authority-manager.usecase";
import { DeleteAuthorityManagerUseCase } from "@entity/domain/usecases/authority-manager/delete-authority-manager.usecase";
import { EditAuthorityManagerUseCase } from "@entity/domain/usecases/authority-manager/edit-authority-manager.usecase";
import { GetAllAuthorityManagersUsecase } from "@entity/domain/usecases/authority-manager/get-all-authority-managers.usecase";
import { CreateEmployeeContractUseCase } from "@entity/domain/usecases/employee/contracts/employee/create-employee-contract.usecase";
import { DeleteEmployeeContractUseCase } from "@entity/domain/usecases/employee/contracts/employee/delete-employee-contract.usecase";
import { EditEmployeeContractUseCase } from "@entity/domain/usecases/employee/contracts/employee/edit-employee-contract.usecase";
import { GetAllEmployeeContractsByUseCase } from "@entity/domain/usecases/employee/contracts/employee/get-all-employee-contracts-by.usecase";
import { CreateFreelanceContractUseCase } from "@entity/domain/usecases/employee/contracts/freelance/create-freelance-contract.usecase";
import { DeleteFreelanceContractUseCase } from "@entity/domain/usecases/employee/contracts/freelance/delete-freelance-contract.usecase";
import { EditFreelanceContractUseCase } from "@entity/domain/usecases/employee/contracts/freelance/edit-freelance-contract.usecase";
import { GetAllFreelanceContractsByUseCase } from "@entity/domain/usecases/employee/contracts/freelance/get-all-freelance-contracts-by.usecase";
import { CreateContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/create-contract-termination.usecase";
import { DeleteContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/delete-contract-termination.usecase";
import { EditContractTerminationUseCase } from "@entity/domain/usecases/employee/contracts/termination/edit-contract-termination.usecase";
import { GetAllContractTerminationsUseCase } from "@entity/domain/usecases/employee/contracts/termination/get-all-contract-terminations.usecase";
import { CreateVolunteerContractUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/create-volunteer-contract.usecase";
import { DeleteVolunteerContractUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/delete-volunteer-contract.usecase";
import { EditVolunteerContractUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/edit-volunteer-contract.usecase";
import { GetAllVolunteerContractsByUseCase } from "@entity/domain/usecases/employee/contracts/volunteer/get-all-volunteer-contracts-by.usecase";
import { GetAllDisabilityTypesUseCase } from "@entity/domain/usecases/employee/get-all-disability-types.usecase";
import { GetAllEmployeesByUseCase } from "@entity/domain/usecases/employee/get-all-employees-by.usecase";
import { GetAllProfessionalGroupsUseCase } from "@entity/domain/usecases/employee/get-all-professional-groups.usecase";
import { GetAllSubsidyReasonsUseCase } from "@entity/domain/usecases/employee/get-all-subsidy-reasons.usecase";
import { GetAllTerminationReasonsUseCase } from "@entity/domain/usecases/employee/get-all-termination-reasons.usecase";
import { GetEmployeesCountByTypeUseCase } from "@entity/domain/usecases/employee/get-employees-count-by-type.usecase";
import { ActiveEntityUseCase } from "@entity/domain/usecases/entity/active-entity.usecase";
import { CreateEntityUseCase } from "@entity/domain/usecases/entity/create-entity.usecase";
import { DeactivateEntityUseCase } from "@entity/domain/usecases/entity/deactivate-entity.usecase";
import { EditEntityUseCase } from "@entity/domain/usecases/entity/edit-entity.usecase";
import { GetAllEntitiesByUseCase } from "@entity/domain/usecases/entity/get-all-entities-by.usecase";
import { GetAllEntityTerritorialScopeUseCase } from "@entity/domain/usecases/entity/get-all-entity-territorial-scope.usecase";
import { GetEntitiesByUseCase } from "@entity/domain/usecases/entity/get-entities-by.usecase";
import { GetEntityByIdUseCase } from "@entity/domain/usecases/entity/get-entity-by-id.usecase";
import { GetAllActionLinesUseCase } from "@entity/domain/usecases/get-all-action-lines.usecase";
import { GetAllAdministrativeRecordScopeUseCase } from "@entity/domain/usecases/get-all-administrative-record-scope.usecase";
import { GetAllCategoriesUseCase } from "@entity/domain/usecases/get-all-categories.usecase";
import { GetAllCCAAUseCase } from "@entity/domain/usecases/get-all-ccaa.usecase";
import { GetAllCollectivesUseCase } from "@entity/domain/usecases/get-all-collectives.usecase";
import { GetAllCrosscuttingScopesUseCase } from "@entity/domain/usecases/get-all-crosscutting-scopes.usecase";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { GetAllMandateTerminationReasonsUseCase } from "@entity/domain/usecases/get-all-mandate-termination-reasons.usecase";
import { GetAllPolicyProtocolTypesUseCase } from "@entity/domain/usecases/get-all-policy-protocol-types.usecase";
import { GetAllPositionTypesUseCase } from "@entity/domain/usecases/get-all-position-types.usecase";
import { GetAllServicesUseCase } from "@entity/domain/usecases/get-all-services.usecase";
import { GetAllTitularityTypesUseCase } from "@entity/domain/usecases/get-all-titularity-types.usecase";
import { GetAllTypologiesUseCase } from "@entity/domain/usecases/get-all-typologies.usecase";
import { CreatePublicInterestUseCase } from "@entity/domain/usecases/public-interest/create-public-interest.usecase";
import { DeletePublicInterestUseCase } from "@entity/domain/usecases/public-interest/delete-public-interest.usecase";
import { EditPublicInterestUseCase } from "@entity/domain/usecases/public-interest/edit-public-interest.usecase";
import { GetAllPublicInterestsUsecase } from "@entity/domain/usecases/public-interest/get-all-public-interests.usecase";
import { CreateAuditoryUseCase } from "@entity/domain/usecases/quality-framework/auditory/create-auditory.usecase";
import { DeleteAuditoryUseCase } from "@entity/domain/usecases/quality-framework/auditory/delete-auditory.usecase";
import { EditAuditoryUseCase } from "@entity/domain/usecases/quality-framework/auditory/edit-auditory.usecase";
import { GetAllAuditoriesUsecase } from "@entity/domain/usecases/quality-framework/auditory/get-all-auditories.usecase";
import { CreatePolicyProtocolUseCase } from "@entity/domain/usecases/quality-framework/policy-protocol/create-policy-protocol.usecase";
import { DeletePolicyProtocolUseCase } from "@entity/domain/usecases/quality-framework/policy-protocol/delete-policy-protocol.usecase";
import { EditPolicyProtocolUseCase } from "@entity/domain/usecases/quality-framework/policy-protocol/edit-policy-protocol.usecase";
import { GetAllPolicyProtocolsUsecase } from "@entity/domain/usecases/quality-framework/policy-protocol/get-all-policy-protocols.usecase";
import { CreateProjectAuditoryUseCase } from "@entity/domain/usecases/quality-framework/project-auditory/create-project-auditory.usecase";
import { DeleteProjectAuditoryUseCase } from "@entity/domain/usecases/quality-framework/project-auditory/delete-project-auditory.usecase";
import { EditProjectAuditoryUseCase } from "@entity/domain/usecases/quality-framework/project-auditory/edit-project-auditory.usecase";
import { GetAllProjectAuditoriesUsecase } from "@entity/domain/usecases/quality-framework/project-auditory/get-all-project-auditories.usecase";
import { CreateQualityCertificateUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/create-quality-certificate.usecase";
import { DeleteQualityCertificateUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/delete-quality-certificate.usecase";
import { EditQualityCertificateUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/edit-quality-certificate.usecase";
import { GetAllQualityCertificatesUsecase } from "@entity/domain/usecases/quality-framework/quality-certificate/get-all-quality-certificates.usecase";
import { GetQualityCertificateTypesUseCase } from "@entity/domain/usecases/quality-framework/quality-certificate/get-quality-certificate-types.usecase";
import { CreateRegconRegisterUseCase } from "@entity/domain/usecases/quality-framework/regcon-register/create-regcon-register.usecase";
import { DeleteRegconRegisterUseCase } from "@entity/domain/usecases/quality-framework/regcon-register/delete-regcon-register.usecase";
import { EditRegconRegisterUseCase } from "@entity/domain/usecases/quality-framework/regcon-register/edit-regcon-register.usecase";
import { GetAllRegconRegistersUsecase } from "@entity/domain/usecases/quality-framework/regcon-register/get-all-regcon-registers.usecase";
import { CreateRegisteredRecordUseCase } from "@entity/domain/usecases/registered-record/create-registered-record.usecase";
import { DeleteRegisteredRecordUseCase } from "@entity/domain/usecases/registered-record/delete-registered-record.usecase";
import { EditRegisteredRecordUseCase } from "@entity/domain/usecases/registered-record/edit-registered-record.usecase";
import { GetAllRegisteredRecordsUsecase } from "@entity/domain/usecases/registered-record/get-all-registered-records.usecase";
import { CreateStatutoryTerritorialScopeUseCase } from "@entity/domain/usecases/statutory-territorial-scope/create-statutory-territorial-scope.usecase";
import { DeleteStatutoryTerritorialScopeUseCase } from "@entity/domain/usecases/statutory-territorial-scope/delete-statutory-territorial-scope.usecase";
import { EditStatutoryTerritorialScopeUseCase } from "@entity/domain/usecases/statutory-territorial-scope/edit-statutory-territorial-scope.usecase";
import { GetAllStatuoryTerritorialScopesUsecase } from "@entity/domain/usecases/statutory-territorial-scope/get-all-statutory-territorial-scopes.usecase";
import { DeleteSupplierUseCase } from "@entity/domain/usecases/supplier/delete-supplier.usecase";
import { GetAllServiceProvidedUseCase } from "@entity/domain/usecases/supplier/get-all-service-provided.usecase";
import { GetSupplierByIdUseCase } from "@entity/domain/usecases/supplier/get-all-supplier-by-id.usecase";
import { GetAllSupplierIdentificationTypesUseCase } from "@entity/domain/usecases/supplier/get-all-supplier-identification-types.usecase";
import { GetAllSupplierTypesUseCase } from "@entity/domain/usecases/supplier/get-all-suppplier-types.usecase";
import { GetSuppliersByUseCase } from "@entity/domain/usecases/supplier/get-suppliers-by.usecase";
import { ContractTerminationFormViewModel } from "@entity/presentation/component/employee-form/contract-termination/form/contract-termination-form.viewmodel";
import { EmployeeContractTabViewModel } from "@entity/presentation/component/employee-form/contract/employee/employee-contract-tab.viewmodel";
import { FreelanceContractTabViewModel } from "@entity/presentation/component/employee-form/contract/freelance/freelance-contract-tab.viewmodel";
import { VolunteerContractTabViewModel } from "@entity/presentation/component/employee-form/contract/volunteer/volunteer-contract-tab.viewmodel";
import { EmployeeGeneralTabViewModel } from "@entity/presentation/component/employee-form/general/employee-general-tab.viewmodel";
import { AffiliationTabViewModel } from "@entity/presentation/component/entity-form/affiliation/affiliation-tab.viewmodel";
import { AuthorityManagerTabViewModel } from "@entity/presentation/component/entity-form/authority-manager/authority-manager.viewmodel";
import { ContactViewModel } from "@entity/presentation/component/entity-form/contact/contact.viewmodel";
import { GeneralTabViewModel } from "@entity/presentation/component/entity-form/general/general-tab.viewmodel";
import { PublicInterestViewModel } from "@entity/presentation/component/entity-form/general/public-interest/public-interest.viewmodel";
import { RegisteredRecordViewModel } from "@entity/presentation/component/entity-form/general/registered-record/registered-record.viewmodel";
import { StatutoryTerritorialScopeViewModel } from "@entity/presentation/component/entity-form/general/statutory-territorial-scope/statutory-territorial-scope.viewmodel";
import { ProjectTabViewModel } from "@entity/presentation/component/entity-form/project/project-tab.viewmodel";
import { AuditoryViewModel } from "@entity/presentation/component/entity-form/quality-framework/auditory/auditory.viewmodel";
import { PolicyProtocolViewModel } from "@entity/presentation/component/entity-form/quality-framework/policy-protocol/policy-protocol.viewmodel";
import { ProjectAuditoryViewModel } from "@entity/presentation/component/entity-form/quality-framework/project-auditory/project-auditory.viewmodel";
import { QualityCertificateViewModel } from "@entity/presentation/component/entity-form/quality-framework/quality-certificate/quality-certificate.viewmodel";
import { RegconRegisterViewModel } from "@entity/presentation/component/entity-form/quality-framework/regcon-register/regcon-register.viewmodel";
import { SocialNetworksViewModel } from "@entity/presentation/component/entity-form/social-networks/social-networks.viewmodel";
import { SupplierFormViewModel } from "@entity/presentation/component/supplier-form/supplier-form.viewmodel";
import { EntityListPageViewModel } from "@entity/presentation/pages/entity/list/entity-list-page-viewmodel";
import { AuthenticatedUserMapper } from "@user/data/mappers/authenticated-user.mapper";
import { ContainerModule } from "inversify";
import { ContactDatasource } from "./data/datasources/contact.datasource";
import { CostDatasource } from "./data/datasources/cost.datasource";
import { CreditorDatasource } from "./data/datasources/creditor.datasource";
import { DepositDatasource } from "./data/datasources/deposit.datasource";
import { EmployeeDatasource } from "./data/datasources/employee.datasource";
import { LocalDatasource } from "./data/datasources/local.datasource";
import { MaterialDatasource } from "./data/datasources/material.datasource";
import { RoomDatasource } from "./data/datasources/room.datasource";
import { SocialNetworkDatasource } from "./data/datasources/social-network.datasource";
import { SupplierDatasource } from "./data/datasources/supplier.datasource";
import { AddressMapper } from "./data/mappers/address/address.mapper";
import { CategoryMapper } from "./data/mappers/category.mapper";
import { ContactsMapper } from "./data/mappers/contacts.mapper";
import { CostAdditionalExpensesMapper } from "./data/mappers/cost/cost-additional-expenses.mapper";
import { CostTypeEnumMapper } from "./data/mappers/cost/cost-type-enum.mapper";
import { CostTypeMapper } from "./data/mappers/cost/cost-type.mapper";
import { CostMapper } from "./data/mappers/cost/cost.mapper";
import { CostsMapper } from "./data/mappers/cost/costs.mapper";
import { CreateCostMapper } from "./data/mappers/cost/create-cost.mapper";
import { EditCostMapper } from "./data/mappers/cost/edit-cost.mapper";
import { IRPFTypeEnumMapper } from "./data/mappers/cost/irpf-type-enum.mapper";
import { IRPFTypeMapper } from "./data/mappers/cost/irpf-type.mapper";
import { IVAEnumMapper } from "./data/mappers/cost/iva-enum.mapper";
import { IvaMapper } from "./data/mappers/cost/iva.mapper";
import { PaymentMethodMapper } from "./data/mappers/cost/payment-method-type-enum.mapper";
import { PaymentMethodTypeEnumMapper } from "./data/mappers/cost/payment-method-type.mapper";
import { CreateContactMapper } from "./data/mappers/create-contact.mapper";
import { CreateEntityMapper } from "./data/mappers/create-entity.mapper";
import { CreditorMapper } from "./data/mappers/creditor/creditor.mapper";
import { CreditorsMapper } from "./data/mappers/creditor/creditors.mapper";
import { DepositTypeEnumMapper } from "./data/mappers/deposit-type-enum.mapper";
import { DepositTypesMapper } from "./data/mappers/deposit-type.mapper";
import { CreateDepositMapper } from "./data/mappers/deposit/create-deposit.mapper";
import { DepositMapper } from "./data/mappers/deposit/deposit.mapper";
import { EditDepositMapper } from "./data/mappers/deposit/edit-deposit.mapper";
import { ExportProjectDepositsMapper } from "./data/mappers/deposit/export-project-deposits.mapper";
import { ExportProjectExpensesMapper } from "./data/mappers/deposit/export-project-expenses.mapper";
import { IdentificationEnumMapper } from "./data/mappers/deposit/identification-enum.mapper";
import { IdentificationTypeMapper } from "./data/mappers/deposit/identification-type.mapper";
import { EditContactMapper } from "./data/mappers/edit-contact.mapper";
import { EditEntityMapper } from "./data/mappers/edit-entity.mapper";
import { EmployeeTypeEnumMapper } from "./data/mappers/employee/employee-type-enum.mapper";
import { EmployeeTypeMapper } from "./data/mappers/employee/employee-type.mapper";
import { EmployeeMapper } from "./data/mappers/employee/employee.mapper";
import { EmployeesMapper } from "./data/mappers/employee/employees.mapper";
import { EntityMapper } from "./data/mappers/entity.mapper";
import { GlobalEntityMapper } from "./data/mappers/global-entity.mapper";
import { CostPeriodicityTypeEnumMapper } from "./data/mappers/locals/cost-periodicity-type-enum.mapper";
import { CostPeriodicityTypeMapper } from "./data/mappers/locals/cost-periodicity-type.mapper";
import { LocalMapper } from "./data/mappers/locals/local.mapper";
import { LocalsMapper } from "./data/mappers/locals/locals.mapper";
import { RoomMapper } from "./data/mappers/locals/room.mapper";
import { RoomsMapper } from "./data/mappers/locals/rooms.mapper";
import { StatusTypeEnumMapper } from "./data/mappers/locals/status-type-enum.mapper";
import { StatusTypeMapper } from "./data/mappers/locals/status-type.mapper";
import { MaterialSubtypeEnumMapper } from "./data/mappers/material/material-subtype-enum.mapper";
import { MaterialSubtypeMapper } from "./data/mappers/material/material-subtype.mapper";
import { MaterialTypeEnumMapper } from "./data/mappers/material/material-type-enum.mapper";
import { MaterialTypeMapper } from "./data/mappers/material/material-type.mapper";
import { MaterialMapper } from "./data/mappers/material/material.mapper";
import { MaterialsMapper } from "./data/mappers/material/materials.mapper";
import { MetricUnitEnumMapper } from "./data/mappers/material/metric-unit-enum.mapper";
import { MetricUnitMapper } from "./data/mappers/material/metric-unit.mapper";
import { CreateSocialNetworkMapper } from "./data/mappers/social-network/create-social-network.mapper";
import { EditSocialNetworkMapper } from "./data/mappers/social-network/edit-social-network.mapper";
import { SocialNetworkSummaryMapper } from "./data/mappers/social-network/social-network-summary.mapper";
import { SocialNetworkMapper } from "./data/mappers/social-network/social-network.mapper";
import { SocialNetworksSummaryMapper } from "./data/mappers/social-network/social-networks-summary.mapper";
import { CreateSupplierMapper } from "./data/mappers/supplier/create-supplier.mapper";
import { EditSupplierMapper } from "./data/mappers/supplier/edit-supplier.mapper";
import { SupplierTypeEnumMapper } from "./data/mappers/supplier/supplier-type-enum.mapper";
import { SupplierMapper } from "./data/mappers/supplier/supplier.mapper";
import { SuppliersMapper } from "./data/mappers/supplier/suppliers.mapper";
import { AddressRepository } from "./data/repositories/address.repository";
import { ContactRepository } from "./data/repositories/contact.repository";
import { CostRepository } from "./data/repositories/cost.repository";
import { CreditorRepository } from "./data/repositories/creditor.repository";
import { DepositRepository } from "./data/repositories/deposit.repository";
import { EmployeeRepository } from "./data/repositories/employee.repository";
import { LocalRepository } from "./data/repositories/local.repository";
import { MaterialRepository } from "./data/repositories/material.repository";
import { RoomRepository } from "./data/repositories/room.repository";
import { SocialNetworkRepository } from "./data/repositories/social-network.repository";
import { SupplierRepository } from "./data/repositories/supplier.repository";
import { CreateCostUseCase } from "./domain/usecases/cost/create-cost.usecase";
import { DeleteCostUseCase } from "./domain/usecases/cost/delete-cost.usecase";
import { GetAllCostsByUseCase } from "./domain/usecases/cost/get-all-costs-by.usecase";
import { GetAllCostsTypesUseCase } from "./domain/usecases/cost/get-all-costs-types.usecase";
import { GetAllIrpfTypesUseCase } from "./domain/usecases/cost/get-all-irpf-types.usecase";
import { GetAllIvaTypesUseCase } from "./domain/usecases/cost/get-all-iva-types.usecase";
import { GetAllPaymentMethodTypesUseCase } from "./domain/usecases/cost/get-all-payment-method-types.usecase";
import { GetCostByIdUseCase } from "./domain/usecases/cost/get-cost-by-id.usecase";
import { GetCostsByUseCase } from "./domain/usecases/cost/get-costs-by.usecase";
import { UpdateAdditionalExpensesUseCase } from "./domain/usecases/cost/update-additional-expenses.usecase";
import { UpdateCostUseCase } from "./domain/usecases/cost/update-cost.usecase";
import { CreateContactUseCase } from "./domain/usecases/create-contact.usecase";
import { CreateDepositUseCase } from "./domain/usecases/create-deposit.usecase";
import { CreateCreditorUseCase } from "./domain/usecases/creditor/create-creditor.usecase";
import { DeleteCreditorUseCase } from "./domain/usecases/creditor/delete-creditor.usecase";
import { EditCreditorUseCase } from "./domain/usecases/creditor/edit-creditor.usecase";
import { GetCreditorByIdUseCase } from "./domain/usecases/creditor/get-creditor-by-id.usecase";
import { GetCreditorsByUseCase } from "./domain/usecases/creditor/get-creditors-by.usecase";
import { DeleteContactUseCase } from "./domain/usecases/delete-contact.usecase";
import { DeleteDepositUseCase } from "./domain/usecases/delete-deposit-usecase";
import { EditContactUseCase } from "./domain/usecases/edit-contact.usecase";
import { EditDepositUseCase } from "./domain/usecases/edit-deposit.usecase";
import { CreateEmployeeUseCase } from "./domain/usecases/employee/create-employee.usecase";
import { EditEmployeeUseCase } from "./domain/usecases/employee/edit-employee.usecase";
import { GetAllEmployeeTypesUseCase } from "./domain/usecases/employee/get-all-employee-types.usecase";
import { GetEmployeeByIdUseCase } from "./domain/usecases/employee/get-employee-by-id.usecase";
import { GetEmployeesByUseCase } from "./domain/usecases/employee/get-employees-by.usecase";
import { ExportProjectDepositsUseCase } from "./domain/usecases/export-project-deposits.usecase";
import { ExportProjectExpensesUseCase } from "./domain/usecases/export-project-expenses.usecase";
import { GetAllContactsUseCase } from "./domain/usecases/get-all-contacts.usecase";
import { GetAllDepositTypesUseCase } from "./domain/usecases/get-all-deposit-types.usecase";
import { GetAllDepositsUseCase } from "./domain/usecases/get-all-deposit.usecase";
import { GetAllIdentificationTypesUseCase } from "./domain/usecases/get-all-identification-types.usecase";
import { CreateLocalUseCase } from "./domain/usecases/locals/create-local.usecase";
import { CreateRoomUseCase } from "./domain/usecases/locals/create-room.usecase";
import { DeleteLocalUseCase } from "./domain/usecases/locals/delete-local.usecase";
import { DeleteRoomUseCase } from "./domain/usecases/locals/delete-room.usecase";
import { EditLocalUseCase } from "./domain/usecases/locals/edit-local.usecase";
import { GetAllCostPeriodicitiesUseCase } from "./domain/usecases/locals/get-all-cost-periodicities.usecase";
import { GetAllLocalsUseCase } from "./domain/usecases/locals/get-all-locals.usecase";
import { GetAllStatusTypesUseCase } from "./domain/usecases/locals/get-all-status-types.usecase";
import { SearchRoomsByUseCase } from "./domain/usecases/locals/search-rooms.usecase";
import { CreateMaterialUseCase } from "./domain/usecases/material/create-material.usecase";
import { EditMaterialUseCase } from "./domain/usecases/material/edit-material.usecase";
import { GetAllMaterialSubtypesByTypeIdUseCase } from "./domain/usecases/material/get-all-material-subtypes-by-id.usecase";
import { GetAllMaterialTypesUseCase } from "./domain/usecases/material/get-all-material-types.usecase";
import { GetAllMetricUnitUseCase } from "./domain/usecases/material/get-all-metric-unit.usecase";
import { GetMaterialByIdUseCase } from "./domain/usecases/material/get-material-by-id.usecase";
import { SearchMaterialsByUseCase } from "./domain/usecases/material/search-materials-by.usecase";
import { SearchDepositsByUseCase } from "./domain/usecases/search-deposit-by.usecase";
import { CreateSocialNetworkUseCase } from "./domain/usecases/social-network/create-social-network.usecase";
import { DeleteSocialNetworkUseCase } from "./domain/usecases/social-network/delete-social-network.usecase";
import { EditSocialNetworkUseCase } from "./domain/usecases/social-network/edit-social-network.usecase";
import { GetAllSocialNetworksUseCase } from "./domain/usecases/social-network/get-all-social-networks.usecase";
import { CreateSplitDepositUseCase } from "./domain/usecases/splitDeposit/create-split-deposit.usecase";
import { DeleteSplitDepositUseCase } from "./domain/usecases/splitDeposit/delete-split-deposit.usecase";
import { EditSplitDepositUseCase } from "./domain/usecases/splitDeposit/edit-split-deposit.usecase";
import { CreateSupplierUseCase } from "./domain/usecases/supplier/create-supplier.usecase";
import { EditSupplierUseCase } from "./domain/usecases/supplier/edit-supplier.usecase";
import { CostFormViewModel } from "./presentation/component/cost-form/cost-form.viewmodel";
import { LinkCostToProjectFormViewModel } from "./presentation/component/cost-form/link-cost-to-project-modal-form/link-cost-to-project-modal-form.viewmodel";
import { ContractTerminationTabViewModel } from "./presentation/component/employee-form/contract-termination/contract-termination-tab.viewmodel";
import { ContractTabViewModel } from "./presentation/component/employee-form/contract/contract-tab.viewmodel";
import { DocumentTabViewModel } from "./presentation/component/employee-form/document/document-tab.viewmodel";
import { AddEmployeeFromEntityModalFormViewModel } from "./presentation/component/employee-form/general/form/add-employee-from-entity-modal-form.viewmodel";
import { AddressTabViewModel } from "./presentation/component/entity-form/address/address-tab.viewmodel";
import { DepositViewModel } from "./presentation/component/entity-form/deposits/deposit.viewmodel";
import { DepositFormViewModel } from "./presentation/component/entity-form/deposits/form/deposit.form.viewmodel";
import { DocumentsTabViewModel } from "./presentation/component/entity-form/documents/documents-tab.viewmodel";
import { LocalViewModel } from "./presentation/component/entity-form/locals/form/local.viewmodel";
import { LocalTabViewModel } from "./presentation/component/entity-form/locals/local-tab.viewmodel";
import { MaterialFormViewModel } from "./presentation/component/material-form/material-form.viewmodel";
import { CreateCostPageViewModel } from "./presentation/pages/cost/create/create-cost-page-viewmodel";
import { CostDetailPageViewModel } from "./presentation/pages/cost/detail/cost-detail-page-viewmodel";
import { EditCostPageViewModel } from "./presentation/pages/cost/edit/edit-cost-page-viewmodel";
import { CostListPageViewModel } from "./presentation/pages/cost/list/cost-list-page-viewmodel";
import { ExpenseListPageViewModel } from "./presentation/pages/cost/list/expense-list-page-viewmodel";
import { CreateCreditorPageViewModel } from "./presentation/pages/creditor/create/create-creditor-page.viewmodel";
import { CreditorDetailPageViewModel } from "./presentation/pages/creditor/detail/creditor-detail-page.viewmodel";
import { EditCreditorPageViewModel } from "./presentation/pages/creditor/edit/edit-creditor-page.viewmodel";
import { CreditorListPageViewModel } from "./presentation/pages/creditor/list/creditor-list-page-viewmodel";
import { CreateEmployeePageViewModel } from "./presentation/pages/employee/create/create-employee-page.viewmodel";
import { EmployeeDetailPageViewModel } from "./presentation/pages/employee/detail/employee-detail-page.viewmodel";
import { EditEmployeePageViewModel } from "./presentation/pages/employee/edit/edit-employee-page.viewmodel";
import { EmployeeListPageViewModel } from "./presentation/pages/employee/list/employee-list-page-viewmodel";
import { CreateEntityPageViewModel } from "./presentation/pages/entity/create/create-entity-page.viewmodel";
import { EntityDetailPageViewModel } from "./presentation/pages/entity/detail/entity-detail-page.viewmodel";
import { EditEntityPageViewModel } from "./presentation/pages/entity/edit/edit-entity-page.viewmodel";
import { CreateMaterialPageViewModel } from "./presentation/pages/material/create/create-material-page-viewmodel";
import { DetailMaterialPageViewModel } from "./presentation/pages/material/detail/detail-material-page-viewmodel";
import { EditMaterialPageViewModel } from "./presentation/pages/material/edit/edit-material-page-viewmodel";
import { MaterialListPageViewModel } from "./presentation/pages/material/list/material-list-page-viewmodel";
import { CreateSupplierPageViewModel } from "./presentation/pages/supplier/create/create-supplier-page.viewmodel";
import { SupplierDetailPageViewModel } from "./presentation/pages/supplier/detail/supplier-detail-page.viewmodel";
import { EditSupplierPageViewModel } from "./presentation/pages/supplier/edit/edit-supplier-page.viewmodel";
import { SupplierListPageViewModel } from "./presentation/pages/supplier/list/supplier-list-page-viewmodel";

const entityModule = new DiModuleBuilder();

entityModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(AddressDatasource).toSelf();
        bind(AffiliationDatasource).toSelf();
        bind(AuditoryDatasource).toSelf();
        bind(AuthorityManagerDatasource).toSelf();
        bind(CollectiveDatasource).toSelf();
        bind(ContactDatasource).toSelf();
        bind(ContractsDatasource).toSelf();
        bind(ContractTerminationDatasource).toSelf();
        bind(CostAdditionalExpensesMapper).toSelf();
        bind(CostDatasource).toSelf();
        bind(CreditorDatasource).toSelf();
        bind(DepositDatasource).toSelf();
        bind(EmployeeDatasource).toSelf();
        bind(EntityDatasource).toSelf();
        bind(GlobalEntityDatasource).toSelf();
        bind(LocalDatasource).toSelf();
        bind(LocalMapper).toSelf();
        bind(MaterialDatasource).toSelf();
        bind(PolicyProtocolDatasource).toSelf();
        bind(ProjectAuditoryDatasource).toSelf();
        bind(PublicInterestDatasource).toSelf();
        bind(QualityCertificateDatasource).toSelf();
        bind(RegconRegisterDatasource).toSelf();
        bind(RegisteredRecordDatasource).toSelf();
        bind(RoomDatasource).toSelf();
        bind(ServiceDatasource).toSelf();
        bind(SocialNetworkDatasource).toSelf();
        bind(StatutoryTerritorialScopeDatasource).toSelf();
        bind(SupplierDatasource).toSelf();
        bind(TitularityTypesDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(ActionLineEnumMapper).toSelf();
        bind(ActionLinesMapper).toSelf();
        bind(AddressesMapper).toSelf();
        bind(AddressMapper).toSelf();
        bind(AdministrativeRecordScopeEnumMapper).toSelf();
        bind(AdministrativeRecordScopeMapper).toSelf();
        bind(AffiliationMapper).toSelf();
        bind(AffiliationsMapper).toSelf();
        bind(AuditoriesMapper).toSelf();
        bind(AuditoryMapper).toSelf();
        bind(AuthenticatedUserMapper).toSelf();
        bind(AuthorityManagerMapper).toSelf();
        bind(AuthorityManagersMapper).toSelf();
        bind(CategoryMapper).toSelf();
        bind(CategoryTypeEnumMapper).toSelf();
        bind(CCAAEnumMapper).toSelf();
        bind(CCAAMapper).toSelf();
        bind(CollectiveEnumMapper).toSelf();
        bind(CollectiveMapper).toSelf();
        bind(CollectivesMapper).toSelf();
        bind(ContactsMapper).toSelf();
        bind(ContractTerminationMapper).toSelf();
        bind(ContractTerminationsMapper).toSelf();
        bind(CostMapper).toSelf();
        bind(CostPeriodicityTypeEnumMapper).toSelf();
        bind(CostPeriodicityTypeMapper).toSelf();
        bind(CostsMapper).toSelf();
        bind(CostTypeEnumMapper).toSelf();
        bind(CostTypeMapper).toSelf();
        bind(CreateAddressMapper).toSelf();
        bind(CreateAffiliationMapper).toSelf();
        bind(CreateAuditoryMapper).toSelf();
        bind(CreateAuthorityManagerMapper).toSelf();
        bind(CreateContactMapper).toSelf();
        bind(CreateContractTerminationMapper).toSelf();
        bind(CreateCostMapper).toSelf();
        bind(CreateCreditorPageViewModel).toSelf();
        bind(CreateDepositMapper).toSelf();
        bind(CreateEmployeeContractMapper).toSelf();
        bind(CreateEmployeeMapper).toSelf();
        bind(CreateEntityMapper).toSelf();
        bind(CreateFreelanceContractMapper).toSelf();
        bind(CreatePolicyProtocolMapper).toSelf();
        bind(CreateProjectAuditoryMapper).toSelf();
        bind(CreatePublicInterestMapper).toSelf();
        bind(CreateQualityCertificateMapper).toSelf();
        bind(CreateRegconRegisterMapper).toSelf();
        bind(CreateRegisteredRecordMapper).toSelf();
        bind(CreateSocialNetworkMapper).toSelf();
        bind(CreateStatutoryTerritorialScopeMapper).toSelf();
        bind(CreateSupplierMapper).toSelf();
        bind(CreateVolunteerContract).toSelf();
        bind(CreateVolunteerContractMapper).toSelf();
        bind(CreditorMapper).toSelf();
        bind(CreditorsMapper).toSelf();
        bind(CrosscuttingScopeEnumMapper).toSelf();
        bind(CrosscuttingScopesMapper).toSelf();
        bind(DepositMapper).toSelf();
        bind(DepositTypeEnumMapper).toSelf();
        bind(DepositTypesMapper).toSelf();
        bind(DisabilityTypeEnumMapper).toSelf();
        bind(DisabilityTypeMapper).toSelf();
        bind(EditAddressMapper).toSelf();
        bind(EditAffiliationMapper).toSelf();
        bind(EditAuditoryMapper).toSelf();
        bind(EditAuthorityManagerMapper).toSelf();
        bind(EditContactMapper).toSelf();
        bind(EditContractTerminationMapper).toSelf();
        bind(EditCostMapper).toSelf();
        bind(EditCreditorPageViewModel).toSelf();
        bind(EditDepositMapper).toSelf();
        bind(EditEmployeeContractMapper).toSelf();
        bind(EditEmployeeMapper).toSelf();
        bind(EditEntityMapper).toSelf();
        bind(EditFreelanceContractMapper).toSelf();
        bind(EditPolicyProtocolMapper).toSelf();
        bind(EditProjectAuditoryMapper).toSelf();
        bind(EditPublicInterestMapper).toSelf();
        bind(EditQualityCertificateMapper).toSelf();
        bind(EditRegconRegisterMapper).toSelf();
        bind(EditRegisteredRecordMapper).toSelf();
        bind(EditSocialNetworkMapper).toSelf();
        bind(EditStatutoryTerritorialScopeMapper).toSelf();
        bind(EditSupplierMapper).toSelf();
        bind(EditVolunteerContractMapper).toSelf();
        bind(EmployeeContractMapper).toSelf();
        bind(EmployeeContractsMapper).toSelf();
        bind(EmployeeMapper).toSelf();
        bind(EmployeesCountMapper).toSelf();
        bind(EmployeesMapper).toSelf();
        bind(EmployeeTypeEnumMapper).toSelf();
        bind(EmployeeTypeMapper).toSelf();
        bind(EntitiesSummaryMapper).toSelf();
        bind(EntityMapper).toSelf();
        bind(EntitySummaryMapper).toSelf();
        bind(EntityTerritorialScopeEnumMapper).toSelf();
        bind(EntityTerritorialScopeMapper).toSelf();
        bind(ExportProjectDepositsMapper).toSelf();
        bind(ExportProjectExpensesMapper).toSelf();
        bind(FreelanceContractMapper).toSelf();
        bind(FreelanceContractsMapper).toSelf();
        bind(GlobalEntityMapper).toSelf();
        bind(IdentificationEnumMapper).toSelf();
        bind(IdentificationTypeMapper).toSelf();
        bind(IRPFTypeEnumMapper).toSelf();
        bind(IRPFTypeMapper).toSelf();
        bind(IVAEnumMapper).toSelf();
        bind(IvaMapper).toSelf();
        bind(LocalsMapper).toSelf();
        bind(MandateTerminationReasonEnumMapper).toSelf();
        bind(MandateTerminationReasonMapper).toSelf();
        bind(MaterialMapper).toSelf();
        bind(MaterialsMapper).toSelf();
        bind(MaterialSubtypeEnumMapper).toSelf();
        bind(MaterialSubtypeMapper).toSelf();
        bind(MaterialTypeEnumMapper).toSelf();
        bind(MaterialTypeMapper).toSelf();
        bind(MetricUnitEnumMapper).toSelf();
        bind(MetricUnitMapper).toSelf();
        bind(PaymentMethodMapper).toSelf();
        bind(PaymentMethodTypeEnumMapper).toSelf();
        bind(PolicyProtocolMapper).toSelf();
        bind(PolicyProtocolsMapper).toSelf();
        bind(PolicyProtocolTypeEnumMapper).toSelf();
        bind(PolicyProtocolTypeMapper).toSelf();
        bind(PositionTypeEnumMapper).toSelf();
        bind(PositionTypeMapper).toSelf();
        bind(ProfessionalGroupEnumMapper).toSelf();
        bind(ProfessionalGroupMapper).toSelf();
        bind(ProjectAuditoriesMapper).toSelf();
        bind(ProjectAuditoryMapper).toSelf();
        bind(PublicInterestMapper).toSelf();
        bind(PublicInterestsMapper).toSelf();
        bind(QualityCertificateMapper).toSelf();
        bind(QualityCertificatesMapper).toSelf();
        bind(QualityCertificateTypeEnumMapper).toSelf();
        bind(QualityCertificateTypeMapper).toSelf();
        bind(RegconRegisterMapper).toSelf();
        bind(RegconRegistersMapper).toSelf();
        bind(RegisteredRecordMapper).toSelf();
        bind(RegisteredRecordsMapper).toSelf();
        bind(RoomMapper).toSelf();
        bind(RoomsMapper).toSelf();
        bind(ServiceMapper).toSelf();
        bind(ServiceProvidedEnumMapper).toSelf();
        bind(ServiceProvidedMapper).toSelf();
        bind(ServicesMapper).toSelf();
        bind(SocialNetworkMapper).toSelf();
        bind(SocialNetworksSummaryMapper).toSelf();
        bind(SocialNetworkSummaryMapper).toSelf();
        bind(StatusTypeEnumMapper).toSelf();
        bind(StatusTypeMapper).toSelf();
        bind(StatutoryTerritorialScopeMapper).toSelf();
        bind(StatutoryTerritorialScopesMapper).toSelf();
        bind(SubsidyReasonEnumMapper).toSelf();
        bind(SubsidyReasonMapper).toSelf();
        bind(SupplierIdentificationEnumMapper).toSelf();
        bind(SupplierIdentificationTypeMapper).toSelf();
        bind(SupplierMapper).toSelf();
        bind(SuppliersMapper).toSelf();
        bind(SupplierTypeEnumMapper).toSelf();
        bind(SupplierTypeMapper).toSelf();
        bind(TerminationReasonEnumMapper).toSelf();
        bind(TerminationReasonMapper).toSelf();
        bind(TitularityTypeEnumMapper).toSelf();
        bind(TitularityTypeMapper).toSelf();
        bind(TypologiesMapper).toSelf();
        bind(TypologyEnumMapper).toSelf();
        bind(TypologyMapper).toSelf();
        bind(VolunteerContractMapper).toSelf();
        bind(VolunteerContractsMapper).toSelf();
        bind(VolunteerInfoMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(AddressRepository).toSelf();
        bind(AffiliationRepository).toSelf();
        bind(AuditoryRepository).toSelf();
        bind(AuthorityManagerRepository).toSelf();
        bind(CollectiveRepository).toSelf();
        bind(ContactRepository).toSelf();
        bind(ContractsRepository).toSelf();
        bind(ContractTerminationRepository).toSelf();
        bind(CostRepository).toSelf();
        bind(CreditorRepository).toSelf();
        bind(DepositRepository).toSelf();
        bind(EmployeeRepository).toSelf();
        bind(EntityRepository).toSelf();
        bind(GlobalEntityRepository).toSelf();
        bind(LocalRepository).toSelf();
        bind(MaterialRepository).toSelf();
        bind(PolicyProtocolRepository).toSelf();
        bind(ProjectAuditoryRepository).toSelf();
        bind(PublicInterestRepository).toSelf();
        bind(QualityCertificateRepository).toSelf();
        bind(RegconRegisterRepository).toSelf();
        bind(RegisteredRecordRepository).toSelf();
        bind(RoomRepository).toSelf();
        bind(ServiceRepository).toSelf();
        bind(SocialNetworkRepository).toSelf();
        bind(StatutoryTerritorialScopeRepository).toSelf();
        bind(SupplierRepository).toSelf();
        bind(TitularityTypesRepository).toSelf();
    }),
    useCases: new ContainerModule((bind) => {
        bind(ActiveEntityUseCase).toSelf();
        bind(CreateAddressUseCase).toSelf();
        bind(CreateAffiliationUseCase).toSelf();
        bind(CreateAuditoryUseCase).toSelf();
        bind(CreateAuthorityManagerUseCase).toSelf();
        bind(CreateContactUseCase).toSelf();
        bind(CreateContractTerminationUseCase).toSelf();
        bind(CreateCostUseCase).toSelf();
        bind(CreateCreditorUseCase).toSelf();
        bind(CreateDepositUseCase).toSelf();
        bind(CreateEmployeeContractUseCase).toSelf();
        bind(CreateEmployeeUseCase).toSelf();
        bind(CreateEntityUseCase).toSelf();
        bind(CreateFreelanceContractUseCase).toSelf();
        bind(CreateLocalUseCase).toSelf();
        bind(CreateMaterialUseCase).toSelf();
        bind(CreatePolicyProtocolUseCase).toSelf();
        bind(CreateProjectAuditoryUseCase).toSelf();
        bind(CreatePublicInterestUseCase).toSelf();
        bind(CreateQualityCertificateUseCase).toSelf();
        bind(CreateRegconRegisterUseCase).toSelf();
        bind(CreateRegisteredRecordUseCase).toSelf();
        bind(CreateRoomUseCase).toSelf();
        bind(CreateSocialNetworkUseCase).toSelf();
        bind(CreateSplitDepositUseCase).toSelf();
        bind(CreateStatutoryTerritorialScopeUseCase).toSelf();
        bind(CreateSupplierUseCase).toSelf();
        bind(CreateVolunteerContractUseCase).toSelf();
        bind(DeactivateEntityUseCase).toSelf();
        bind(DeleteAddressUseCase).toSelf();
        bind(DeleteAffiliationUseCase).toSelf();
        bind(DeleteAuditoryUseCase).toSelf();
        bind(DeleteAuthorityManagerUseCase).toSelf();
        bind(DeleteContactUseCase).toSelf();
        bind(DeleteContractTerminationUseCase).toSelf();
        bind(DeleteCostUseCase).toSelf();
        bind(DeleteDepositUseCase).toSelf();
        bind(DeleteEmployeeContractUseCase).toSelf();
        bind(DeleteFreelanceContractUseCase).toSelf();
        bind(DeleteLocalUseCase).toSelf();
        bind(DeletePolicyProtocolUseCase).toSelf();
        bind(DeleteProjectAuditoryUseCase).toSelf();
        bind(DeletePublicInterestUseCase).toSelf();
        bind(DeleteQualityCertificateUseCase).toSelf();
        bind(DeleteRegconRegisterUseCase).toSelf();
        bind(DeleteRegisteredRecordUseCase).toSelf();
        bind(DeleteRoomUseCase).toSelf();
        bind(DeleteSocialNetworkUseCase).toSelf();
        bind(DeleteSplitDepositUseCase).toSelf();
        bind(DeleteStatutoryTerritorialScopeUseCase).toSelf();
        bind(DeleteSupplierUseCase).toSelf();
        bind(DeleteVolunteerContractUseCase).toSelf();
        bind(EditAddressUseCase).toSelf();
        bind(EditAffiliationUseCase).toSelf();
        bind(EditAuditoryUseCase).toSelf();
        bind(EditAuthorityManagerUseCase).toSelf();
        bind(EditContactUseCase).toSelf();
        bind(EditContractTerminationUseCase).toSelf();
        bind(EditCreditorUseCase).toSelf();
        bind(EditDepositUseCase).toSelf();
        bind(EditEmployeeContractUseCase).toSelf();
        bind(EditEmployeeUseCase).toSelf();
        bind(EditEntityUseCase).toSelf();
        bind(EditFreelanceContractUseCase).toSelf();
        bind(EditLocalUseCase).toSelf();
        bind(EditMaterialUseCase).toSelf();
        bind(EditPolicyProtocolUseCase).toSelf();
        bind(EditProjectAuditoryUseCase).toSelf();
        bind(EditPublicInterestUseCase).toSelf();
        bind(EditQualityCertificateUseCase).toSelf();
        bind(EditRegconRegisterUseCase).toSelf();
        bind(EditRegisteredRecordUseCase).toSelf();
        bind(EditSocialNetworkUseCase).toSelf();
        bind(EditSplitDepositUseCase).toSelf();
        bind(EditStatutoryTerritorialScopeUseCase).toSelf();
        bind(EditSupplierUseCase).toSelf();
        bind(EditVolunteerContractUseCase).toSelf();
        bind(ExportProjectDepositsUseCase).toSelf();
        bind(ExportProjectExpensesUseCase).toSelf();
        bind(GetAllActionLinesUseCase).toSelf();
        bind(GetAllAddressesUsecase).toSelf();
        bind(GetAllAdministrativeRecordScopeUseCase).toSelf();
        bind(GetAllAffiliationsUseCase).toSelf();
        bind(GetAllAuditoriesUsecase).toSelf();
        bind(GetAllAuthorityManagersUsecase).toSelf();
        bind(GetAllCategoriesUseCase).toSelf();
        bind(GetAllCCAAUseCase).toSelf();
        bind(GetAllCollectivesUseCase).toSelf();
        bind(GetAllContactsUseCase).toSelf();
        bind(GetAllContractTerminationsUseCase).toSelf();
        bind(GetAllCostPeriodicitiesUseCase).toSelf();
        bind(GetAllCostsByUseCase).toSelf();
        bind(GetAllCostsTypesUseCase).toSelf();
        bind(GetAllCrosscuttingScopesUseCase).toSelf();
        bind(GetAllDepositsUseCase).toSelf();
        bind(GetAllDepositTypesUseCase).toSelf();
        bind(GetAllDisabilityTypesUseCase).toSelf();
        bind(GetAllEmployeeContractsByUseCase).toSelf();
        bind(GetAllEmployeesByUseCase).toSelf();
        bind(GetAllEmployeeTypesUseCase).toSelf();
        bind(GetAllEntitiesByUseCase).toSelf();
        bind(GetAllEntityTerritorialScopeUseCase).toSelf();
        bind(GetAllFreelanceContractsByUseCase).toSelf();
        bind(GetAllGlobalEntitiesUseCase).toSelf();
        bind(GetAllIdentificationTypesUseCase).toSelf();
        bind(GetAllIrpfTypesUseCase).toSelf();
        bind(GetAllIvaTypesUseCase).toSelf();
        bind(GetAllLocalsUseCase).toSelf();
        bind(GetAllMandateTerminationReasonsUseCase).toSelf();
        bind(GetAllMaterialSubtypesByTypeIdUseCase).toSelf();
        bind(GetAllMaterialTypesUseCase).toSelf();
        bind(GetAllMetricUnitUseCase).toSelf();
        bind(GetAllPaymentMethodTypesUseCase).toSelf();
        bind(GetAllPolicyProtocolsUsecase).toSelf();
        bind(GetAllPolicyProtocolTypesUseCase).toSelf();
        bind(GetAllPositionTypesUseCase).toSelf();
        bind(GetAllProfessionalGroupsUseCase).toSelf();
        bind(GetAllProjectAuditoriesUsecase).toSelf();
        bind(GetAllPublicInterestsUsecase).toSelf();
        bind(GetAllQualityCertificatesUsecase).toSelf();
        bind(GetAllRegconRegistersUsecase).toSelf();
        bind(GetAllRegisteredRecordsUsecase).toSelf();
        bind(GetAllServiceProvidedUseCase).toSelf();
        bind(GetAllServicesUseCase).toSelf();
        bind(GetAllSocialNetworksUseCase).toSelf();
        bind(GetAllStatuoryTerritorialScopesUsecase).toSelf();
        bind(GetAllStatusTypesUseCase).toSelf();
        bind(GetAllSubsidyReasonsUseCase).toSelf();
        bind(GetAllSupplierIdentificationTypesUseCase).toSelf();
        bind(GetAllSupplierTypesUseCase).toSelf();
        bind(GetAllTerminationReasonsUseCase).toSelf();
        bind(GetAllTitularityTypesUseCase).toSelf();
        bind(GetAllTypologiesUseCase).toSelf();
        bind(GetAllVolunteerContractsByUseCase).toSelf();
        bind(GetCostByIdUseCase).toSelf();
        bind(GetCostsByUseCase).toSelf();
        bind(GetCreditorByIdUseCase).toSelf();
        bind(GetCreditorsByUseCase).toSelf();
        bind(GetEmployeeByIdUseCase).toSelf();
        bind(GetEmployeesByUseCase).toSelf();
        bind(GetEmployeesCountByTypeUseCase).toSelf();
        bind(GetEntitiesByUseCase).toSelf();
        bind(GetEntityByIdUseCase).toSelf();
        bind(GetMaterialByIdUseCase).toSelf();
        bind(GetQualityCertificateTypesUseCase).toSelf();
        bind(GetSupplierByIdUseCase).toSelf();
        bind(GetSuppliersByUseCase).toSelf();
        bind(SearchDepositsByUseCase).toSelf();
        bind(SearchMaterialsByUseCase).toSelf();
        bind(SearchRoomsByUseCase).toSelf();
        bind(UpdateAdditionalExpensesUseCase).toSelf();
        bind(UpdateCostUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(AddEmployeeFromEntityModalFormViewModel).toSelf();
        bind(AddressTabViewModel).toSelf();
        bind(AffiliationTabViewModel).toSelf();
        bind(AuditoryViewModel).toSelf();
        bind(AuthorityManagerTabViewModel).toSelf();
        bind(ContactViewModel).toSelf();
        bind(ContractTabViewModel).toSelf();
        bind(ContractTerminationFormViewModel).toSelf();
        bind(ContractTerminationTabViewModel).toSelf();
        bind(CostDetailPageViewModel).toSelf();
        bind(CostFormViewModel).toSelf();
        bind(CostListPageViewModel).toSelf();
        bind(CreateCostPageViewModel).toSelf();
        bind(CreateEmployeePageViewModel).toSelf();
        bind(CreateEntityPageViewModel).toSelf();
        bind(CreateMaterialPageViewModel).toSelf();
        bind(CreateSupplierPageViewModel).toSelf();
        bind(CreditorDetailPageViewModel).toSelf();
        bind(CreditorListPageViewModel).toSelf();
        bind(DeleteCreditorUseCase).toSelf();
        bind(DepositFormViewModel).toSelf();
        bind(DepositViewModel).toSelf();
        bind(DetailMaterialPageViewModel).toSelf();
        bind(DocumentsTabViewModel).toSelf();
        bind(DocumentTabViewModel).toSelf();
        bind(EditCostPageViewModel).toSelf();
        bind(EditEmployeePageViewModel).toSelf();
        bind(EditEntityPageViewModel).toSelf();
        bind(EditMaterialPageViewModel).toSelf();
        bind(EditSupplierPageViewModel).toSelf();
        bind(EmployeeContractTabViewModel).toSelf();
        bind(EmployeeDetailPageViewModel).toSelf();
        bind(EmployeeGeneralTabViewModel).toSelf();
        bind(EmployeeListPageViewModel).toSelf();
        bind(EntityDetailPageViewModel).toSelf();
        bind(EntityListPageViewModel).toSelf();
        bind(ExpenseListPageViewModel).toSelf();
        bind(FreelanceContractTabViewModel).toSelf();
        bind(GeneralTabViewModel).toSelf();
        bind(LinkCostToProjectFormViewModel).toSelf();
        bind(LocalTabViewModel).toSelf();
        bind(LocalViewModel).toSelf();
        bind(MaterialFormViewModel).toSelf();
        bind(MaterialListPageViewModel).toSelf();
        bind(PolicyProtocolViewModel).toSelf();
        bind(ProjectAuditoryViewModel).toSelf();
        bind(ProjectTabViewModel).toSelf();
        bind(PublicInterestViewModel).toSelf();
        bind(QualityCertificateViewModel).toSelf();
        bind(RegconRegisterViewModel).toSelf();
        bind(RegisteredRecordViewModel).toSelf();
        bind(SocialNetworksViewModel).toSelf();
        bind(StatutoryTerritorialScopeViewModel).toSelf();
        bind(SupplierDetailPageViewModel).toSelf();
        bind(SupplierFormViewModel).toSelf();
        bind(SupplierListPageViewModel).toSelf();
        bind(VolunteerContractTabViewModel).toSelf();
    }),
}));

entityModule.registerModule();

export { entityModule };
