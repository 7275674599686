import {
    DownloadOutlined,
    LeftOutlined,
    ProjectOutlined,
} from "@ant-design/icons";
import { Undefinable, isDefined } from "@core/domain/types/undefinable.type";
import { IncCard } from "@core/presentacion/component/data-display/card/inc-card.component";
import { IncSpreadsheet } from "@core/presentacion/component/data-entry/spreadsheet/inc-spreadsheet.component";
import { IncSkeleton } from "@core/presentacion/component/feedback/skeleton/inc-skeleton.component";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { IncButton } from "@core/presentacion/component/general/button/inc-button.component";
import { IncCol } from "@core/presentacion/component/layout/col/inc-col.component";
import { IncRow } from "@core/presentacion/component/layout/row/inc-row.component";
import { useViewModel } from "@core/presentacion/hook/use-view-model/use-view-model.hook";
import { container } from "@di/inversify.config";
import { HotTableClass } from "@handsontable/react";
import { BudgetSpreadsheetValidationTypeEnum } from "@project/domain/models/budget-spreadsheet.model";
import { EditProjectNavigationState } from "@project/presentation/components/project-form/project-form.component";
import {
    BudgetSpreadsheetCategoryView,
    BudgetSpreadsheetGroupView,
    BudgetSpreadsheetItemView,
    BudgetSpreadsheetValidationView,
    BudgetSpreadsheetValueView,
    CellTypeData,
    EditBudgetPageViewModel,
} from "@project/presentation/pages/edit-budget/edit-budget-page.viewmodel";
import { ProjectRoutes } from "@routes/private/project.routes";
import { theme } from "@tailwind";
import { isNumber, isNumberString } from "class-validator";
import Handsontable from "handsontable/base";
import { DetailedSettings } from "handsontable/plugins/columnSummary/columnSummary";
import { CommentObject } from "handsontable/plugins/comments";
import { runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import numbro from "numbro";
import { FC, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import colors from "tailwindcss/colors";
// @ts-expect-error: Missing typescript definitions for language configuration objets
import esEs from "numbro/languages/es-ES";

numbro.registerLanguage(esEs, true);

const _EditBudgetPage: FC = () => {
    const params = useParams<"projectId" | "projectFinancialEntityId">();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const handsontableRef = useRef<HotTableClass>(null);

    const projectFinancialEntityId = Number(params.projectFinancialEntityId);

    const viewModel = useViewModel(() => {
        if (isNaN(projectFinancialEntityId)) {
            throw new Error("Invalid project financial entity id");
        }

        const vmInstance = container.get(EditBudgetPageViewModel);
        vmInstance.projectFinancialEntityId = projectFinancialEntityId;

        return vmInstance;
    });

    const getColumnId = (prop: string): string => prop.split(".")[0];

    const columns = useMemo<Handsontable.ColumnSettings[]>(() => {
        // Columns for Category, Group and Item
        const fixedColumns: Handsontable.ColumnSettings[] = [
            {
                // eslint-disable-next-line id-blacklist
                data: "categoryLabel",
                title: " ",
                readOnly: true,
            },
            {
                // eslint-disable-next-line id-blacklist
                data: "groupLabel",
                title: " ",
                readOnly: true,
            },
            {
                // eslint-disable-next-line id-blacklist
                data: "itemLabel",
                title: " ",
                readOnly: true,
            },
        ];

        const dynamicColumns: Handsontable.ColumnSettings[] = (
            viewModel.budgetSpreadsheetInmutable?.columns ?? []
        ).map((column) => ({
            // @see BudgetSpreadsheetValueView
            // eslint-disable-next-line id-blacklist
            data: `${column.id}.value`,
            title: column.label,
        }));

        return fixedColumns.concat(dynamicColumns);
    }, [viewModel.budgetSpreadsheetInmutable?.columns]);

    // Generate summary (sum) for each dynamic column at category/group level
    const columnSummary = useMemo<DetailedSettings[]>(() => {
        const summary: DetailedSettings[] = [];

        (viewModel.budgetSpreadsheetInmutable?.columns ?? []).forEach(
            (_column, columnIndex) => {
                let currentRowIndex = 0;
                const allRanges: [number, number][] = [];

                (viewModel.budgetSpreadsheetInmutable?.rows ?? []).forEach(
                    (category) => {
                        // We track the start index of the category to use it as destination row
                        const categoryStartIndex = currentRowIndex;
                        // Ranges are an array of arrays, each array is a range of rows
                        // So if we need to include for summary rows index from 1-6, 10 and 12-15
                        // ranges will contain  [1, 6], [10, 10], [12, 15]
                        // The same for groupRanges
                        const categoryRanges: [number, number][] = [];

                        category.__children.forEach((group) => {
                            currentRowIndex += 1;
                            // We track the start index of the group to use it as destination row
                            const groupStartIndex = currentRowIndex;
                            const groupRanges: number[][] = [];

                            // eslint-disable-next-line max-nested-callbacks
                            group.__children.forEach((item) => {
                                currentRowIndex += 1;

                                categoryRanges.push([
                                    // We are at item level (row) and the value is below
                                    currentRowIndex + 1,
                                    currentRowIndex + item.__children.length,
                                ]);

                                groupRanges.push([
                                    // We are at item level (row) and the value is below
                                    currentRowIndex + 1,
                                    currentRowIndex + item.__children.length,
                                ]);

                                allRanges.push([
                                    // We are at item level (row) and the value is below
                                    currentRowIndex + 1,
                                    currentRowIndex + item.__children.length,
                                ]);

                                // eslint-disable-next-line max-nested-callbacks
                                item.__children.forEach(() => {
                                    currentRowIndex += 1;
                                });
                            });

                            // push summary for group with all item ranges
                            summary.push({
                                destinationRow: groupStartIndex,
                                ranges: groupRanges,
                                destinationColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                sourceColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                type: "sum",
                            });
                        });

                        // push summary for category with all group ranges
                        if (
                            category.categoryId ===
                            EditBudgetPageViewModel.TOTAL_SUMMARY_CATEGORY_ID
                        ) {
                            summary.push({
                                destinationRow: currentRowIndex,
                                // destinationRow: 0,
                                // reversedRowCoords: true,
                                ranges: allRanges,
                                destinationColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                sourceColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                type: "sum",
                            });
                        } else {
                            summary.push({
                                destinationRow: categoryStartIndex,
                                ranges: categoryRanges,
                                destinationColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                sourceColumn:
                                    // @see EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET +
                                    columnIndex,
                                type: "sum",
                            });

                            // push budget summary with all ranges
                        }

                        currentRowIndex += 1;
                    },
                );
            },
        );

        return summary;
    }, [
        viewModel.budgetSpreadsheetInmutable?.columns,
        viewModel.budgetSpreadsheetInmutable?.rows,
    ]);

    const handleExportFinancialEntity = useCallback(async () => {
        if (isNaN(projectFinancialEntityId)) {
            throw new Error("Invalid project financial entity id");
        }
        await viewModel.exportFinancialEntity(projectFinancialEntityId);
    }, [projectFinancialEntityId, viewModel]);

    const handleCells = useCallback(function cells(
        this: Handsontable.CellProperties,
        row: number,
        column: number,
        prop: string | number,
    ): Handsontable.CellMeta {
        /* eslint-disable react/no-this-in-sfc */
        const rowObject = this.instance.getSourceDataAtRow(row) as CellTypeData;

        const cellProperties: Handsontable.CellMeta = {
            readOnly: true,
            renderer(
                _instance,
                tableCell,
                _row,
                col,
                _prop,
                _value,
                _cellProperties,
            ) {
                let columnType;
                runInAction(() => {
                    if (
                        viewModel.budgetSpreadsheetInmutable?.columns[
                            col - EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                        ]
                    )
                        columnType =
                            viewModel.budgetSpreadsheetInmutable.columns[
                                col -
                                    EditBudgetPageViewModel.SUMMARY_COLUMNS_OFFSET
                            ].type;
                });

                if (
                    typeof _value === "number" &&
                    columnType === BudgetSpreadsheetValidationTypeEnum.Amount
                ) {
                    // In normal circumstances we should specify format via columns options with numericFormat and type: numeric
                    // But we are using renderer to format the value and set styles, so we need to format the value here
                    tableCell.innerHTML = numbro(_value).formatCurrency({
                        average: false,
                        thousandSeparated: true,
                        mantissa: 2,
                    });
                } else if (
                    typeof _value === "number" &&
                    columnType ===
                        BudgetSpreadsheetValidationTypeEnum.Percentage
                ) {
                    tableCell.innerHTML = numbro(_value).formatCurrency({
                        average: false,
                        thousandSeparated: false,
                        mantissa: 2,
                        currencySymbol: "%",
                    });
                } else {
                    tableCell.innerHTML = _value;
                }

                // @ts-expect-error: TW
                const errorColor = theme.extend.colors["coral-red"];

                // We display the cell in different colors depending on the level. Also for category/group we handle invalid cell display style
                if (
                    Object.hasOwn(
                        rowObject,
                        "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
                    )
                ) {
                    tableCell.style.backgroundColor =
                        _cellProperties.valid === false
                            ? errorColor
                            : // @ts-expect-error: TW
                              theme.extend.colors.cyan[950];
                    tableCell.style.color = colors.white;
                    tableCell.style.fontWeight = "bold";
                    tableCell.style.fontSize = "1.2rem";
                    tableCell.style.textAlign = "center";
                    tableCell.style.verticalAlign = "middle";
                    tableCell.style.padding = "0.5rem";
                    tableCell.style.borderBottom = "1px solid #fff";
                } else if (
                    Object.hasOwn(
                        rowObject,
                        "groupId" satisfies keyof BudgetSpreadsheetGroupView,
                    ) &&
                    col >= EditBudgetPageViewModel.GROUP_COLUMN_INDEX
                ) {
                    tableCell.style.backgroundColor =
                        _cellProperties.valid === false
                            ? errorColor
                            : // @ts-expect-error: TW
                              theme.extend.colors.cyan[800];
                    // @ts-expect-error: TW
                    tableCell.style.color = theme.extend.colors.white;
                    tableCell.style.fontWeight = "bold";
                    tableCell.style.fontSize = "1.1rem";
                    tableCell.style.textAlign = "center";
                    tableCell.style.verticalAlign = "middle";
                    tableCell.style.padding = "0.5rem";
                    tableCell.style.borderBottom = "1px solid #fff";
                } else if (
                    Object.hasOwn(
                        rowObject,
                        "itemId" satisfies keyof BudgetSpreadsheetItemView,
                    ) &&
                    col >= EditBudgetPageViewModel.ITEM_COLUMN_INDEX
                ) {
                    tableCell.style.backgroundColor =
                        // @ts-expect-error: TW
                        theme.extend.colors.cyan[500];
                    // @ts-expect-error: TW
                    tableCell.style.color = theme.extend.colors.white;
                    tableCell.style.fontSize = "1rem";
                    tableCell.style.textAlign = "left";
                    tableCell.style.verticalAlign = "middle";
                    tableCell.style.padding = "0.5rem";
                    tableCell.style.borderBottom = "1px solid #fff";
                } else if (col >= EditBudgetPageViewModel.VALUE_COLUMN_INDEX) {
                    tableCell.style.backgroundColor =
                        // @ts-expect-error: TW
                        theme.extend.colors.cyan[200];
                    tableCell.style.color = colors.black;
                    tableCell.style.fontSize = "1rem";
                    tableCell.style.textAlign = "left";
                    tableCell.style.verticalAlign = "middle";
                    tableCell.style.padding = "0.5rem";
                    tableCell.style.borderBottom = "1px solid #fff";
                }
            },
        };

        // Only dynamic columns are editable, and they are identified as [idColumn].value
        if (
            this.readOnly === false &&
            typeof prop === "string" &&
            prop.includes("value")
        ) {
            // Example of prop: 1.value where 1 is the column id
            const id = Number(getColumnId(prop));

            if (
                isNumber(id) &&
                isDefined(
                    // We guess the type of the rowObject, that why optional chaining
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    (rowObject as BudgetSpreadsheetValueView)?.[id]?.value,
                )
            ) {
                cellProperties.readOnly = false;
                // We set the type of the cell to numeric format be applied
                cellProperties.type = "numeric";
            }
        }

        return cellProperties;

        /* eslint-enable react/no-this-in-sfc */
    }, []);

    const handleAfterChange = useCallback(
        function afterChange(
            this: Handsontable,
            changes: Handsontable.CellChange[] | null,
            source: Handsontable.ChangeSource,
        ): void {
            /* eslint-disable react/no-this-in-sfc */
            if (source === "edit" && changes) {
                changes.forEach(([row, prop, _oldValue]) => {
                    const rowObject = this.getSourceDataAtRow(
                        changes[0][0],
                    ) as unknown as BudgetSpreadsheetValueView;

                    if (typeof prop === "string") {
                        const columnId = getColumnId(prop);

                        if (isNumberString(columnId)) {
                            const columnIndex: number = this.propToCol(prop);
                            const valueItem = rowObject[Number(columnId)];

                            if (valueItem) {
                                const formattedValue = numbro(
                                    valueItem.value,
                                ).value();
                                if (valueItem.value !== formattedValue) {
                                    this.setDataAtCell(
                                        row,
                                        columnIndex,
                                        formattedValue,
                                    );
                                }
                            }

                            // If the cell is valid we add it to the changed cells
                            if (
                                this.getCellMeta(row, columnIndex).valid ===
                                true
                            ) {
                                viewModel.setChangedCell(
                                    valueItem.id,
                                    valueItem.value,
                                );
                            } else if (viewModel.hasChangedCell(valueItem.id)) {
                                viewModel.removeChangedCell(valueItem.id);
                            }
                        }
                    }
                });
                // Numeric format is only visual so even if the cell has something like
                // 12.000,45€ o 12,45€ the internal value is a number (12000.45 or 12.45)
                // Here we are intercepting the paste event, so we can convert the value with format copied from outsisde the table to number
                // Values copied from the table are already numbers because copy takes the internal value rather than the visual value (formated)
            } else if (source === "CopyPaste.paste" && changes) {
                changes.forEach(([row, colProp, _oldValue, newValue]) => {
                    if (typeof colProp === "string") {
                        const columnId = getColumnId(colProp);

                        if (isNumberString(columnId)) {
                            const columnIndex: number = this.propToCol(colProp);

                            const formattedValue = numbro(newValue).value();
                            if (newValue !== formattedValue) {
                                this.setDataAtCell(
                                    row,
                                    columnIndex,
                                    formattedValue,
                                );
                            }
                        }
                    }
                });
            }
            /* eslint-enable react/no-this-in-sfc */
        },
        [viewModel],
    );

    const handleUpdateBudget = useCallback(async () => {
        viewModel.setValidatingBeforeSave(true);

        // We validate all cells before save
        handsontableRef.current?.hotInstance?.validateCells(
            async (isValid: boolean) => {
                if (isValid) {
                    const edited = await viewModel.updateBudget();

                    if (edited) {
                        viewModel.getBudget();
                    }
                } else {
                    ToastManagerStore.warning(
                        t("private:project.editBudget.someInvalidCellError"),
                    );
                }

                viewModel.setValidatingBeforeSave(false);
            },
        );
    }, [t, viewModel]);

    const handleGoBack = useCallback(() => {
        const projectId = Number(params.projectId);
        if (isNaN(projectId)) {
            throw new Error("Invalid project id");
        }

        const state: EditProjectNavigationState = {
            defaultTab: "budget",
        };

        navigate(ProjectRoutes.EDIT(projectId), {
            state,
        });
    }, [navigate, params.projectId]);

    const handleValidator = useCallback(function validator(
        this: Handsontable.CellProperties,
        value: Handsontable.CellValue,
        callBack: (valid: boolean) => void,
    ): void {
        /*  eslint-disable react/no-this-in-sfc */
        const row = this.instance.getSourceDataAtRow(this.row) as
            | BudgetSpreadsheetCategoryView
            | BudgetSpreadsheetGroupView
            | BudgetSpreadsheetValueView;

        // Only category and group have validations defined. The validations applies to the summary columns.
        // For example, if we have a category with a validation of 20000, the sum of all values of the summary columns should not be greater than 20000
        // A category/group can define different validations for each column

        if (
            Object.hasOwn(
                row,
                "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
            ) ||
            Object.hasOwn(
                row,
                "groupId" satisfies keyof BudgetSpreadsheetGroupView,
            )
        ) {
            const validations = (
                row as
                    | BudgetSpreadsheetCategoryView
                    | BudgetSpreadsheetGroupView
            ).validations;

            const col = (this.prop as string).split(".")[0];

            const validationsValues = validations
                ? Array.from(validations.values())
                : [];

            const validationsValuesFiltered: BudgetSpreadsheetValidationView[] =
                validationsValues.filter(
                    (validationsValue) =>
                        validationsValue.columnId === Number(col),
                );

            if (validationsValuesFiltered.length) {
                validationsValuesFiltered.forEach((validation) => {
                    const colProp = this.instance.colToProp(this.col);
                    const colId = getColumnId(colProp.toString());

                    const isAmountValidation =
                        validation.type ===
                        BudgetSpreadsheetValidationTypeEnum.Amount;

                    const isPercentageValidation =
                        validation.type ===
                        BudgetSpreadsheetValidationTypeEnum.Percentage;

                    const isOnlyValueBasedValidation =
                        isAmountValidation ||
                        (isPercentageValidation &&
                            validation.percentage === undefined);

                    if (
                        isOnlyValueBasedValidation &&
                        value > validation.valueToCompare
                    ) {
                        callBack(false);
                        const comment: CommentObject = {
                            value: validation.message,
                            readOnly: true,
                        };
                        this.instance.setCellMeta(
                            this.row,
                            this.col,
                            "comment",
                            comment,
                        );

                        this.instance.render();
                    } else if (isPercentageValidation) {
                        if (validation.percentage?.validateAgainstTotal) {
                            const totalRowIndex = this.instance.countRows() - 1;
                            const totalRowData =
                                this.instance.getSourceDataAtRow(
                                    totalRowIndex,
                                ) as BudgetSpreadsheetCategoryView;

                            const totalValue =
                                totalRowData[
                                    this.col -
                                        EditBudgetPageViewModel.ITEM_COLUMN_INDEX
                                ]?.value;

                            if (value > validation.valueToCompare) {
                                callBack(false);
                                const comment: CommentObject = {
                                    value: validation.message,
                                    readOnly: true,
                                };
                                this.instance.setCellMeta(
                                    this.row,
                                    this.col,
                                    "comment",
                                    comment,
                                );

                                this.instance.render();
                            } else {
                                callBack(true);

                                this.instance.removeCellMeta(
                                    this.row,
                                    this.col,
                                    "comment",
                                );

                                this.instance.render();
                            }
                        } else if (
                            validation.percentage?.categoryIdToCompare !==
                            undefined
                        ) {
                            const categoryRowIndexToCompare = this.instance
                                .getSourceData()
                                .findIndex(
                                    (maybeCategoryRow) =>
                                        Object.hasOwn(
                                            maybeCategoryRow,
                                            "categoryId" satisfies keyof BudgetSpreadsheetCategoryView,
                                        ) &&
                                        maybeCategoryRow.categoryId ===
                                            validation.percentage
                                                ?.categoryIdToCompare,
                                );

                            if (categoryRowIndexToCompare === -1) {
                                callBack(true);
                                this.instance.render();

                                return;
                            }

                            const categoryRowToCompare =
                                this.instance.getSourceDataAtRow(
                                    categoryRowIndexToCompare,
                                ) as BudgetSpreadsheetCategoryView;

                            let groupRowIndexToCompare: Undefinable<number> =
                                undefined;
                            if (
                                validation.percentage.groupIdToCompare !==
                                undefined
                            ) {
                                groupRowIndexToCompare =
                                    categoryRowToCompare.__children.findIndex(
                                        (group) =>
                                            group.groupId ===
                                            validation.percentage
                                                ?.groupIdToCompare,
                                    );

                                if (groupRowIndexToCompare === undefined) {
                                    callBack(true);
                                    this.instance.render();

                                    return;
                                }
                            }

                            if (value > validation.valueToCompare) {
                                callBack(false);

                                const comment: CommentObject = {
                                    value: validation.message,
                                    readOnly: true,
                                };
                                this.instance.setCellMeta(
                                    this.row,
                                    this.col,
                                    "comment",
                                    comment,
                                );

                                this.instance.render();
                            } else {
                                callBack(true);

                                this.instance.removeCellMeta(
                                    this.row,
                                    this.col,
                                    "comment",
                                );

                                this.instance.render();
                            }
                        } else {
                            callBack(true);

                            this.instance.removeCellMeta(
                                this.row,
                                this.col,
                                "comment",
                            );

                            this.instance.render();
                        }
                    } else {
                        callBack(true);

                        this.instance.removeCellMeta(
                            this.row,
                            this.col,
                            "comment",
                        );

                        this.instance.render();
                    }
                });
            } else {
                callBack(true);

                this.instance.removeCellMeta(this.row, this.col, "comment");

                this.instance.render();
            }
        } else {
            callBack(true);
            this.instance.render();
        }

        /*  eslint-enable react/no-this-in-sfc */
    }, []);

    return (
        <>
            <IncRow gutter={["medium", "medium"]}>
                <IncCol span={24}>
                    <IncCard>
                        <IncRow
                            align={"middle"}
                            justify={"space-between"}
                        >
                            <IncCol span={12}>
                                <IncRow
                                    gutter={"small"}
                                    justify={"start"}
                                    align={"middle"}
                                >
                                    <IncCol>
                                        <IncButton
                                            onClick={handleGoBack}
                                            icon={<LeftOutlined />}
                                        />
                                    </IncCol>
                                    <IncCol>
                                        <ProjectOutlined
                                            className={"tw-text-3xl"}
                                        />
                                    </IncCol>
                                    <IncCol>
                                        <h2>
                                            {t(
                                                "private:project.editBudget.title",
                                            )}
                                        </h2>
                                    </IncCol>
                                </IncRow>
                            </IncCol>
                            <IncCol>
                                <IncRow
                                    gutter={"small"}
                                    justify={"end"}
                                    align={"middle"}
                                >
                                    <IncCol>
                                        <IncButton.Form
                                            type={"primary"}
                                            icon={<DownloadOutlined />}
                                            onClick={
                                                handleExportFinancialEntity
                                            }
                                        >
                                            {t(
                                                "private:project.editBudget.exportFinancialEntityBtn",
                                            )}
                                        </IncButton.Form>
                                    </IncCol>
                                    <IncCol>
                                        <IncButton.Form
                                            type={"primary"}
                                            disabled={!viewModel.anyCellChanged}
                                            loading={
                                                viewModel.validatingBeforeSave
                                            }
                                            onClick={handleUpdateBudget}
                                        >
                                            {viewModel.validatingBeforeSave
                                                ? t(
                                                      "private:project.editBudget.validating",
                                                  )
                                                : t("common:saveButton")}
                                        </IncButton.Form>
                                    </IncCol>
                                </IncRow>
                            </IncCol>
                        </IncRow>
                    </IncCard>
                </IncCol>
            </IncRow>
            <IncRow className={"tw-mt-4"}>
                <IncCol span={24}>
                    <IncCard>
                        <IncSkeleton loading={viewModel.initialLoading}>
                            {viewModel.budgetSpreadsheetMutable ? (
                                <div
                                    style={{
                                        height: "700px",
                                        width: "100%",
                                        overflow: "auto",
                                    }}
                                >
                                    <IncSpreadsheet
                                        width={"100%"}
                                        height={700}
                                        ref={handsontableRef}
                                        key={
                                            viewModel.budgetSpreadsheetMutable
                                                .uuid
                                        }
                                        afterChange={handleAfterChange}
                                        validator={handleValidator}
                                        columns={columns}
                                        columnSummary={columnSummary}
                                        data={toJS(
                                            viewModel.budgetSpreadsheetMutable
                                                .rows,
                                        )}
                                        comments
                                        rowHeaders
                                        nestedRows
                                        bindRowsWithHeaders
                                        cells={handleCells}
                                    />
                                </div>
                            ) : null}
                        </IncSkeleton>
                    </IncCard>
                </IncCol>
            </IncRow>
        </>
    );
};

export const EditBudgetPage = observer(_EditBudgetPage);
