import { IncDocument } from "@core/domain/models/inc-document.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { ProjectActivityTask } from "./project-activity-task.model";

export interface TechnicalProposalsFilters {
    projectId?: Undefinable<number>;
}

export interface ActivitySlot {
    name: string;
    value: string;
}

export class Indicator {
    constructor(
        public readonly id: number,
        public activityId: number,
        public estimatedIndicator: string,
        public achievedIndicator: string,
        public verificationFont: string,
        public status?: Undefinable<string>,
        public activityName?: Undefinable<string>,
        public slots?: Undefinable<ActivitySlot[]>,
    ) {}
}

export enum ActivityStatusEnum {
    toDo = "toDo",
    inProgress = "inProgress",
    inTesting = "inTesting",
    done = "done",
}

export class Activity {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: number,
        public name: string,
        public description: string,
        public needs: string,
        public objective: number,
        public estimatedExecutionStartDate?: Undefinable<DateTime>,
        public estimatedExecutionEndDate?: Undefinable<DateTime>,
        public executionStartDate?: Undefinable<DateTime>,
        public executionEndDate?: Undefinable<DateTime>,
        public employeeIds: number[] = [],
        public indicators: Indicator[] = [],
        public status: ActivityStatusEnum = ActivityStatusEnum.toDo,
        public assignedEmployee?: Undefinable<number>,
        public technicalProposalId?: Undefinable<number>,
        public deadline?: DateTime,
        public comment?: Undefinable<string>,
        public tasks: ProjectActivityTask[] = [],
        public materialIds: number[] = [],
    ) {}
}

export class Objective {
    constructor(
        public readonly id: number,
        public name: string,
        public description: string,
        public technicalProposalId: number,
        public activities: Activity[] = [],
    ) {}
}

export class TechnicalProposal {
    constructor(
        public readonly id: number,
        public readonly projectId: number,
        public overallObjective: string,
        public objectives: Objective[] = [],
        public documents: IncDocument[] = [],
    ) {}
}

export class TechnicalProposals {
    constructor(
        public readonly technicalProposals: TechnicalProposal[],
        public readonly total: number,
    ) {}
}

export class CreateIndicator {
    constructor(
        public readonly activityId: Indicator["activityId"],
        public readonly estimatedIndicator: Indicator["estimatedIndicator"],
        public readonly achievedIndicator: Indicator["achievedIndicator"],
        public readonly verificationFont: Indicator["verificationFont"],
        public readonly status?: Indicator["status"],
    ) {}
}

export class EditIndicator extends CreateIndicator {
    constructor(
        public readonly id: Indicator["id"],
        activityId: CreateIndicator["activityId"],
        estimatedIndicator: CreateIndicator["estimatedIndicator"],
        achievedIndicator: CreateIndicator["achievedIndicator"],
        verificationFont: CreateIndicator["verificationFont"],
        status?: CreateIndicator["status"],
        public slots?: Indicator["slots"],
    ) {
        super(
            activityId,
            estimatedIndicator,
            achievedIndicator,
            verificationFont,
            status,
        );
    }
}

export class CreateActivity {
    // eslint-disable-next-line max-params
    constructor(
        public readonly objectiveId: Activity["objective"],
        public readonly name: Activity["name"],
        public readonly description: Activity["description"],
        public readonly needs: Activity["needs"],
        public readonly estimatedExecutionStartDate?: Activity["estimatedExecutionStartDate"],
        public readonly estimatedExecutionEndDate?: Activity["estimatedExecutionEndDate"],
        public readonly executionStartDate?: Activity["executionStartDate"],
        public readonly executionEndDate?: Activity["executionEndDate"],
        public readonly employeeIds?: Activity["employeeIds"],
        public readonly materialIds?: Activity["materialIds"],
        public readonly indicators: CreateIndicator[] = [],
    ) {}
}

export class EditActivity extends CreateActivity {
    // eslint-disable-next-line max-params
    constructor(
        public readonly id: Activity["id"],
        objectiveId: Activity["objective"],
        name: Activity["name"],
        description: Activity["description"],
        needs: Activity["needs"],
        estimatedExecutionStartDate?: Activity["estimatedExecutionStartDate"],
        estimatedExecutionEndDate?: Activity["estimatedExecutionEndDate"],
        executionStartDate?: Activity["executionStartDate"],
        executionEndDate?: Activity["executionEndDate"],
        employeeIds?: Activity["employeeIds"],
        materialIds?: Activity["materialIds"],
        public override indicators: (CreateIndicator | EditIndicator)[] = [],
    ) {
        super(
            objectiveId,
            name,
            description,
            needs,
            estimatedExecutionStartDate,
            estimatedExecutionEndDate,
            executionStartDate,
            executionEndDate,
            employeeIds,
            materialIds,
            indicators,
        );
    }
}

export class CreateObjective {
    constructor(
        public readonly name: Objective["name"],
        public readonly description: Objective["description"],
        public readonly technicalProposalId: Objective["technicalProposalId"],
    ) {}
}

export class EditObjective extends CreateObjective {
    constructor(
        public readonly id: Objective["id"],
        name: Objective["name"],
        description: Objective["description"],
        technicalProposalId: Objective["technicalProposalId"],
    ) {
        super(name, description, technicalProposalId);
    }
}

export class CreateTechnicalProposal {
    constructor(
        public readonly overallObjective: TechnicalProposal["overallObjective"],
        public readonly projectId: TechnicalProposal["projectId"],
        public readonly documents?: Undefinable<IncDocument[]>,
    ) {}
}

export class EditTechnicalProposal extends CreateTechnicalProposal {
    constructor(
        public readonly id: TechnicalProposal["id"],
        project: TechnicalProposal["projectId"],
        overallObjective: TechnicalProposal["overallObjective"],
        documents?: TechnicalProposal["documents"],
    ) {
        super(overallObjective, project, documents);
    }
}
