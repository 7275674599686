import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { ProjectEmployeeDedicationMapper } from "@project/data/mappers/project-employee-dedication.mapper";
import { CreateProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/create-project-employee-dedication.usecase";
import { DedicationFormValuesValidated } from "@project/presentation/components/project-form/employees/add-dedication-modal-form.view";
import { inject, injectable } from "inversify";

@injectable()
export class AddDedicationModalFormViewModel extends BaseViewModel {
    constructor(
        @inject(CreateProjectEmployeeDedicationUseCase)
        private readonly createProjectEmployeeDedicationUseCase: CreateProjectEmployeeDedicationUseCase,
        @inject(ProjectEmployeeDedicationMapper)
        private readonly projectEmployeeDedicationMapper: ProjectEmployeeDedicationMapper,
    ) {
        super();
    }

    async addDedication(
        projectEmployeeId: number,
        dedication: DedicationFormValuesValidated,
    ): Promise<boolean> {
        LoadLayoutStore.start();

        const projectEmployeeDedication =
            this.projectEmployeeDedicationMapper.mapFromFormToCreate(
                projectEmployeeId,
                dedication,
            );
        const created =
            await this.createProjectEmployeeDedicationUseCase.execute(
                projectEmployeeDedication,
            );

        if (created) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return !!created;
    }
}
