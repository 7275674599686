import { CountryEnum } from "@core/domain/models/country.model";
import { DiagnosisTypeEnum } from "@core/domain/models/diagnosis-type.model";
import { EducationLevelEnum } from "@core/domain/models/education-level.model";
import { EmploymentStatusEnum } from "@core/domain/models/employment-status.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { Nullish } from "@core/domain/types/nullish.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { DateTime } from "luxon";
import { Address } from "./address.model";
import { AssociateServiceToDependenceEnum } from "./associate-service-to-dependence.enum";
import { BeneficiaryIdentification } from "./beneficiary.model";
import { CreateBeneficiaryIdentification } from "./create-beneficiary.model";
import { EmploymentSituationRelevantCharacteristicsEnum } from "./employment-situation-relevant-characteristics.enum";
import { GenderEnum } from "./gender.model";
import { MaritalStatusEnum } from "./marital-status.model";
import { EditRelative } from "./relatives/edit-relative.model";

export enum DisabilityTypeEnum {
    Disability33 = "disability_33",
    Disability65 = "disability_65",
    Dependence1 = "dependence_1",
    Dependence2 = "dependence_2",
    Dependence3 = "dependence_3",
    DependenceNotRecognized = "dependence_not_recognized",
    DisabilityNotRecognized = "disability_not_recognized",
}

export enum DependenceGradeEnum {
    DependenceGrade1 = "dependence_grade_1",
    DependenceGrade2 = "dependence_grade_2",
    DependenceGrade3 = "dependence_grade_3",
}

export enum TherapyTypeEnum {
    MedicalTherapies = "medical_therapies",
    PsychologicalTherapies = "psychological_therapies",
    OccupationalTherapy = "occupational_therapy",
    MusicTherapy = "music_therapy",
    OtherTherapy = "other_therapy",
}

export class EditBeneficiary {
    constructor(
        public readonly id: number,
        public name: string,
        public surname: string,
        public identificationsCreate: CreateBeneficiaryIdentification[],
        public identificationsEdit: BeneficiaryIdentification[],
        public gender: GenderEnum,
        public phone: Nullish<string>,
        public phone_owner: Nullish<string>,
        public email: Nullish<string>,
        public email_owner: Nullish<string>,
        public nationality: CountryEnum,
        public country_of_origin: CountryEnum,
        public birth_date: DateTime,
        public marital_status: MaritalStatusEnum,
        public level_of_education: EducationLevelEnum,
        public complementary_training: Nullish<string>,
        public employment_situation: EmploymentStatusEnum,
        public employmentSituationDescription: Undefinable<
            EmploymentSituationRelevantCharacteristicsEnum[]
        >,
        public otherEmploymentDescription: Nullable<string>,
        // public intervention_date: Nullable<DateTime>,
        public disability: boolean,
        public disability_type: Nullable<DisabilityTypeEnum[]>,
        public health_observations: string,
        public dependence: boolean,
        public dependence_grade: Nullable<DependenceGradeEnum>,
        public in_therapy: boolean,
        public therapy_type: Nullable<TherapyTypeEnum>,
        public isDiagnosed: boolean,
        public diagnosisType: Nullable<DiagnosisTypeEnum>,
        public diagnosisDate: Nullable<DateTime>,
        public hasLegalGuardian: boolean,
        public associateServiceToDependence: Nullable<AssociateServiceToDependenceEnum>,
        public dependenceDiagnosis: Undefinable<string>,
        public dependenceObservations: Nullable<string>,
        public under_protection: boolean,
        public censusAddress: Address,
        public relatives: EditRelative[],
        public usualAddress?: Undefinable<Address>,
        public documentIds: number[] = [],
    ) {}
}
