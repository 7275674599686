import { LanguageRemoteDatasource } from "@core/data/datasources/language-remote.datasource";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import {
    Language,
    LanguageIsoCodeEnum,
} from "@core/domain/models/language.model";
import { Either } from "@core/domain/types/either";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { inject, injectable } from "inversify";
import { BehaviorSubject, Observable } from "rxjs";
import { LanguageLocalDatasource } from "../datasources/language-local.datasource";

@injectable()
export class LanguageRepository {
    private languages: Undefinable<Language[]>;

    private _currentLanguage: BehaviorSubject<
        Undefinable<LanguageIsoCodeEnum>
    > = new BehaviorSubject<Undefinable<LanguageIsoCodeEnum>>(undefined);

    get currentLanguage(): Observable<Undefinable<LanguageIsoCodeEnum>> {
        return this._currentLanguage;
    }

    constructor(
        @inject(LanguageRemoteDatasource)
        private readonly languageRemoteDatasource: LanguageRemoteDatasource,
        @inject(LanguageLocalDatasource)
        private readonly languageLocalDatasource: LanguageLocalDatasource,
    ) {}

    async getAll(): Promise<Either<FallbackError, Language[]>> {
        if (this.languages) {
            return Either.Right(this.languages);
        }

        const languagesResult = await this.languageRemoteDatasource.fetchAll();

        if (languagesResult.isRight()) {
            this.languages = languagesResult.getOrThrow();
        }

        return languagesResult;
    }

    async getCurrent(): Promise<Either<FallbackError, LanguageIsoCodeEnum>> {
        if (this._currentLanguage.value) {
            return Either.Right(this._currentLanguage.value);
        }

        const currentLanguageResult = this.languageLocalDatasource.getCurrent();

        if (currentLanguageResult.isLeft())
            return Either.Left(currentLanguageResult.getLeftOrThrow());

        const languagesResult = await this.getAll();
        if (languagesResult.isLeft())
            return Either.Left(languagesResult.getLeftOrThrow());

        const matchedLanguage = languagesResult
            .getOrThrow()
            .find(
                (lang) => lang.isoCode === currentLanguageResult.getOrThrow(),
            );
        if (!matchedLanguage) {
            const defaultLanguageResult =
                this.languageLocalDatasource.getDefault();

            if (defaultLanguageResult.isLeft())
                return Either.Left(currentLanguageResult.getLeftOrThrow());
            const defaultLanguageResultValue =
                defaultLanguageResult.getOrThrow();

            let defaultLanguage: Language | undefined;

            if (Array.isArray(defaultLanguageResultValue)) {
                defaultLanguage = languagesResult
                    .getOrThrow()
                    .find((lang) =>
                        defaultLanguageResultValue.some(
                            (currentLang) => currentLang === lang.isoCode,
                        ),
                    );
            } else {
                console.error(
                    "Error in the fallbackLng configuration of i18n.",
                );
                return Either.Left(new FallbackError());
            }

            if (!defaultLanguage) {
                return Either.Left(new FallbackError());
            }

            this._currentLanguage.next(defaultLanguage.isoCode);
            return Either.Right(defaultLanguage.isoCode);
        }

        this._currentLanguage.next(matchedLanguage.isoCode);
        return Either.Right(matchedLanguage.isoCode);
    }

    async update(
        language: LanguageIsoCodeEnum,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        const remoteUpdateResult =
            await this.languageRemoteDatasource.update(language);
        if (remoteUpdateResult.isLeft()) {
            return Either.Left(remoteUpdateResult.getLeftOrThrow());
        }
        await this.languageLocalDatasource.update(language);
        this._currentLanguage.next(language);

        return Either.Right(true);
    }
}
