import { IncDocument } from "@core/domain/models/inc-document.model";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { Project } from "./project.model";

export class CreateProject {
    public description?: Undefinable<Project["description"]>;
    public territorialScopeId?: Undefinable<Project["territorialScopeId"]>;
    public catalogueIds?: Undefinable<Project["catalogueIds"]>;
    public documents?: Undefinable<IncDocument[]>;
    public isActive?: Undefinable<Project["isActive"]>;
    public notes?: Undefinable<Project["notes"]>;

    constructor(
        public name: Project["name"],
        public year: Project["year"],
        public statusId: Project["statusId"],
    ) {}
}
