import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import {
    BudgetValidationDto,
    CreateBudgetValidationBody,
    EditBudgetValidationBody,
} from "@project/data/dto/budget.dto";
import { BudgetColumnTypeEnumDto } from "@project/data/dto/budget/column/budget-column-type.dto";
import { BudgetColumnTypeEnumMapper } from "@project/data/mappers/budget/columns/budget-column-type-enum.mapper";
import { BudgetColumnTypeEnum } from "@project/domain/models/budget/budget-column/budget-column-type.model";
import {
    BudgetPercentageValidation,
    BudgetValidation,
    CreateBudgetValidation,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { BudgetValidationFormValuesValidated } from "@project/presentation/components/budget-form/budget-validations/budget-validation-form.component";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetValidationMapper {
    constructor(
        @inject(BudgetColumnTypeEnumMapper)
        private readonly budgetColumnTypeEnumMapper: BudgetColumnTypeEnumMapper,
    ) {}

    map(budgetValidationDto: BudgetValidationDto): Nullable<BudgetValidation> {
        const errors = validateSync(budgetValidationDto);
        if (errors.length) {
            console.error(errors);

            return null;
        }

        let type: Undefinable<BudgetColumnTypeEnum> = undefined;

        if (budgetValidationDto.type) {
            const mappedType = this.budgetColumnTypeEnumMapper.map(
                budgetValidationDto.type,
            );

            if (!mappedType) {
                return null;
            }

            type = mappedType;
        }

        let percentage: Undefinable<BudgetPercentageValidation> = undefined;
        if (
            budgetValidationDto.is_total_comparison ||
            budgetValidationDto.category_to_compare !== null
        ) {
            percentage = new BudgetPercentageValidation(
                budgetValidationDto.is_total_comparison,
                budgetValidationDto.category_to_compare ?? undefined,
                budgetValidationDto.group_to_compare ?? undefined,
            );
        }

        return new BudgetValidation(
            budgetValidationDto.id,
            budgetValidationDto.budget,
            budgetValidationDto.name,
            budgetValidationDto.column,
            type,
            budgetValidationDto.validation_message,
            budgetValidationDto.category,
            budgetValidationDto.group ?? undefined,
            budgetValidationDto.value ?? undefined,
            percentage,
        );
    }

    mapFormToCreateBudgetValidation(
        budgetId: number,
        form: BudgetValidationFormValuesValidated,
    ): CreateBudgetValidation {
        let percentage: Undefinable<BudgetPercentageValidation> = undefined;
        if (form.validateAgainstTotal || form.categoryIdToCompare !== null) {
            percentage = new BudgetPercentageValidation(
                form.validateAgainstTotal,
                form.categoryIdToCompare ?? undefined,
                form.groupIdToCompare ?? undefined,
            );
        }

        return new CreateBudgetValidation(
            budgetId,
            form.name,
            form.columnId,
            form.columnValidationType,
            form.message,
            form.categoryId,
            form.groupId ?? undefined,
            form.value,
            percentage,
        );
    }

    mapFromForm(
        id: number,
        budgetId: number,
        form: BudgetValidationFormValuesValidated,
    ): BudgetValidation {
        let percentage: Undefinable<BudgetPercentageValidation> = undefined;
        if (
            form.validateAgainstTotal ||
            form.categoryIdToCompare ||
            (form.categoryIdToCompare && form.groupIdToCompare)
        ) {
            percentage = new BudgetPercentageValidation(
                form.validateAgainstTotal,
                form.categoryIdToCompare ?? undefined,
                form.groupIdToCompare ?? undefined,
            );
        }

        return new BudgetValidation(
            id,
            budgetId,
            form.name,
            form.columnId,
            form.columnValidationType,
            form.message,
            form.categoryId,
            form.groupId ?? undefined,
            form.value,
            percentage,
        );
    }

    mapToCreateDto(
        createValidation: CreateBudgetValidation,
    ): CreateBudgetValidationBody {
        let type: Undefinable<BudgetColumnTypeEnumDto> = undefined;
        if (createValidation.type) {
            type = this.budgetColumnTypeEnumMapper.mapToDto(
                createValidation.type,
            );
        }

        return {
            budget: createValidation.budgetId,
            name: createValidation.name,
            column: createValidation.columnId,
            type,
            validation_message: createValidation.message,
            category: createValidation.categoryId,
            group: createValidation.groupId,
            value: createValidation.value,
            is_total_comparison:
                createValidation.percentage?.validateAgainstTotal,
            category_to_compare:
                createValidation.percentage?.categoryIdToCompare,
            group_to_compare: createValidation.percentage?.groupIdToCompare,
        };
    }

    mapToEditDto(validation: BudgetValidation): EditBudgetValidationBody {
        let type: Undefinable<BudgetColumnTypeEnumDto> = undefined;
        if (validation.type) {
            type = this.budgetColumnTypeEnumMapper.mapToDto(validation.type);
        }

        return {
            id: validation.id,
            budget: validation.budgetId,
            name: validation.name,
            column: validation.columnId,
            type,
            validation_message: validation.message,
            category: validation.categoryId,
            group: validation.groupId,
            value: validation.value,
            is_total_comparison: validation.percentage?.validateAgainstTotal,
            category_to_compare: validation.percentage?.categoryIdToCompare,
            group_to_compare: validation.percentage?.groupIdToCompare,
        };
    }
}
