import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { MaterialSubtype } from "@entity/domain/models/material/material-subtype.model";
import { inject, injectable } from "inversify";
import {
    MaterialType,
    MaterialTypeEnum,
} from "../../domain/models/material/material-type.model";
import {
    CreateMaterial,
    Material,
    MaterialSearchFilters,
    Materials,
} from "../../domain/models/material/material.model";
import { MetricUnit } from "../../domain/models/material/metric-unit.model";
import { MaterialDatasource } from "../datasources/material.datasource";
import { MaterialTypeEnumMapper } from "../mappers/material/material-type-enum.mapper";

@injectable()
export class MaterialRepository {
    constructor(
        @inject(MaterialDatasource)
        private readonly materialDatasource: MaterialDatasource,
        @inject(MaterialTypeEnumMapper)
        private readonly materialTypeEnumMapper: MaterialTypeEnumMapper,
    ) {}

    async getAllMaterialTypes(): Promise<
        Either<FallbackError, MaterialType[]>
    > {
        return this.materialDatasource.fetchAllTypes();
    }

    async getAllMaterialSubtypesByTypeId(
        typeId: MaterialTypeEnum,
    ): Promise<Either<FallbackError, MaterialSubtype[]>> {
        const typeIdDto = this.materialTypeEnumMapper.mapToDto(typeId);
        return this.materialDatasource.fetchAllSubtypesByTypeId(typeIdDto);
    }

    async getAllMetricUnits(): Promise<Either<FallbackError, MetricUnit[]>> {
        return this.materialDatasource.fetchAllMetricUnits();
    }

    async searchAllBy(
        pagination: Pagination,
        filters?: MaterialSearchFilters,
    ): Promise<Either<FallbackError, Materials>> {
        const materialsResult = await this.materialDatasource.fetchAllBy(
            pagination,
            filters,
        );

        if (materialsResult.isLeft())
            return Either.Left(materialsResult.getLeftOrThrow());

        return Either.Right(materialsResult.getOrThrow());
    }

    async getMaterial(
        materialId: number,
    ): Promise<Either<FallbackError, Material>> {
        const materialsResult =
            await this.materialDatasource.fetchById(materialId);

        if (materialsResult.isLeft())
            return Either.Left(materialsResult.getLeftOrThrow());

        return Either.Right(materialsResult.getOrThrow());
    }

    async create(
        material: CreateMaterial,
    ): Promise<Either<ValidationError | FallbackError, Material>> {
        return this.materialDatasource.create(material);
    }
    async edit(
        material: Material,
    ): Promise<Either<ValidationError | FallbackError, Material>> {
        return this.materialDatasource.edit(material);
    }
}
