import { IncSkeleton } from "@core/presentacion/component/feedback/skeleton/inc-skeleton.component";
import { IncConent } from "@core/presentacion/component/layout/content/inc-content.component";
import { DashboardLayoutViewModel } from "@core/presentacion/component/layout/dashboard-layout/dashboard-layout.viewmodel";
import { IncFooter } from "@core/presentacion/component/layout/footer/inc-footer.component";
import { IncHeader } from "@core/presentacion/component/layout/header/inc-header.component";
import { IncLayout } from "@core/presentacion/component/layout/layout/inc-layout.component";
import { IncSiderMenu } from "@core/presentacion/component/layout/sider-menu/sider-menu.component";
import { useViewModel } from "@core/presentacion/hook/use-view-model/use-view-model.hook";
import { container } from "@di/inversify.config";
import { Divider, Space, Tag, Typography } from "antd";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

const _DashboardLayout: FC = () => {
    const viewModel = useViewModel(() =>
        container.get(DashboardLayoutViewModel),
    );

    return (
        <IncLayout className={"tw-min-h-screen"}>
            <IncSkeleton loading={viewModel.loading}>
                <IncSiderMenu />
                <IncLayout className={"tw-max-h-screen"}>
                    <IncHeader
                        className={"tw-p-0"}
                        style={{ background: "tw-bg-grey-600" }}
                    />
                    <IncConent
                        className={
                            "tw-px-6 tw-py-4 tw-bg-cyan-dark tw-overflow-y-auto"
                        }
                    >
                        <Suspense fallback={<IncSkeleton loading />}>
                            <Outlet />
                        </Suspense>
                    </IncConent>
                    <IncFooter
                        style={{ height: 48 }}
                        className={"tw-text-center tw-py-4"}
                    >
                        <Space direction={"horizontal"}>
                            <Typography.Text>© 2023 Innicia</Typography.Text>
                            {/* TODO CHANGE TO !== PROD ONCE PRE PROD SETTINGS AVAILABLE */}
                            {process.env.INC_REACT_APP_STAGE !== "dev" &&
                                process.env.INC_REACT_APP_STAGE !== "prod" && (
                                    <>
                                        <Divider type={"vertical"} />
                                        <Typography.Text>
                                            Version:{" "}
                                            <Tag color={"blue"}>
                                                {process.env.GIT_BRANCH}
                                            </Tag>
                                            <Tag color={"green"}>
                                                {process.env.GIT_COMMIT}
                                            </Tag>
                                        </Typography.Text>
                                        <Divider type={"vertical"} />
                                        <Typography.Text>
                                            Build Date:{" "}
                                            <Tag color={"orange"}>
                                                {DateTime.fromISO(
                                                    process.env.BUILD_DATE,
                                                ).toLocaleString(
                                                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                                                )}
                                            </Tag>
                                        </Typography.Text>
                                    </>
                                )}
                        </Space>
                    </IncFooter>
                </IncLayout>
            </IncSkeleton>
        </IncLayout>
    );
};

export const DashboardLayout = observer(_DashboardLayout);
