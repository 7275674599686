import { Nullable } from "@core/domain/types/nullable.type";
import { Undefinable } from "@core/domain/types/undefinable.type";
import { Supplier } from "@entity/domain/models/supplier/supplier.model";
import { DateTime } from "luxon";

export class SupplierSearchFilters {
    search?: Undefinable<string>;
    active?: Nullable<boolean>;
    dateTo?: Undefinable<DateTime>;
    dateFrom?: Undefinable<DateTime>;
}

export class Suppliers {
    constructor(
        public readonly suppliers: Supplier[],
        public readonly count: number,
    ) {}
}
