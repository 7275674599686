import { GenderEnumMapper } from "@beneficiary/data/mappers/gender-enum.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { VolunteerInfoDto } from "@entity/data/dto/employee/volunteer-info.dto";
import { VolunteerInfo } from "@entity/domain/models/employee/volunteer-info.model";
import { VolunteerInfoValuesValidated } from "@entity/presentation/component/employee-form/general/employee-general-tab.view";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";

@injectable()
export class VolunteerInfoMapper {
    constructor(
        @inject(GenderEnumMapper)
        private readonly genderEnumMapper: GenderEnumMapper,
    ) {}
    map(volunteerInfoDto: VolunteerInfoDto): Nullable<VolunteerInfo> {
        const errors = validateSync(volunteerInfoDto);

        if (Boolean(errors.length)) {
            console.error(errors);

            return null;
        }
        const gender = this.genderEnumMapper.map(volunteerInfoDto.gender);
        if (!gender) return null;

        return new VolunteerInfo(
            gender,
            volunteerInfoDto.mobile_phone,
            volunteerInfoDto.email,
            volunteerInfoDto.address,
            volunteerInfoDto.street_number,
            volunteerInfoDto.floor,
            volunteerInfoDto.stair,
            volunteerInfoDto.door,
            volunteerInfoDto.city,
            volunteerInfoDto.postal_code,
        );
    }

    mapToDto(editVolunteerInfo: VolunteerInfo): VolunteerInfoDto {
        const gender = this.genderEnumMapper.mapToDto(editVolunteerInfo.gender);

        const editVolunteerInfoBody: VolunteerInfoDto = {
            gender,
            address: editVolunteerInfo.address,
            street_number: editVolunteerInfo.streetNumber,
            floor: editVolunteerInfo.floor ? editVolunteerInfo.floor : null,
            door: editVolunteerInfo.door ? editVolunteerInfo.door : null,
            stair: editVolunteerInfo.stair ? editVolunteerInfo.stair : null,
            city: editVolunteerInfo.city,
            postal_code: editVolunteerInfo.postalCode,
            mobile_phone: editVolunteerInfo.mobilePhone,
            email: editVolunteerInfo.email ? editVolunteerInfo.email : null,
        };

        return editVolunteerInfoBody;
    }

    mapFromFormValues(
        volunteerInfo: VolunteerInfoValuesValidated,
    ): VolunteerInfo {
        const editVolunteerInfo: VolunteerInfo = new VolunteerInfo(
            volunteerInfo.gender,
            volunteerInfo.mobilePhone,
            volunteerInfo.email,
            volunteerInfo.address,
            volunteerInfo.streetNumber,
            volunteerInfo.floor,
            volunteerInfo.stair,
            volunteerInfo.door,
            volunteerInfo.city,
            volunteerInfo.postalCode,
        );

        return editVolunteerInfo;
    }
}
