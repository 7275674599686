import { IsEnum, IsNotEmpty, IsString } from "class-validator";

export enum DependenceGradeEnumDto {
    DependenceGrade1 = "dependence_grade_1",
    DependenceGrade2 = "dependence_grade_2",
    DependenceGrade3 = "dependence_grade_3",
}

export class DependenceGradeDto {
    @IsEnum(DependenceGradeEnumDto)
    @IsNotEmpty()
    key!: DependenceGradeEnumDto;

    @IsString()
    @IsNotEmpty()
    value!: string;
}
