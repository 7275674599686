import { Nullable } from "@core/domain/types/nullable.type";
import {
    BudgetSpreadsheetColumnDto,
    BudgetSpreadsheetItemDto,
} from "@project/data/dto/budget-spreadsheet.dto";
import { BudgetSpreadsheetValueMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-value.mapper";
import {
    BudgetSpreadsheetItem,
    BudgetSpreadsheetValue,
} from "@project/domain/models/budget-spreadsheet.model";
import { validateSync } from "class-validator";
import { OrderedMap } from "immutable";
import { inject, injectable } from "inversify";

@injectable()
export class BudgetSpreadsheetItemMapper {
    constructor(
        @inject(BudgetSpreadsheetValueMapper)
        private readonly budgetSpreadsheetValueMapper: BudgetSpreadsheetValueMapper,
    ) {}

    map(
        itemDto: BudgetSpreadsheetItemDto,
        columnsDto: BudgetSpreadsheetColumnDto[],
    ): Nullable<BudgetSpreadsheetItem> {
        const errors = validateSync(itemDto);

        if (errors.length > 0) {
            console.error(errors);

            return null;
        }

        const values: OrderedMap<number, BudgetSpreadsheetValue> = OrderedMap(
            itemDto.values
                .mapNotNull((valueDto, valueIndex) =>
                    this.budgetSpreadsheetValueMapper.map(
                        valueDto,
                        columnsDto[valueIndex],
                    ),
                )
                .map((value) => [value.id, value]),
        );

        return new BudgetSpreadsheetItem(itemDto.id, itemDto.name, values);
    }
}
