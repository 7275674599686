import { GlobalEntityRepository } from "@entity/data/repositories/global-entity.repository";
import { inject, injectable } from "inversify";
import {
    GlobalEntity,
    GlobalEntitySearchFilters,
} from "../models/global-entity.model";

@injectable()
export class GetAllGlobalEntitiesUseCase {
    constructor(
        @inject(GlobalEntityRepository)
        private readonly globalEntityRepository: GlobalEntityRepository,
    ) {}

    async execute(
        filters?: GlobalEntitySearchFilters,
    ): Promise<GlobalEntity[]> {
        const entitiesResult =
            await this.globalEntityRepository.getAll(filters);

        return entitiesResult.getOrElse([]);
    }
}
