import { DocumentDto } from "@core/data/dto/document.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { ActionLineEnumDto } from "@entity/data/dto/action-line-enum.dto";
import { CollectiveEnumDto } from "@entity/data/dto/collectives.dto";
import { CatalogueEnumDto } from "@project/data/dto/catalogue.dto";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsNumberString,
    IsOptional,
    IsString,
} from "class-validator";
import { ProjectEmployeeDto } from "./project-employee/project-employees.dto";
import { ProjectFinancialEntityDto } from "./project-financial-entities.dto";
import { StatusEnumDto } from "./status.dto";
import { TerritorialScopeEnumDto } from "./territorial-scope.dto";

export class ProjectDto {
    @IsNumber()
    @IsNotEmpty()
    id!: number;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsNumber()
    @IsNotEmpty()
    year!: number;

    @IsString()
    @IsOptional()
    notes!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    is_active!: Nullable<boolean>;

    @IsString()
    @IsOptional()
    description!: Nullable<string>;

    @IsEnum(TerritorialScopeEnumDto)
    @IsOptional()
    territorial_scope!: Nullable<TerritorialScopeEnumDto>;

    @IsEnum(CatalogueEnumDto, { each: true })
    @IsOptional()
    catalogue!: Nullable<CatalogueEnumDto[]>;

    @IsEnum(StatusEnumDto)
    @IsNotEmpty()
    status!: StatusEnumDto;

    @IsEnum(CollectiveEnumDto, { each: true })
    @IsOptional()
    collectives!: Nullable<CollectiveEnumDto[]>;

    @IsEnum(ActionLineEnumDto, { each: true })
    @IsOptional()
    action_lines!: Nullable<ActionLineEnumDto[]>;

    @IsDateString()
    @IsOptional()
    start_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    end_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    design_date!: Nullable<string>;

    @IsDateString()
    @IsOptional()
    design_resolution_date!: Nullable<string>;

    @IsArray()
    @IsOptional()
    @Type(() => ProjectEmployeeDto)
    employees_data!: Nullable<ProjectEmployeeDto[]>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    documents_data!: Nullable<DocumentDto[]>;

    @IsArray()
    @IsOptional()
    @Type(() => ProjectFinancialEntityDto)
    project_finantial_entity_data!: Nullable<ProjectFinancialEntityDto[]>;

    @IsInt()
    @IsNotEmpty()
    entity!: number;

    @IsNumberString()
    @IsOptional()
    total_cost!: Nullable<string>;
}
