import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { EditEntityBody } from "@entity/data/dto/edit-entity.body";
import { ActionLineEnumMapper } from "@entity/data/mappers/action-line-enum.mapper";
import { CategoryTypeEnumMapper } from "@entity/data/mappers/category-type-enum.mapper";
import { CollectiveEnumMapper } from "@entity/data/mappers/collective-enum.mapper";
import { CrosscuttingScopeEnumMapper } from "@entity/data/mappers/crosscutting-scope-enum.mapper";
import { TypologyEnumMapper } from "@entity/data/mappers/typology-enum.mapper";
import { EditEntity } from "@entity/domain/models/edit-entity.model";
import { EditEntityFormValuesValidated } from "@entity/presentation/component/entity-form/entity-form.component";
import { inject, injectable } from "inversify";

@injectable()
export class EditEntityMapper {
    constructor(
        @inject(CategoryTypeEnumMapper)
        private readonly categoryTypeEnumMapper: CategoryTypeEnumMapper,
        @inject(CollectiveEnumMapper)
        private collectiveEnumMapper: CollectiveEnumMapper,
        @inject(TypologyEnumMapper)
        private typologyEnumMapper: TypologyEnumMapper,
        @inject(ActionLineEnumMapper)
        private actionLineEnumMapper: ActionLineEnumMapper,
        @inject(CrosscuttingScopeEnumMapper)
        private crosscuttingScopeEnumMapper: CrosscuttingScopeEnumMapper,
    ) {}

    mapFromEntityFormValues(
        entityId: number,
        entityForm: EditEntityFormValuesValidated,
    ): EditEntity {
        const entity = new EditEntity(
            entityId,
            entityForm.general.name,
            entityForm.general.typologyId,
            entityForm.general.categoryId,
            entityForm.general.collectiveId,
            entityForm.general.actionLines,
            entityForm.general.secondaryCollectives,
            entityForm.general.crosscuttingScopes,
        );

        entity.actionLinesDescription = convertEmptyToUndefined(
            entityForm.general.actionLinesDescription,
        );
        entity.cnaeCode = convertEmptyToUndefined(entityForm.general.cnaeCode);
        entity.entityPurpose = convertEmptyToUndefined(
            entityForm.general.entityPurpose,
        );
        entity.identificationDocument = convertEmptyToUndefined(
            entityForm.general.identificationDocument,
        );
        entity.legalConstitutionDate = convertEmptyToUndefined(
            entityForm.general.legalConstitutionDate,
        );
        entity.otherActionLine = convertEmptyToUndefined(
            entityForm.general.otherActionLine,
        );
        entity.otherCollective = convertEmptyToUndefined(
            entityForm.general.otherCollective,
        );
        entity.otherCrosscuttingScope = convertEmptyToUndefined(
            entityForm.general.otherCrosscuttingScope,
        );
        entity.otherTypology = convertEmptyToUndefined(
            entityForm.general.otherTypology,
        );
        entity.webAddress = convertEmptyToUndefined(
            entityForm.general.webAddress,
        );
        entity.equalityPlanExpirationDate = convertEmptyToUndefined(
            entityForm.general.equalityPlanExpirationDate,
        );

        entity.address = entityForm.address;
        entity.documents = entityForm.documents.documents;
        entity.publicInterest = entityForm.general.publicInterest;
        entity.equalityPlan = entityForm.general.equalityPlan;

        return entity;
    }

    mapToDto(editEntity: EditEntity): EditEntityBody {
        const editEntityBody: EditEntityBody = {
            id: editEntity.id,
            company_name: editEntity.name,
        };

        if (editEntity.collectiveId) {
            editEntityBody.priority_collective =
                this.collectiveEnumMapper.mapToDto(editEntity.collectiveId);
        }

        if (editEntity.typologyId) {
            editEntityBody.legal_typology = this.typologyEnumMapper.mapToDto(
                editEntity.typologyId,
            );
        }

        if (editEntity.categoryId) {
            editEntityBody.category = this.categoryTypeEnumMapper.mapToDto(
                editEntity.categoryId,
            );
        }

        if (editEntity.actionLines) {
            editEntityBody.action_lines = editEntity.actionLines.map(
                (actionLine) => this.actionLineEnumMapper.mapToDto(actionLine),
            );
        }

        if (editEntity.secondaryCollectives) {
            editEntityBody.secondary_collectives =
                editEntity.secondaryCollectives.map((secondaryCollective) =>
                    this.collectiveEnumMapper.mapToDto(secondaryCollective),
                );
        }

        if (editEntity.crosscuttingScopes) {
            editEntityBody.crosscutting_entity_scopes =
                editEntity.crosscuttingScopes.map((crosscuttingScope) =>
                    this.crosscuttingScopeEnumMapper.mapToDto(
                        crosscuttingScope,
                    ),
                );
        }

        editEntityBody.entity_purpose = convertEmptyToUndefined(
            editEntity.entityPurpose,
        );
        editEntityBody.legal_constitution_date = convertEmptyToUndefined(
            editEntity.legalConstitutionDate?.toISODate(),
        );
        editEntityBody.web_address = convertEmptyToUndefined(
            editEntity.webAddress,
        );
        editEntityBody.legal_constitution_date = convertEmptyToUndefined(
            editEntity.legalConstitutionDate?.toISODate(),
        );
        editEntityBody.other_legal_typology = convertEmptyToUndefined(
            editEntity.otherTypology,
        );
        editEntityBody.other_collectives = convertEmptyToUndefined(
            editEntity.otherCollective,
        );
        editEntityBody.identification = convertEmptyToUndefined(
            editEntity.identificationDocument,
        );
        editEntityBody.cnae_code = convertEmptyToUndefined(editEntity.cnaeCode);
        editEntityBody.other_action_line = convertEmptyToUndefined(
            editEntity.otherActionLine,
        );
        editEntityBody.action_lines_description = convertEmptyToUndefined(
            editEntity.actionLinesDescription,
        );
        editEntityBody.other_crosscutting_entity_scope =
            convertEmptyToUndefined(editEntity.otherCrosscuttingScope);

        editEntityBody.public_interest = editEntity.publicInterest;
        editEntityBody.equality_plan = editEntity.equalityPlan;

        editEntityBody.equality_plan_expiration_date = convertEmptyToUndefined(
            editEntity.equalityPlanExpirationDate?.toISODate(),
        );

        if (editEntity.documents) {
            const documents = editEntity.documents.map(
                (document) => document.id,
            );
            editEntityBody.documents = convertEmptyToUndefined(documents);
        }

        return editEntityBody;
    }
}
