import { Undefinable } from "@core/domain/types/undefinable.type";
import { BudgetColumnTypeEnum } from "@project/domain/models/budget/budget-column/budget-column-type.model";

export enum BudgetTypeEnum {
    CAM = "CAM",
    IRPF = "IRPF",
    GENERAL = "GENERAL",
}

export class BudgetType {
    constructor(
        public readonly id: BudgetTypeEnum,
        public readonly label: string,
    ) {}
}

export class BudgetPercentageValidation {
    constructor(
        public readonly validateAgainstTotal: boolean,
        public readonly categoryIdToCompare: Undefinable<number>,
        public readonly groupIdToCompare: Undefinable<number>,
    ) {}
}

export class CreateBudgetValidation {
    constructor(
        public readonly budgetId: number,
        public name: string,
        public columnId: number,
        public type: Undefinable<BudgetColumnTypeEnum>,
        public message: string,
        public categoryId: number,
        public groupId: Undefinable<number>,
        public value: string,
        public percentage?: BudgetPercentageValidation,
    ) {}
}

export class BudgetValidation {
    constructor(
        public readonly id: number,
        public readonly budgetId: number,
        public name: string,
        public columnId: number,
        public type: Undefinable<BudgetColumnTypeEnum>,
        public message: string,
        public categoryId: number,
        public groupId: Undefinable<number>,
        public value: string,
        public percentage?: Undefinable<BudgetPercentageValidation>,
    ) {}
}

export class BudgetColumn {
    constructor(
        public readonly id: number,
        public name: string,
        public budget: number,
        public type: BudgetColumnTypeEnum,
    ) {}
}

export class BudgetCategoryConcept {
    constructor(
        public readonly id: number,
        public name: string,
        public category: number,
        public group: Undefinable<number>,
    ) {}
}

export class BudgetCategoryGroup {
    constructor(
        public readonly id: number,
        public name: string,
        public category: number,
    ) {}
}

export class BudgetCategory {
    constructor(
        public readonly id: number,
        public name: string,
        public budget: number,
        public groups: BudgetCategoryGroup[] = [],
    ) {}
}

export class FinancialEntityBudget {
    constructor(
        public readonly id: number,
        public name: string,
        public description: string,
        public financialEntityId: number,
        public columns: BudgetColumn[] = [],
        public categories: BudgetCategory[] = [],
    ) {}
}

export class FinancialEntityBudgets {
    constructor(
        public readonly financialEntityBudgets: FinancialEntityBudget[],
        public count: number,
    ) {}
}

export class BudgetColumns {
    constructor(
        public readonly budgetColumns: BudgetColumn[],
        public count: number,
    ) {}
}

export class BudgetCategories {
    constructor(
        public readonly budgetCategories: BudgetCategory[],
        public count: number,
    ) {}
}

export class BudgetCategoryGroups {
    constructor(
        public readonly budgetCategoryGroups: BudgetCategoryGroup[],
        public count: number,
    ) {}
}

export class BudgetCategoryConcepts {
    constructor(
        public readonly budgetCategoryConcepts: BudgetCategoryConcept[],
        public count: number,
    ) {}
}

export class BudgetValidations {
    constructor(
        public readonly budgetValidations: BudgetValidation[],
        public count: number,
    ) {}
}
