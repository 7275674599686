import { BeneficiarySummary } from "@beneficiary/domain/models/beneficiary-summary.model";
import { GetAllBeneficiariesUseCase } from "@beneficiary/domain/usecases/get-all-beneficiaries.usecase";
import { CreateDocument } from "@core/domain/models/create-document.model";
import { IncDocument } from "@core/domain/models/inc-document.model";
import { Nullable } from "@core/domain/types/nullable.type";
import { CreateDocumentUseCase } from "@core/domain/usecases/create-document.usecase";
import { DownloadDocumentUseCase } from "@core/domain/usecases/download-document.usecase";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { Employee } from "@entity/domain/models/employee/employee.model";
import { GetAllEmployeesByUseCase } from "@entity/domain/usecases/employee/get-all-employees-by.usecase";
import { Status } from "@proceeding/domain/models/status.model";
import { GetAllStatusUseCase } from "@proceeding/domain/usecases/get-all-status.usecase";
import { GlobalActivity } from "@project/domain/models/global-activity.model";
import { GlobalProject } from "@project/domain/models/global-project.model";
import { GetAllGlobalActivitiesByUseCase } from "@project/domain/usecases/get-all-global-activities-by.usecase";
import { GetAllGlobalProjectsByUseCase } from "@project/domain/usecases/get-all-global-projects-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    flow,
    flowResult,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

@injectable()
export class GeneralTabViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    activitiesLoading: boolean = false;

    @observable
    beneficiaries: BeneficiarySummary[] = [];

    @observable
    projects: GlobalProject[] = [];

    @observable
    activities: GlobalActivity[] = [];

    @observable
    employees: Employee[] = [];

    @observable
    statuses: Status[] = [];

    @observable
    derivedFromModalOpen: boolean = false;

    constructor(
        @inject(CreateDocumentUseCase)
        private readonly createDocumentUseCase: CreateDocumentUseCase,
        @inject(DownloadDocumentUseCase)
        private readonly downloadDocumentUseCase: DownloadDocumentUseCase,
        @inject(GetAllBeneficiariesUseCase)
        private readonly getAllBeneficiariesUseCase: GetAllBeneficiariesUseCase,
        @inject(GetAllGlobalProjectsByUseCase)
        private readonly getAllGlobalProjectsByUseCase: GetAllGlobalProjectsByUseCase,
        @inject(GetAllEmployeesByUseCase)
        private readonly getAllEmployeesByUseCase: GetAllEmployeesByUseCase,
        @inject(GetAllStatusUseCase)
        private readonly getAllStatusesUseCase: GetAllStatusUseCase,
        @inject(GetAllGlobalActivitiesByUseCase)
        private readonly getAllGlobalActivitiesByUseCase: GetAllGlobalActivitiesByUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    @flow
    async *initViewData(): AsyncGenerator<void> {
        yield flowResult(
            Promise.all([
                this.getAllBeneficiaries(),
                this.getAllProjects(),
                this.getAllEmployees(),
                this.getAllProceedingStatuses(),
            ]),
        );

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @flow
    async *getAllProceedingStatuses(): AsyncGenerator<void> {
        const statuses = await this.getAllStatusesUseCase.execute();

        runInAction(() => {
            this.statuses = statuses;
        });
    }

    @flow
    async *getAllBeneficiaries(): AsyncGenerator<void> {
        const beneficiaries = await this.getAllBeneficiariesUseCase.execute();

        runInAction(() => {
            this.beneficiaries = beneficiaries;
        });
    }

    @flow
    async *getAllProjects(): AsyncGenerator<void> {
        const projects = await this.getAllGlobalProjectsByUseCase.execute({
            isActive: true,
        });

        runInAction(() => {
            this.projects = projects;
        });
    }

    @flow
    async *getAllActivitiesInProject(projectId: number): AsyncGenerator<void> {
        this.activitiesLoading = true;
        const activities = await this.getAllGlobalActivitiesByUseCase.execute({
            projectId,
        });

        runInAction(() => {
            this.activities = activities;
            this.activitiesLoading = false;
        });
    }

    @flow
    async *getAllEmployees(): AsyncGenerator<void> {
        const employees = await this.getAllEmployeesByUseCase.execute();

        runInAction(() => {
            this.employees = employees;
        });
    }

    @action
    clearActivities(): void {
        this.activities = [];
    }

    async uploadDocument(
        createDocument: CreateDocument,
    ): Promise<Nullable<IncDocument>> {
        LoadLayoutStore.start();

        const document =
            await this.createDocumentUseCase.execute(createDocument);

        if (document) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return document;
    }

    async downloadDocument(documentUrl: string): Promise<void> {
        LoadLayoutStore.start();

        await this.downloadDocumentUseCase.execute(documentUrl);

        LoadLayoutStore.finish();
    }

    @action
    setDerivedFromModalOpen(open: boolean): void {
        this.derivedFromModalOpen = open;
    }
}
