import { DiModuleBuilder } from "@di/builder/di-module-builder";
import { BudgetDataSource } from "@project/data/datasource/budget.datasource";
import { FinancialEntityDatasource } from "@project/data/datasource/financial-entity.datasource";
import { GlobalProjectDatasource } from "@project/data/datasource/global-project.datasource";
import { ProjectEmployeeDedicationDatasource } from "@project/data/datasource/project-employee-dedication.datasource";
import { ProjectEmployeeDatasource } from "@project/data/datasource/project-employee.datasource";
import { ProjectFinancialEntityDataSource } from "@project/data/datasource/project-financial-entity.datasource";
import { ProjectMaterialDatasource } from "@project/data/datasource/project-material.datasource";
import { TechnicalProposalDatasource } from "@project/data/datasource/technical-proposal.datasource";
import { BudgetSummaryMapper } from "@project/data/mappers/budget-summary.mapper";
import { BudgetCategoriesMapper } from "@project/data/mappers/budget/categories/budget-categories.mapper";
import { BudgetCategoryMapper } from "@project/data/mappers/budget/categories/budget-category.mapper";
import { BudgetCategoryConceptMapper } from "@project/data/mappers/budget/categories/concepts/budget-category-concept.mapper";
import { BudgetCategoryConceptsMapper } from "@project/data/mappers/budget/categories/concepts/budget-category-concepts.mapper";
import { CreateBudgetCategoryConceptMapper } from "@project/data/mappers/budget/categories/concepts/create-budget-category-concept.mapper";
import { CreateBudgetCategoryMapper } from "@project/data/mappers/budget/categories/create-budget-category.mapper";
import { BudgetCategoryGroupMapper } from "@project/data/mappers/budget/categories/groups/budget-category-group.mapper";
import { BudgetCategoryGroupsMapper } from "@project/data/mappers/budget/categories/groups/budget-category-groups.mapper";
import { CreateBudgetCategoryGroupMapper } from "@project/data/mappers/budget/categories/groups/create-budget-category-group.mapper";
import { BudgetColumnTypeEnumMapper } from "@project/data/mappers/budget/columns/budget-column-type-enum.mapper";
import { BudgetColumnTypeMapper } from "@project/data/mappers/budget/columns/budget-column-type.mapper";
import { BudgetColumnMapper } from "@project/data/mappers/budget/columns/budget-column.mapper";
import { BudgetColumnsMapper } from "@project/data/mappers/budget/columns/budget-columns.mapper";
import { CreateBudgetColumnMapper } from "@project/data/mappers/budget/columns/create-budget-column.mapper";
import { CreateFinancialEntityBudgetMapper } from "@project/data/mappers/budget/create-financial-entity-budget.mapper";
import { EditFinancialEntityBudgetMapper } from "@project/data/mappers/budget/edit-financial-entity-budget.mapper";
import { FinancialEntityBudgetMapper } from "@project/data/mappers/budget/financial-entity-budget.mapper";
import { FinancialEntityBudgetsMapper } from "@project/data/mappers/budget/financial-entity-budgets.mapper";
import { BudgetValidationMapper } from "@project/data/mappers/budget/validations/budget-validation.mapper";
import { BudgetValidationsMapper } from "@project/data/mappers/budget/validations/budget-validations.mapper";
import { BudgetsSummaryMapper } from "@project/data/mappers/budgets-summary.mapper";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { CatalogueMapper } from "@project/data/mappers/catalogue.mapper";
import { CreateProjectMapper } from "@project/data/mappers/create-project.mapper";
import { EditProjectMapper } from "@project/data/mappers/edit-project.mapper";
import { FinancialEntitiesMapper } from "@project/data/mappers/financial-entity/financial-entities.mapper";
import { FinancialEntityMapper } from "@project/data/mappers/financial-entity/financial-entity-mapper";
import { FinancialEntityTypeEnumMapper } from "@project/data/mappers/financial-entity/financial-entity-type-enum.mapper";
import { FinancialEntityTypeMapper } from "@project/data/mappers/financial-entity/financial-entity-type.mapper";
import { CreateProjectMaterialMapper } from "@project/data/mappers/materials/create-project-material.mapper";
import { ProjectMaterialMapper } from "@project/data/mappers/materials/project-material.mapper";
import { BudgetSpreadsheetCategoryMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-category.mapper";
import { BudgetSpreadsheetColumnMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-column.mapper";
import { BudgetSpreadsheetGroupMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-group.mapper";
import { BudgetSpreadsheetItemMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-item.mapper";
import { BudgetSpreadsheetMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-mapper";
import { BudgetSpreadsheetValidationTypeEnumMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-validation-type-enum.mapper";
import { BudgetSpreadsheetValidationMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-validation.mapper";
import { BudgetSpreadsheetValueMapper } from "@project/data/mappers/project-financial-entity/budget-spreadsheet/budget-spreadsheet-value.mapper";
import { ProjectSummaryMapper } from "@project/data/mappers/project-summary.mapper";
import { ProjectsSummaryMapper } from "@project/data/mappers/projects-summary.mapper";
import { StatusEnumMapper } from "@project/data/mappers/status-enum.mapper";
import { StatusMapper } from "@project/data/mappers/status.mapper";
import { ActivityMapper } from "@project/data/mappers/technical-proposal/activity.mapper";
import { IndicatorMapper } from "@project/data/mappers/technical-proposal/indicator.mapper";
import { ObjectiveMapper } from "@project/data/mappers/technical-proposal/objective.mapper";
import { TechnicalProposalMapper } from "@project/data/mappers/technical-proposal/technical-proposal.mapper";
import { TechnicalProposalsMapper } from "@project/data/mappers/technical-proposal/technical-proposals.mapper";
import { BudgetRepository } from "@project/data/repositories/budget.repository";
import { FinancialEntityRepository } from "@project/data/repositories/financial-entity.repository";
import { GlobalProjectRepository } from "@project/data/repositories/global-project.repository";
import { ProjectEmployeeDedicationRepository } from "@project/data/repositories/project-employee-dedication.repository";
import { ProjectEmployeeRepository } from "@project/data/repositories/project-employee.repository";
import { ProjectFinancialEntityRepository } from "@project/data/repositories/project-financial-entity.repository";
import { ProjectMaterialRepository } from "@project/data/repositories/project-material.repository";
import { TechnicalProposalRepository } from "@project/data/repositories/technical-proposal/technical-proposal.repository";
import { CreateBudgetCategoryConceptUseCase } from "@project/domain/usecases/budget/categories/concepts/create-category-concept.usecase";
import { DeleteBudgetCategoryConceptUseCase } from "@project/domain/usecases/budget/categories/concepts/delete-category-concept.usecase";
import { EditCategoryConceptUseCase } from "@project/domain/usecases/budget/categories/concepts/edit-category-concept.usecase";
import { GetAllCategoryConceptsByCatIdUseCase } from "@project/domain/usecases/budget/categories/concepts/get-all-category-concepts-by-category-id.usecase";
import { CreateBudgetCategoryUseCase } from "@project/domain/usecases/budget/categories/create-budget-category.usecase";
import { DeleteBudgetCategoryUseCase } from "@project/domain/usecases/budget/categories/delete-budget-category.usecase";
import { EditBudgetCategoryUseCase } from "@project/domain/usecases/budget/categories/edit-budget-category.usecase";
import { GetAllBudgetCategoriesByBudgetIdUseCase } from "@project/domain/usecases/budget/categories/get-all-budget-categories-by-budget-id.usecase";
import { CreateBudgetCategoryGroupUseCase } from "@project/domain/usecases/budget/categories/groups/create-category-group.usecase";
import { DeleteBudgetCategoryGroupUseCase } from "@project/domain/usecases/budget/categories/groups/delete-category-group.usecase";
import { EditCategoryGroupUseCase } from "@project/domain/usecases/budget/categories/groups/edit-category-group.usecase";
import { GetAllCategoryGroupsByCategoryIdUseCase } from "@project/domain/usecases/budget/categories/groups/get-all-category-groups-by-category-id.usecase";
import { CloneBudgetUseCase } from "@project/domain/usecases/budget/clone-budget.usecase";
import { CreateBudgetColumnUseCase } from "@project/domain/usecases/budget/columns/create-budget-column.usecase";
import { DeleteBudgetColumnUseCase } from "@project/domain/usecases/budget/columns/delete-budget-column.usecase";
import { EditBudgetColumnUseCase } from "@project/domain/usecases/budget/columns/edit-budget-column.usecase";
import { GetAllBudgetColumnTypesUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-column-types.usecase";
import { GetAllBudgetColumnsByBudgetIdUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-columns-by-budget-id.usecase";
import { CreateBudgetUseCase } from "@project/domain/usecases/budget/create-budget-usecase";
import { DeleteBudgetUseCase } from "@project/domain/usecases/budget/delete-budget.usecase";
import { EditBudgetUseCase } from "@project/domain/usecases/budget/edit-budget.usecase";
import { GetBudgetByIdUseCase } from "@project/domain/usecases/budget/get-budget-by-id.usecase";
import { CreateBudgetValidationUseCase } from "@project/domain/usecases/budget/validations/create-budget-validation.usecase";
import { DeleteBudgetValidationUseCase } from "@project/domain/usecases/budget/validations/delete-budget-validation.usecase";
import { EditBudgetValidationUseCase } from "@project/domain/usecases/budget/validations/edit-budget-validation.usecase";
import { GetAllValidationsByBudgetIdUseCase } from "@project/domain/usecases/budget/validations/get-all-validations-by-budget-id.usecase";
import { CreateProjectUseCase } from "@project/domain/usecases/create-project.usecase";
import { EditProjectUseCase } from "@project/domain/usecases/edit-project.usecase";
import { ExportProjectsUseCase } from "@project/domain/usecases/export-projects.usecase";
import { GetAllFinancialEntitiesUseCase } from "@project/domain/usecases/financial-entity/get-all-financial-entities.usecase";
import { GetAllFinancialEntityTypesUsecase } from "@project/domain/usecases/financial-entity/get-all-financial-entity-types.usecase";
import { GetAllProjectFinancialEntitiesUseCase } from "@project/domain/usecases/financial-entity/get-all-project-financial-entities.usecase";
import { SearchFinancialEntitiesByUseCase } from "@project/domain/usecases/financial-entity/search-financial-entities-by.usecase";
import { GetAllCataloguesUseCase } from "@project/domain/usecases/get-all-catalogues.usecase";
import { GetAllMaterialsUseCase } from "@project/domain/usecases/get-all-materials.usecase";
import { GetAllProjectsUseCase } from "@project/domain/usecases/get-all-projects.usecase";
import { GetAllStatusUseCase } from "@project/domain/usecases/get-all-status.usecase";
import { GetBudgetSpreadsheetByIdUseCase } from "@project/domain/usecases/get-budget-spreadsheet-by-id.usecase";
import { GetProjectByIdUseCase } from "@project/domain/usecases/get-project-by-id.usecase";
import { ImportProjectsUseCase } from "@project/domain/usecases/import-projects.usecase";
import { CreateProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/create-project-employee-dedication.usecase";
import { DeleteProjectEmployeeDedicationUseCase } from "@project/domain/usecases/project-employee-dedication/delete-project-employee-dedication.usecase";
import { GetAllProjectEmployeeDedicationsByUseCase } from "@project/domain/usecases/project-employee-dedication/get-all-project-employee-dedications-by.usecase";
import { GetProjectEmployeeDedicationByIdUseCase } from "@project/domain/usecases/project-employee-dedication/get-project-employee-dedication-by-id.usecase";
import { GetProjectEmployeeDedicationsByUseCase } from "@project/domain/usecases/project-employee-dedication/get-project-employee-dedications-by.usecase";
import { UpdateProjectEmployeeDedicationUsecase } from "@project/domain/usecases/project-employee-dedication/update-project-employee-dedication.usecase";
import { CreateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/create-project-employee.usecase";
import { DeleteProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/delete-project-employee.usecase";
import { GetAllProjectEmployeesByUseCase } from "@project/domain/usecases/project-employee/get-all-project-employees-by.usecase";
import { GetProjectEmployeeByIdUseCase } from "@project/domain/usecases/project-employee/get-project-employee-by-id.usecase";
import { GetProjectEmployeesByUseCase } from "@project/domain/usecases/project-employee/get-project-employees-by.usecase";
import { UpdateProjectEmployeeUseCase } from "@project/domain/usecases/project-employee/update-project-employee.usecase";
import { CreateProjectMaterialUseCase } from "@project/domain/usecases/project-material/create-project-material.usecase";
import { DeleteProjectMaterialUseCase } from "@project/domain/usecases/project-material/delete-project-material.usecase";
import { EditProjectMaterialUseCase } from "@project/domain/usecases/project-material/edit-project-material.usecase";
import { GetAllProjectMaterialsUseCase } from "@project/domain/usecases/project-material/get-all-project-materials.usecase";
import { SearchProjectsByUseCase } from "@project/domain/usecases/search-projects-by.usecase";
import { ExportTechnicalProposalUseCase } from "@project/domain/usecases/technical-proposal/export-technical-proposal.usecase";
import { GetAllTechnicalProposalsByUseCase } from "@project/domain/usecases/technical-proposal/get-all-technical-proposal-by.usecase";
import { GetObjectiveByIdUseCase } from "@project/domain/usecases/technical-proposal/objective/get-objective-by-id.usecase";
import { UpdateBudgetSpreadsheetUseCase } from "@project/domain/usecases/update-budget-spreadsheet.usecase";
import { BudgetCategoriesViewModel } from "@project/presentation/components/budget-form/budget-category/budget-categories.viewmodel";
import { BudgetCategoryConceptsViewModel } from "@project/presentation/components/budget-form/budget-category/budget-category-concept/budget-category-concepts.viewmodel";
import { BudgetCategoryGroupsViewModel } from "@project/presentation/components/budget-form/budget-category/budget-category-group/budget-category-groups.viewmodel";
import { BudgetColumnViewModel } from "@project/presentation/components/budget-form/budget-column/budget-column.viewmodel";
import { BudgetValidationFormViewModel } from "@project/presentation/components/budget-form/budget-validations/budget-validation-form.viewmodel";
import { BudgetValidationsViewModel } from "@project/presentation/components/budget-form/budget-validations/budget-validation.viewmodel";
import { FinancialEntityFormViewmodel } from "@project/presentation/components/financial-entity-form/financial-entity-form.viewmodel";
import { MaterialFormViewModel } from "@project/presentation/components/material-resources/form/material-form.viewmodel";
import { MaterialResourcesViewModel } from "@project/presentation/components/material-resources/material-resources-tab.viewmodel";
import { ProjectFinancialEntityFormViewModel } from "@project/presentation/components/project-form/budget/project-financial-entity-form.viewmodel";
import { AddEmployeeModalFormViewModel } from "@project/presentation/components/project-form/employees/add-employee-modal-form.viewmodel";
import { EmployeeDetailViewModel } from "@project/presentation/components/project-form/employees/employee-detail.viewmodel";
import { EmployeesTabViewModel } from "@project/presentation/components/project-form/employees/employees-tab.viewmodel";
import { GeneralTabViewModel } from "@project/presentation/components/project-form/general/general-tab.viewmodel";
import { KanbanActivityViewModel } from "@project/presentation/components/project-form/kanban/components/kanban-activity.viewmodel";
import { TechnicalProposalTabViewModel } from "@project/presentation/components/project-form/technical-proposal/technical-proposal-tab.viewmodel";
import { EditBudgetPageViewModel } from "@project/presentation/pages/edit-budget/edit-budget-page.viewmodel";
import { CreateFinancialEntityBudgetPageViewModel } from "@project/presentation/pages/financial-entity/budget/create/create-budget-page.viewmodel";
import { EditFinancialEntityBudgetPageViewModel } from "@project/presentation/pages/financial-entity/budget/edit/edit-budget-page.viewmodel";
import { FinancialEntityListPageViewModel } from "@project/presentation/pages/financial-entity/list/financial-entity-list-page.viewmodel";
import { CreateProjectPageViewModel } from "@project/presentation/pages/project/create/create-project-page.viewmodel";
import { DetailProjectPageViewModel } from "@project/presentation/pages/project/detail/detail-project.viewmodel";
import { EditProjectPageViewModel } from "@project/presentation/pages/project/edit/edit-project-page.viewmodel";
import { ProjectListPageViewModel } from "@project/presentation/pages/project/list/project-list-page-viewmodel";
import { ContainerModule } from "inversify";
import { ProjectActivityTaskDataSource } from "./data/datasource/project-activity-task.datasource";
import { ProjectActivityDataSource } from "./data/datasource/project-activity.datasource";
import { ProjectExpenseDatasource } from "./data/datasource/project-expense.datasource";
import { ProjectDatasource } from "./data/datasource/project.datasource";
import { IndicatorDataSource } from "./data/datasource/technical-proposal/indicator.datasource";
import { ObjectiveDataSource } from "./data/datasource/technical-proposal/objective.datasource";
import { ActivitiesSummaryMapper } from "./data/mappers/activities-summary.mapper";
import { ActivityStatusEnumMapper } from "./data/mappers/activity-status-enum.mapper";
import { ActivitySummaryMapper } from "./data/mappers/activity-summary.mapper";
import { ActivityTaskMapper } from "./data/mappers/activity-task.mapper";
import { AccommodationExpenseMapper } from "./data/mappers/expenses/accommodation-expense.mapper";
import { ContractTypeEnumMapper } from "./data/mappers/expenses/contract-type-enum.mapper";
import { ContractTypeMapper } from "./data/mappers/expenses/contract-type.mapper";
import { ContributionGroupTypeEnumMapper } from "./data/mappers/expenses/contribution-group-type-enum.mapper";
import { ContributionGroupTypeMapper } from "./data/mappers/expenses/contribution-group-type.mapper";
import { CreateExpenseMapper } from "./data/mappers/expenses/create-expense.mapper";
import { DietTypeEnumMapper } from "./data/mappers/expenses/diet-type-enum.mapper";
import { DietTypeMapper } from "./data/mappers/expenses/diet-type.mapper";
import { EditExpenseMapper } from "./data/mappers/expenses/edit-expense.mapper";
import { ExpenseSubTypeEnumMapper } from "./data/mappers/expenses/expense-sub-type-enum.mapper";
import { ExpenseSubTypeMapper } from "./data/mappers/expenses/expense-sub-type.mapper";
import { ExpenseTypeEnumMapper } from "./data/mappers/expenses/expense-type-enum.mapper";
import { ExpenseTypeMapper } from "./data/mappers/expenses/expense-type.mapper";
import { ExpenseMapper } from "./data/mappers/expenses/expense.mapper";
import { ExpensesMapper } from "./data/mappers/expenses/expenses.mapper";
import { InvestmentExpenseMapper } from "./data/mappers/expenses/investment-expense.mapper";
import { MaintenanceExpenseMapper } from "./data/mappers/expenses/maintenance-expense.mapper";
import { ManagementExpenseMapper } from "./data/mappers/expenses/management-expense.mapper";
import { MonthTypeEnumMapper } from "./data/mappers/expenses/month-type-enum.mapper";
import { MonthTypeMapper } from "./data/mappers/expenses/month-type.mapper";
import { PaymentMethodTypeEnumMapper } from "./data/mappers/expenses/payment-method-type-enum.mapper";
import { PaymentMethodTypeMapper } from "./data/mappers/expenses/payment-method-type.mapper";
import { PersonnelExpenseMapper } from "./data/mappers/expenses/personnel-expense.mapper";
import { TransportTypeEnumMapper } from "./data/mappers/expenses/transport-type-enum.mapper";
import { TransportTypeMapper } from "./data/mappers/expenses/transport-type.mapper";
import { TravelExpenseMapper } from "./data/mappers/expenses/travel-expense.mapper";
import { BudgetTypeEnumMapper } from "./data/mappers/financial-entity/budget-type-enum.mapper";
import { BudgetTypeMapper } from "./data/mappers/financial-entity/budget-type-mapper";
import { CreateFinancialEntityMapper } from "./data/mappers/financial-entity/create-financial-entity.mapper";
import { EditFinancialEntityMapper } from "./data/mappers/financial-entity/edit-financial-entity.mapper";
import { FinancingTypeEnumMapper } from "./data/mappers/financial-entity/financing-type-enum.mapper";
import { FinancingTypeMapper } from "./data/mappers/financial-entity/financing-type-mapper";
import { GlobalProjectMapper } from "./data/mappers/global-project.mapper";
import { EditProjectMaterialMapper } from "./data/mappers/materials/edit-project-material.mapper";
import { ProjectEmployeeDedicationMapper } from "./data/mappers/project-employee-dedication.mapper";
import { ProjectEmployeeDedicationsMapper } from "./data/mappers/project-employee-dedications.mapper";
import { ProjectEmployeeMapper } from "./data/mappers/project-employee.mapper";
import { ProjectEmployeesMapper } from "./data/mappers/project-employees.mapper";
import { ProjectFinancialEntitiesMapper } from "./data/mappers/project-financial-entities.mapper";
import { ProjectFinancialEntityMapper } from "./data/mappers/project-financial-entity.mapper";
import { ProjectMapper } from "./data/mappers/project.mapper";
import { TerritorialScopeEnumMapper } from "./data/mappers/territorial-scope-enum.mapper";
import { TerritorialScopeMapper } from "./data/mappers/territorial-scope.mapper";
import { ProjectActivityTaskRepository } from "./data/repositories/project-activity-task.repository";
import { ProjectExpenseRepository } from "./data/repositories/project-expense.repository";
import { ProjectRepository } from "./data/repositories/project.repository";
import { ProjectActivityRepository } from "./data/repositories/technical-proposal/activity.repository";
import { IndicatorRepository } from "./data/repositories/technical-proposal/indicator.repository";
import { ObjectiveRepository } from "./data/repositories/technical-proposal/objective.repository";
import { CreateExpenseUseCase } from "./domain/usecases/expenses/create-expense.usecase";
import { DeleteExpenseUseCase } from "./domain/usecases/expenses/delete-expense.usecase";
import { EditExpenseUseCase } from "./domain/usecases/expenses/edit-expense.usecase";
import { GetAllContractTypesUseCase } from "./domain/usecases/expenses/get-all-contract-types.usecase";
import { GetAllContributionGroupTypesUseCase } from "./domain/usecases/expenses/get-all-contribution-group-types.usecase";
import { GetAllDietTypesUseCase } from "./domain/usecases/expenses/get-all-diet-types.usecase";
import { GetAllProjectExpenseSubTypesUseCase } from "./domain/usecases/expenses/get-all-expense-sub-types.usecase";
import { GetAllExpensesUseCase } from "./domain/usecases/expenses/get-all-expenses.usecase";
import { GetAllMonthTypesUseCase } from "./domain/usecases/expenses/get-all-month-types.usecase";
import { GetAllPaymentMethodTypesUseCase } from "./domain/usecases/expenses/get-all-payment-method-types.usecase";
import { GetAllProjectExpenseTypesUseCase } from "./domain/usecases/expenses/get-all-project-expense-types.usecase";
import { GetAllTransportTypesUseCase } from "./domain/usecases/expenses/get-all-transport-types.usecase";
import { ExportFinancialEntityUseCase } from "./domain/usecases/export-financial-entity.usecase";
import { ExportJustificationUseCase } from "./domain/usecases/export-justification.usecase";
import { CreateBudgetFromTemplateUseCase } from "./domain/usecases/financial-entity/create-budget-from-template.usecase";
import { CreateFinancialEntityUseCase } from "./domain/usecases/financial-entity/create-financial-entity.usecase";
import { EditFinancialEntityUseCase } from "./domain/usecases/financial-entity/edit-financial-entity.usecase";
import { GetAllBudgetTypesUseCase } from "./domain/usecases/financial-entity/get-all-budget-types.usecase";
import { GetAllFinancingTypesUsecase } from "./domain/usecases/financial-entity/get-all-financing-types.usecase";
import { GetFinancialEntityByIdUseCase } from "./domain/usecases/financial-entity/get-financial-entity-by-id.usecase";
import { GetAllBudgetsUseCase } from "./domain/usecases/get-all-budgets.usecase";
import { GetAllGlobalProjectsByUseCase } from "./domain/usecases/get-all-global-projects-by.usecase";
import { GetAllTerritorialScopeUseCase } from "./domain/usecases/get-all-territorial-scope.usecase";
import { GetRecentsModifiedProjectsUseCase } from "./domain/usecases/get-recents-modified-projects.usecase";
import { ExportIndicatorsByUseCase } from "./domain/usecases/monitoring/export-indicators-by.usecase";
import { CreateActivityTaskUseCase } from "./domain/usecases/project-activity/create-activity-task.usecase";
import { CreateActivityUseCase } from "./domain/usecases/project-activity/create-activity.usecase";
import { DeleteActivityTaskUseCase } from "./domain/usecases/project-activity/delete-activity-task.usecase";
import { DeleteActivityUseCase } from "./domain/usecases/project-activity/delete-activity.usecase";
import { EditActivityUseCase } from "./domain/usecases/project-activity/edit-activiy.usecase";
import { EditKanbanActivityUseCase } from "./domain/usecases/project-activity/edit-kanban-activity.usecase";
import { GetAllActivitiesByUseCase } from "./domain/usecases/project-activity/get-all-activities-by.usecase";
import { GetAllProjectActivitiesSummaryByUseCase } from "./domain/usecases/project-activity/get-all-summary-activities-by.usecase";
import { UpdateActivityTaskUseCase } from "./domain/usecases/project-activity/update-activity-task.usecase";
import { SearchFinEntByProFinEntUseCase } from "./domain/usecases/search-financial-entity-by.usecase";
import { CreateTechnicalProposalUseCase } from "./domain/usecases/technical-proposal/create-technical-proposal.usecase";
import { EditTechnicalProposalUseCase } from "./domain/usecases/technical-proposal/edit-technical-proposal.usecase";
import { ExportBeneficiaryTechProposalByUseCase } from "./domain/usecases/technical-proposal/export-beneficiary-tech-proposal-by.usecase";
import { GetTechnicalProposalsByIdUseCase } from "./domain/usecases/technical-proposal/get-technical-proposal-by-id.usecase";
import { CreateIndicatorUseCase } from "./domain/usecases/technical-proposal/indicator/create-indicator.usecase";
import { DeleteIndicatorUseCase } from "./domain/usecases/technical-proposal/indicator/delete-indicator.usecase";
import { EditIndicatorUseCase } from "./domain/usecases/technical-proposal/indicator/edit-indicator.usecase";
import { GetAllIndicatorsByUseCase } from "./domain/usecases/technical-proposal/indicator/get-all-indicator-by.usecase";
import { CreateObjectiveUseCase } from "./domain/usecases/technical-proposal/objective/create-objective.usecase";
import { DeleteObjectiveUseCase } from "./domain/usecases/technical-proposal/objective/delete-objective.usecase";
import { EditObjectiveUseCase } from "./domain/usecases/technical-proposal/objective/edit-objective.usecase";
import { GetAllObjectivesByUseCase } from "./domain/usecases/technical-proposal/objective/get-all-objective-by.usecase";
import { AddDedicationModalFormViewModel } from "./presentation/components/project-form/employees/add-dedication-modal-form.viewmodel";
import { EntityCostFormViewModel } from "./presentation/components/project-form/expenses/entity-cost-form/entity-cost-form.viewmodel";
import { ExpenseAccommodationFormViewModel } from "./presentation/components/project-form/expenses/expenses-form/accommodation/expense-accommodation-form.viewmodel";
import { ExpensesModalBodyViewModel } from "./presentation/components/project-form/expenses/expenses-form/expense-modal-body.viewmodel";
import { ExpenseInvestmentFormViewModel } from "./presentation/components/project-form/expenses/expenses-form/investment/expense-investment-form.viewmodel";
import { ExpenseMaintenanceFormViewModel } from "./presentation/components/project-form/expenses/expenses-form/maintenance/expense-maintenance-form.viewmodel";
import { ExpenseManagementFormViewModel } from "./presentation/components/project-form/expenses/expenses-form/management/expense-management-form.viewmodel";
import { ExpensePersonnelFormViewModel } from "./presentation/components/project-form/expenses/expenses-form/personnel/expense-personnel-form.viewmodel";
import { ExpenseTravelFormViewModel } from "./presentation/components/project-form/expenses/expenses-form/travels/expense-travel-form.viewmodel";
import { ExpensesTabViewModel } from "./presentation/components/project-form/expenses/expenses-tab.viewmodel";
import { JustificationTabViewModel } from "./presentation/components/project-form/justification/justification-tab.viewmodel";
import { KanbanTabViewModel } from "./presentation/components/project-form/kanban/kanban-tab.viewmodel";
import { MonitoringTabViewModel } from "./presentation/components/project-form/monitoring/monitoring-tab.viewmodel";
import { ActivityFormViewModel } from "./presentation/components/project-form/technical-proposal/activity/activity-form.viewmodel";
import { ObjectiveFormViewModel } from "./presentation/components/project-form/technical-proposal/objective/objective-form.viewmodel";
import { TechnicalProposalFormViewModel } from "./presentation/components/project-form/technical-proposal/technical-proposal-form.viewmodel";
import { CreateFinancialEntityPageViewModel } from "./presentation/pages/financial-entity/create/create-financial-entity-page.viewmodel";
import { DetailFinancialEntityPageViewModel } from "./presentation/pages/financial-entity/detail/detail-financial-entity-page.viewmodel";
import { EditFinancialEntityPageViewModel } from "./presentation/pages/financial-entity/edit/edit-financial-entity-page.viewmodel";

const projectModule = new DiModuleBuilder();

projectModule.registerSubModules(() => ({
    datasources: new ContainerModule((bind) => {
        bind(BudgetDataSource).toSelf();
        bind(FinancialEntityDatasource).toSelf();
        bind(GlobalProjectDatasource).toSelf();
        bind(IndicatorDataSource).toSelf();
        bind(ObjectiveDataSource).toSelf();
        bind(ProjectActivityDataSource).toSelf();
        bind(ProjectActivityTaskDataSource).toSelf();
        bind(ProjectDatasource).toSelf();
        bind(ProjectEmployeeDatasource).toSelf();
        bind(ProjectEmployeeDedicationDatasource).toSelf();
        bind(ProjectExpenseDatasource).toSelf();
        bind(ProjectFinancialEntityDataSource).toSelf();
        bind(ProjectMaterialDatasource).toSelf();
        bind(TechnicalProposalDatasource).toSelf();
    }),
    mappers: new ContainerModule((bind) => {
        bind(AccommodationExpenseMapper).toSelf();
        bind(ActivitiesSummaryMapper).toSelf();
        bind(ActivityMapper).toSelf();
        bind(ActivityStatusEnumMapper).toSelf();
        bind(ActivitySummaryMapper).toSelf();
        bind(ActivityTaskMapper).toSelf();
        bind(BudgetCategoriesMapper).toSelf();
        bind(BudgetCategoryConceptMapper).toSelf();
        bind(BudgetCategoryConceptsMapper).toSelf();
        bind(BudgetCategoryGroupMapper).toSelf();
        bind(BudgetCategoryGroupsMapper).toSelf();
        bind(BudgetCategoryMapper).toSelf();
        bind(BudgetColumnMapper).toSelf();
        bind(BudgetColumnsMapper).toSelf();
        bind(BudgetColumnTypeEnumMapper).toSelf();
        bind(BudgetColumnTypeMapper).toSelf();
        bind(BudgetSpreadsheetCategoryMapper).toSelf();
        bind(BudgetSpreadsheetColumnMapper).toSelf();
        bind(BudgetSpreadsheetGroupMapper).toSelf();
        bind(BudgetSpreadsheetItemMapper).toSelf();
        bind(BudgetSpreadsheetMapper).toSelf();
        bind(BudgetSpreadsheetValidationMapper).toSelf();
        bind(BudgetSpreadsheetValidationTypeEnumMapper).toSelf();
        bind(BudgetSpreadsheetValueMapper).toSelf();
        bind(BudgetsSummaryMapper).toSelf();
        bind(BudgetSummaryMapper).toSelf();
        bind(BudgetTypeEnumMapper).toSelf();
        bind(BudgetTypeMapper).toSelf();
        bind(BudgetValidationMapper).toSelf();
        bind(BudgetValidationsMapper).toSelf();
        bind(CatalogueEnumMapper).toSelf();
        bind(CatalogueMapper).toSelf();
        bind(ContractTypeEnumMapper).toSelf();
        bind(ContractTypeMapper).toSelf();
        bind(ContributionGroupTypeEnumMapper).toSelf();
        bind(ContributionGroupTypeMapper).toSelf();
        bind(CreateBudgetCategoryConceptMapper).toSelf();
        bind(CreateBudgetCategoryGroupMapper).toSelf();
        bind(CreateBudgetCategoryMapper).toSelf();
        bind(CreateBudgetColumnMapper).toSelf();
        bind(CreateExpenseMapper).toSelf();
        bind(CreateFinancialEntityBudgetMapper).toSelf();
        bind(CreateFinancialEntityMapper).toSelf();
        bind(CreateProjectMapper).toSelf();
        bind(CreateProjectMaterialMapper).toSelf();
        bind(DietTypeEnumMapper).toSelf();
        bind(DietTypeMapper).toSelf();
        bind(EditExpenseMapper).toSelf();
        bind(EditFinancialEntityBudgetMapper).toSelf();
        bind(EditFinancialEntityMapper).toSelf();
        bind(EditProjectMapper).toSelf();
        bind(EditProjectMaterialMapper).toSelf();
        bind(ExpenseMapper).toSelf();
        bind(ExpensesMapper).toSelf();
        bind(ExpenseSubTypeEnumMapper).toSelf();
        bind(ExpenseSubTypeMapper).toSelf();
        bind(ExpenseTypeEnumMapper).toSelf();
        bind(ExpenseTypeMapper).toSelf();
        bind(FinancialEntitiesMapper).toSelf();
        bind(FinancialEntityBudgetMapper).toSelf();
        bind(FinancialEntityBudgetsMapper).toSelf();
        bind(FinancialEntityMapper).toSelf();
        bind(FinancialEntityTypeEnumMapper).toSelf();
        bind(FinancialEntityTypeMapper).toSelf();
        bind(FinancingTypeEnumMapper).toSelf();
        bind(FinancingTypeMapper).toSelf();
        bind(GlobalProjectMapper).toSelf();
        bind(IndicatorMapper).toSelf();
        bind(InvestmentExpenseMapper).toSelf();
        bind(MaintenanceExpenseMapper).toSelf();
        bind(ManagementExpenseMapper).toSelf();
        bind(MonthTypeEnumMapper).toSelf();
        bind(MonthTypeMapper).toSelf();
        bind(ObjectiveMapper).toSelf();
        bind(PaymentMethodTypeEnumMapper).toSelf();
        bind(PaymentMethodTypeMapper).toSelf();
        bind(PersonnelExpenseMapper).toSelf();
        bind(ProjectEmployeeDedicationMapper).toSelf();
        bind(ProjectEmployeeDedicationsMapper).toSelf();
        bind(ProjectEmployeeMapper).toSelf();
        bind(ProjectEmployeesMapper).toSelf();
        bind(ProjectFinancialEntitiesMapper).toSelf();
        bind(ProjectFinancialEntityMapper).toSelf();
        bind(ProjectMapper).toSelf();
        bind(ProjectMaterialMapper).toSelf();
        bind(ProjectsSummaryMapper).toSelf();
        bind(ProjectSummaryMapper).toSelf();
        bind(StatusEnumMapper).toSelf();
        bind(StatusMapper).toSelf();
        bind(TechnicalProposalMapper).toSelf();
        bind(TechnicalProposalsMapper).toSelf();
        bind(TerritorialScopeEnumMapper).toSelf();
        bind(TerritorialScopeMapper).toSelf();
        bind(TransportTypeEnumMapper).toSelf();
        bind(TransportTypeMapper).toSelf();
        bind(TravelExpenseMapper).toSelf();
    }),
    repositories: new ContainerModule((bind) => {
        bind(BudgetRepository).toSelf();
        bind(FinancialEntityRepository).toSelf();
        bind(GlobalProjectRepository).toSelf();
        bind(IndicatorRepository).toSelf();
        bind(ObjectiveRepository).toSelf();
        bind(ProjectActivityRepository).toSelf();
        bind(ProjectActivityTaskRepository).toSelf();
        bind(ProjectEmployeeDedicationRepository).toSelf();
        bind(ProjectEmployeeRepository).toSelf();
        bind(ProjectExpenseRepository).toSelf();
        bind(ProjectFinancialEntityRepository).toSelf();
        bind(ProjectMaterialRepository).toSelf();
        bind(ProjectRepository).toSelf();
        bind(TechnicalProposalRepository).toSelf();
    }),
    useCases: new ContainerModule((bind) => {
        bind(CloneBudgetUseCase).toSelf();
        bind(CreateActivityTaskUseCase).toSelf();
        bind(CreateActivityUseCase).toSelf();
        bind(CreateBudgetCategoryConceptUseCase).toSelf();
        bind(CreateBudgetCategoryGroupUseCase).toSelf();
        bind(CreateBudgetCategoryUseCase).toSelf();
        bind(CreateBudgetColumnUseCase).toSelf();
        bind(CreateBudgetFromTemplateUseCase).toSelf();
        bind(CreateBudgetUseCase).toSelf();
        bind(CreateBudgetValidationUseCase).toSelf();
        bind(CreateFinancialEntityUseCase).toSelf();
        bind(CreateIndicatorUseCase).toSelf();
        bind(CreateObjectiveUseCase).toSelf();
        bind(CreateProjectEmployeeDedicationUseCase).toSelf();
        bind(CreateProjectEmployeeUseCase).toSelf();
        bind(CreateProjectMaterialUseCase).toSelf();
        bind(CreateProjectUseCase).toSelf();
        bind(CreateTechnicalProposalUseCase).toSelf();
        bind(DeleteActivityTaskUseCase).toSelf();
        bind(DeleteActivityUseCase).toSelf();
        bind(DeleteBudgetCategoryConceptUseCase).toSelf();
        bind(DeleteBudgetCategoryGroupUseCase).toSelf();
        bind(DeleteBudgetCategoryUseCase).toSelf();
        bind(DeleteBudgetColumnUseCase).toSelf();
        bind(DeleteBudgetUseCase).toSelf();
        bind(DeleteBudgetValidationUseCase).toSelf();
        bind(DeleteExpenseUseCase).toSelf();
        bind(DeleteIndicatorUseCase).toSelf();
        bind(DeleteObjectiveUseCase).toSelf();
        bind(DeleteProjectEmployeeDedicationUseCase).toSelf();
        bind(DeleteProjectEmployeeUseCase).toSelf();
        bind(DeleteProjectMaterialUseCase).toSelf();
        bind(EditActivityUseCase).toSelf();
        bind(EditBudgetCategoryUseCase).toSelf();
        bind(EditBudgetColumnUseCase).toSelf();
        bind(EditBudgetUseCase).toSelf();
        bind(EditBudgetValidationUseCase).toSelf();
        bind(EditCategoryConceptUseCase).toSelf();
        bind(EditCategoryGroupUseCase).toSelf();
        bind(EditExpenseUseCase).toSelf();
        bind(EditFinancialEntityUseCase).toSelf();
        bind(EditIndicatorUseCase).toSelf();
        bind(EditKanbanActivityUseCase).toSelf();
        bind(EditObjectiveUseCase).toSelf();
        bind(EditProjectMaterialUseCase).toSelf();
        bind(EditProjectUseCase).toSelf();
        bind(EditTechnicalProposalUseCase).toSelf();
        bind(ExportBeneficiaryTechProposalByUseCase).toSelf();
        bind(ExportFinancialEntityUseCase).toSelf();
        bind(ExportIndicatorsByUseCase).toSelf();
        bind(ExportJustificationUseCase).toSelf();
        bind(ExportProjectsUseCase).toSelf();
        bind(ExportTechnicalProposalUseCase).toSelf();
        bind(GetAllActivitiesByUseCase).toSelf();
        bind(GetAllBudgetCategoriesByBudgetIdUseCase).toSelf();
        bind(GetAllBudgetColumnsByBudgetIdUseCase).toSelf();
        bind(GetAllBudgetColumnTypesUseCase).toSelf();
        bind(GetAllBudgetsUseCase).toSelf();
        bind(GetAllBudgetTypesUseCase).toSelf();
        bind(GetAllCataloguesUseCase).toSelf();
        bind(GetAllCategoryConceptsByCatIdUseCase).toSelf();
        bind(GetAllCategoryGroupsByCategoryIdUseCase).toSelf();
        bind(GetAllContractTypesUseCase).toSelf();
        bind(GetAllContributionGroupTypesUseCase).toSelf();
        bind(GetAllDietTypesUseCase).toSelf();
        bind(GetAllExpensesUseCase).toSelf();
        bind(GetAllFinancialEntitiesUseCase).toSelf();
        bind(GetAllFinancialEntityTypesUsecase).toSelf();
        bind(GetAllFinancingTypesUsecase).toSelf();
        bind(GetAllGlobalProjectsByUseCase).toSelf();
        bind(GetAllIndicatorsByUseCase).toSelf();
        bind(GetAllMaterialsUseCase).toSelf();
        bind(GetAllMonthTypesUseCase).toSelf();
        bind(GetAllObjectivesByUseCase).toSelf();
        bind(GetAllPaymentMethodTypesUseCase).toSelf();
        bind(GetAllProjectActivitiesSummaryByUseCase).toSelf();
        bind(GetAllProjectEmployeeDedicationsByUseCase).toSelf();
        bind(GetAllProjectEmployeesByUseCase).toSelf();
        bind(GetAllProjectExpenseSubTypesUseCase).toSelf();
        bind(GetAllProjectExpenseTypesUseCase).toSelf();
        bind(GetAllProjectFinancialEntitiesUseCase).toSelf();
        bind(GetAllProjectMaterialsUseCase).toSelf();
        bind(GetAllProjectsUseCase).toSelf();
        bind(GetAllStatusUseCase).toSelf();
        bind(GetAllTechnicalProposalsByUseCase).toSelf();
        bind(GetAllTerritorialScopeUseCase).toSelf();
        bind(GetAllTransportTypesUseCase).toSelf();
        bind(GetAllValidationsByBudgetIdUseCase).toSelf();
        bind(GetBudgetByIdUseCase).toSelf();
        bind(GetBudgetSpreadsheetByIdUseCase).toSelf();
        bind(GetFinancialEntityByIdUseCase).toSelf();
        bind(GetObjectiveByIdUseCase).toSelf();
        bind(GetProjectByIdUseCase).toSelf();
        bind(GetProjectEmployeeByIdUseCase).toSelf();
        bind(GetProjectEmployeeDedicationByIdUseCase).toSelf();
        bind(GetProjectEmployeeDedicationsByUseCase).toSelf();
        bind(GetProjectEmployeesByUseCase).toSelf();
        bind(GetRecentsModifiedProjectsUseCase).toSelf();
        bind(GetTechnicalProposalsByIdUseCase).toSelf();
        bind(ImportProjectsUseCase).toSelf();
        bind(SearchFinancialEntitiesByUseCase).toSelf();
        bind(SearchFinEntByProFinEntUseCase).toSelf();
        bind(SearchProjectsByUseCase).toSelf();
        bind(UpdateActivityTaskUseCase).toSelf();
        bind(UpdateBudgetSpreadsheetUseCase).toSelf();
        bind(UpdateProjectEmployeeDedicationUsecase).toSelf();
        bind(UpdateProjectEmployeeUseCase).toSelf();
    }),
    viewModels: new ContainerModule((bind) => {
        bind(ActivityFormViewModel).toSelf();
        bind(AddDedicationModalFormViewModel).toSelf();
        bind(AddEmployeeModalFormViewModel).toSelf();
        bind(BudgetCategoriesViewModel).toSelf();
        bind(BudgetCategoryConceptsViewModel).toSelf();
        bind(BudgetCategoryGroupsViewModel).toSelf();
        bind(BudgetColumnViewModel).toSelf();
        bind(BudgetValidationFormViewModel).toSelf();
        bind(BudgetValidationsViewModel).toSelf();
        bind(CreateExpenseUseCase).toSelf();
        bind(CreateFinancialEntityBudgetPageViewModel).toSelf();
        bind(CreateFinancialEntityPageViewModel).toSelf();
        bind(CreateProjectPageViewModel).toSelf();
        bind(DetailFinancialEntityPageViewModel).toSelf();
        bind(DetailProjectPageViewModel).toSelf();
        bind(EditBudgetPageViewModel).toSelf();
        bind(EditFinancialEntityBudgetPageViewModel).toSelf();
        bind(EditFinancialEntityPageViewModel).toSelf();
        bind(EditProjectPageViewModel).toSelf();
        bind(EmployeeDetailViewModel).toSelf();
        bind(EmployeesTabViewModel).toSelf();
        bind(EntityCostFormViewModel).toSelf();
        bind(ExpenseAccommodationFormViewModel).toSelf();
        bind(ExpenseInvestmentFormViewModel).toSelf();
        bind(ExpenseMaintenanceFormViewModel).toSelf();
        bind(ExpenseManagementFormViewModel).toSelf();
        bind(ExpensePersonnelFormViewModel).toSelf();
        bind(ExpensesModalBodyViewModel).toSelf();
        bind(ExpensesTabViewModel).toSelf();
        bind(ExpenseTravelFormViewModel).toSelf();
        bind(FinancialEntityFormViewmodel).toSelf();
        bind(FinancialEntityListPageViewModel).toSelf();
        bind(GeneralTabViewModel).toSelf();
        bind(JustificationTabViewModel).toSelf();
        bind(KanbanActivityViewModel).toSelf();
        bind(KanbanTabViewModel).toSelf();
        bind(MaterialFormViewModel).toSelf();
        bind(MaterialResourcesViewModel).toSelf();
        bind(MonitoringTabViewModel).toSelf();
        bind(ObjectiveFormViewModel).toSelf();
        bind(ProjectFinancialEntityFormViewModel).toSelf();
        bind(ProjectListPageViewModel).toSelf();
        bind(TechnicalProposalFormViewModel).toSelf();
        bind(TechnicalProposalTabViewModel).toSelf();
    }),
}));

projectModule.registerModule();

export { projectModule };
