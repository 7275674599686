import { DependenceGradeEnumDto } from "@beneficiary/data/dto/dependence-grade-enum.dto";
import { DependenceGradeEnum } from "@beneficiary/domain/models/dependence-grade.enum";
import { Nullable } from "@core/domain/types/nullable.type";
import { injectable } from "inversify";

@injectable()
export class DependenceGradesEnumMapper {
    map(
        relationshipIdDto: DependenceGradeEnumDto,
    ): Nullable<DependenceGradeEnum> {
        switch (relationshipIdDto) {
            case DependenceGradeEnumDto.DependenceGrade1:
                return DependenceGradeEnum.DependenceGrade1;
            case DependenceGradeEnumDto.DependenceGrade2:
                return DependenceGradeEnum.DependenceGrade2;
            case DependenceGradeEnumDto.DependenceGrade3:
                return DependenceGradeEnum.DependenceGrade3;

            default:
                return null;
        }
    }

    mapToDto(relationshipIdDto: DependenceGradeEnum): DependenceGradeEnumDto {
        // eslint-disable-next-line default-case
        switch (relationshipIdDto) {
            case DependenceGradeEnum.DependenceGrade1:
                return DependenceGradeEnumDto.DependenceGrade1;
            case DependenceGradeEnum.DependenceGrade2:
                return DependenceGradeEnumDto.DependenceGrade2;
            case DependenceGradeEnum.DependenceGrade3:
                return DependenceGradeEnumDto.DependenceGrade3;
        }
    }
}
