import { GenderEnum } from "@beneficiary/domain/models/gender.model";
import { Nullable } from "@core/domain/types/nullable.type";

export class VolunteerInfo {
    constructor(
        public gender: GenderEnum,
        public mobilePhone: string,
        public email: Nullable<string>,
        public address: string,
        public streetNumber: string,
        public floor: Nullable<string>,
        public stair: Nullable<string>,
        public door: Nullable<string>,
        public city: string,
        public postalCode: string,
    ) {}
}
