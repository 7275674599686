import { coreTypes } from "@core/core-types.di";
import { EXPAND_ALL, FieldsQuery } from "@core/data/dto/fields.dto";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import type { Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { Nullable } from "@core/domain/types/nullable.type";
import {
    BudgetCategoriesDto,
    BudgetCategoriesQuery,
    BudgetCategoryConceptsDto,
    BudgetCategoryConceptsQuery,
    BudgetCategoryGroupsDto,
    BudgetColumnsDto,
    BudgetColumnsQuery,
    BudgetDto,
    BudgetValidationDto,
    BudgetValidationsDto,
    BudgetValidationsQuery,
    BudgetsDto,
    BudgetsQuery,
    CategoryConceptBudgetDto,
    CategoryGroupBudgetDto,
    CategoryGroupsQuery,
    CategoryRowBudgetDto,
    ColumnBudgetDto,
    CreateBudgetValidationBody,
} from "@project/data/dto/budget.dto";
import { BudgetColumnTypeDto } from "@project/data/dto/budget/column/budget-column-type.dto";
import { CreateBudgetColumnBody } from "@project/data/dto/budget/column/create-budget-column.body";
import { CreateFinancialEntityBudgetBody } from "@project/data/dto/budget/create-financial-entity-budget.body";
import { BudgetCategoriesMapper } from "@project/data/mappers/budget/categories/budget-categories.mapper";
import { BudgetCategoryMapper } from "@project/data/mappers/budget/categories/budget-category.mapper";
import { BudgetCategoryConceptsMapper } from "@project/data/mappers/budget/categories/concepts/budget-category-concepts.mapper";
import { CreateBudgetCategoryConceptMapper } from "@project/data/mappers/budget/categories/concepts/create-budget-category-concept.mapper";
import { CreateBudgetCategoryMapper } from "@project/data/mappers/budget/categories/create-budget-category.mapper";
import { BudgetCategoryGroupMapper } from "@project/data/mappers/budget/categories/groups/budget-category-group.mapper";
import { BudgetCategoryGroupsMapper } from "@project/data/mappers/budget/categories/groups/budget-category-groups.mapper";
import { CreateBudgetCategoryGroupMapper } from "@project/data/mappers/budget/categories/groups/create-budget-category-group.mapper";
import { BudgetColumnTypeMapper } from "@project/data/mappers/budget/columns/budget-column-type.mapper";
import { BudgetColumnMapper } from "@project/data/mappers/budget/columns/budget-column.mapper";
import { BudgetColumnsMapper } from "@project/data/mappers/budget/columns/budget-columns.mapper";
import { CreateBudgetColumnMapper } from "@project/data/mappers/budget/columns/create-budget-column.mapper";
import { CreateFinancialEntityBudgetMapper } from "@project/data/mappers/budget/create-financial-entity-budget.mapper";
import { EditFinancialEntityBudgetMapper } from "@project/data/mappers/budget/edit-financial-entity-budget.mapper";
import { FinancialEntityBudgetMapper } from "@project/data/mappers/budget/financial-entity-budget.mapper";
import { BudgetValidationMapper } from "@project/data/mappers/budget/validations/budget-validation.mapper";
import { BudgetValidationsMapper } from "@project/data/mappers/budget/validations/budget-validations.mapper";
import { BudgetsSummaryMapper } from "@project/data/mappers/budgets-summary.mapper";
import {
    BudgetsSearchFilters,
    BudgetsSummary,
} from "@project/domain/models/budget-summary.model";
import { CreateBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/create-budget-category-concept.model";
import { EditBudgetCategoryConcept } from "@project/domain/models/budget/budget-category/concepts/edit-budget-category-concept.model";
import { CreateBudgetCategory } from "@project/domain/models/budget/budget-category/create-budget-category.model";
import { EditBudgetCategory } from "@project/domain/models/budget/budget-category/edit-budget-category.model";
import { CreateBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/create-budget-category-group.model";
import { EditBudgetCategoryGroup } from "@project/domain/models/budget/budget-category/groups/edit-budget-category-group.model";
import { BudgetColumnType } from "@project/domain/models/budget/budget-column/budget-column-type.model";
import { CreateBudgetColumn } from "@project/domain/models/budget/budget-column/create-budget-column.model";
import { EditBudgetColumn } from "@project/domain/models/budget/budget-column/edit-budget-column.model";
import { CreateFinancialEntityBudget } from "@project/domain/models/budget/create-financial-entity-budget.model";
import { EditFinancialEntityBudgetModel } from "@project/domain/models/budget/edit-financial-entity-budget.model";
import {
    BudgetCategories,
    BudgetCategory,
    BudgetCategoryConcept,
    BudgetCategoryConcepts,
    BudgetCategoryGroup,
    BudgetCategoryGroups,
    BudgetColumn,
    BudgetColumns,
    BudgetValidation,
    BudgetValidations,
    CreateBudgetValidation,
    FinancialEntityBudget,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";

const ENDPOINTS = {
    BUDGETS: "/budgets/",
    COLUMNS: "/column_budgets/",
    CATEGORIES: "/categories_row_budgets/",
    COLUMN_TYPES: "/column_budgets/types",
    CATEGORY_GROUP: "/categories_group_budgets/",
    CATEGORY_CONCEPTS: "/categories_item_budgets/",
    VALIDATIONS: "/budget_validations/",
};

@injectable()
export class BudgetDataSource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(BudgetsSummaryMapper)
        private readonly budgetsSummaryMapper: BudgetsSummaryMapper,
        @inject(FinancialEntityBudgetMapper)
        private readonly financialEntityBudgetMapper: FinancialEntityBudgetMapper,
        @inject(BudgetColumnsMapper)
        private readonly budgetColumnsMapper: BudgetColumnsMapper,
        @inject(BudgetColumnMapper)
        private readonly budgetColumnMapper: BudgetColumnMapper,
        @inject(BudgetColumnTypeMapper)
        private readonly budgetColumnTypeMapper: BudgetColumnTypeMapper,
        @inject(CreateBudgetColumnMapper)
        private readonly createBudgetColumnMapper: CreateBudgetColumnMapper,
        @inject(BudgetCategoryMapper)
        private readonly budgetCategoryMapper: BudgetCategoryMapper,
        @inject(BudgetCategoriesMapper)
        private readonly budgetCategoriesMapper: BudgetCategoriesMapper,
        @inject(CreateBudgetCategoryMapper)
        private readonly createBudgetCategoryMapper: CreateBudgetCategoryMapper,
        @inject(BudgetCategoryGroupMapper)
        private readonly budgetCategoryGroupMapper: BudgetCategoryGroupMapper,
        @inject(BudgetCategoryGroupsMapper)
        private readonly budgetCategoryGroupsMapper: BudgetCategoryGroupsMapper,
        @inject(CreateBudgetCategoryGroupMapper)
        private readonly createBudgetCategoryGroupMapper: CreateBudgetCategoryGroupMapper,
        @inject(CreateBudgetCategoryConceptMapper)
        private readonly createBudgetCategoryConceptMapper: CreateBudgetCategoryConceptMapper,
        @inject(BudgetCategoryConceptsMapper)
        private readonly budgetCategoryConceptsMapper: BudgetCategoryConceptsMapper,
        @inject(CreateFinancialEntityBudgetMapper)
        private readonly createFinancialEntityBudgetMapper: CreateFinancialEntityBudgetMapper,
        @inject(EditFinancialEntityBudgetMapper)
        private readonly editFinancialEntityBudgetMapper: EditFinancialEntityBudgetMapper,
        @inject(BudgetValidationsMapper)
        private readonly budgetValidationsMapper: BudgetValidationsMapper,
        @inject(BudgetValidationMapper)
        private readonly budgetValidationMapper: BudgetValidationMapper,
    ) {}

    async fetchAllBy(
        pagination: Pagination,
        filters?: BudgetsSearchFilters,
    ): Promise<Either<FallbackError, BudgetsSummary>> {
        const query: BudgetsQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };

        if (filters?.financialEntityId) {
            query.finantial_entity = filters.financialEntityId;
        }
        const responseResult = await this.http.get<BudgetsDto>(
            ENDPOINTS.BUDGETS,
            {
                query,
            },
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.budgetsSummaryMapper.map(
                    plainToClass(BudgetsDto, response.data),
                ),
            );
    }
    async getById(
        id: number,
    ): Promise<Either<FallbackError, FinancialEntityBudget>> {
        const query: FieldsQuery = {
            expand: EXPAND_ALL,
        };
        const responseResult = await this.http.get<BudgetDto>(
            `${ENDPOINTS.BUDGETS}${id}`,
            { query },
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const budget = this.financialEntityBudgetMapper.map(
                    plainToClass(BudgetDto, response.data),
                );
                if (!budget) return Either.Left(new FallbackError());

                return Either.Right(budget);
            });
    }

    async create(
        budget: CreateFinancialEntityBudget,
    ): Promise<Either<ValidationError | FallbackError, FinancialEntityBudget>> {
        const budgetBody =
            this.createFinancialEntityBudgetMapper.mapToDto(budget);
        const responseResult = await this.http.post<
            BudgetDto,
            CreateFinancialEntityBudgetBody
        >(ENDPOINTS.BUDGETS, budgetBody);
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdBudget = this.financialEntityBudgetMapper.map(
                    plainToClass(BudgetDto, response.data),
                );
                if (!createdBudget) return Either.Left(new FallbackError());
                return Either.Right(createdBudget);
            });
    }

    async edit(
        budget: EditFinancialEntityBudgetModel,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<FinancialEntityBudget>>
    > {
        const body = this.editFinancialEntityBudgetMapper.mapToDto(budget);
        const responseResult = await this.http.put<BudgetDto>(
            `${ENDPOINTS.BUDGETS}${budget.id}/`,
            body,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .map((response) =>
                this.financialEntityBudgetMapper.map(response.data),
            );
    }

    async delete(id: number): Promise<Either<FallbackError, true>> {
        const responseResult = await this.http.delete(
            `${ENDPOINTS.BUDGETS}${id}`,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async clone(id: number): Promise<Either<FallbackError, true>> {
        const responseResult = await this.http.get(
            `${ENDPOINTS.BUDGETS}${id}/clone_budget/`,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    // Columns
    async getColumnsByBudgetId(
        budgetId: number,
    ): Promise<Either<FallbackError, BudgetColumns>> {
        const query: BudgetColumnsQuery = {
            budget: budgetId,
        };
        const responseResult = await this.http.get<BudgetColumnsDto>(
            ENDPOINTS.COLUMNS,
            { query },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.budgetColumnsMapper.map(
                    plainToClass(BudgetColumnsDto, response.data),
                ),
            );
    }

    async getColumnsTypes(): Promise<
        Either<FallbackError, BudgetColumnType[]>
    > {
        const responseResult = await this.http.get<BudgetColumnTypeDto[]>(
            ENDPOINTS.COLUMN_TYPES,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((budgetColumnTypeDto) =>
                    this.budgetColumnTypeMapper.map(
                        plainToClass(BudgetColumnTypeDto, budgetColumnTypeDto),
                    ),
                ),
            );
    }
    async createColumn(
        newColumn: CreateBudgetColumn,
    ): Promise<Either<ValidationError | FallbackError, BudgetColumn>> {
        const createBudgetColumnDto =
            this.createBudgetColumnMapper.mapToDto(newColumn);

        const responseResult = await this.http.post<
            ColumnBudgetDto,
            CreateBudgetColumnBody
        >(ENDPOINTS.COLUMNS, createBudgetColumnDto);
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdColumn = this.createBudgetColumnMapper.map(
                    plainToClass(ColumnBudgetDto, response.data),
                );
                if (!createdColumn) return Either.Left(new FallbackError());
                return Either.Right(createdColumn);
            });
    }

    async editColumn(
        column: EditBudgetColumn,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetColumn>>
    > {
        const responseResult = await this.http.put<ColumnBudgetDto>(
            `${ENDPOINTS.COLUMNS}${column.id}/`,
            column,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .map((response) => this.budgetColumnMapper.map(response.data));
    }
    async deleteColumn(columnId: number): Promise<Either<FallbackError, true>> {
        const responseResult = await this.http.delete(
            `${ENDPOINTS.COLUMNS}${columnId}`,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    // Categories
    async getCategoriesByBudgetId(
        budgetId: number,
    ): Promise<Either<FallbackError, BudgetCategories>> {
        const query: BudgetCategoriesQuery = {
            budget: budgetId,
        };
        const responseResult = await this.http.get<BudgetCategoriesDto>(
            ENDPOINTS.CATEGORIES,
            { query },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.budgetCategoriesMapper.map(
                    plainToClass(BudgetCategoriesDto, response.data),
                ),
            );
    }

    async createCategory(
        newCategory: CreateBudgetCategory,
    ): Promise<Either<ValidationError | FallbackError, BudgetCategory>> {
        const createBudgetCategoryBody =
            this.createBudgetCategoryMapper.mapToDto(newCategory);
        const responseResult = await this.http.post(
            ENDPOINTS.CATEGORIES,
            createBudgetCategoryBody,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdCategory = this.createBudgetCategoryMapper.map(
                    plainToClass(CategoryRowBudgetDto, response.data),
                );
                if (!createdCategory) return Either.Left(new FallbackError());
                return Either.Right(createdCategory);
            });
    }

    async editCategory(
        category: EditBudgetCategory,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetCategory>>
    > {
        const responseResult = await this.http.put<CategoryRowBudgetDto>(
            `${ENDPOINTS.CATEGORIES}${category.id}/`,
            category,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .map((response) => this.budgetCategoryMapper.map(response.data));
    }

    async deleteCategory(
        categoryId: number,
    ): Promise<Either<FallbackError, true>> {
        const responseResult = await this.http.delete(
            `${ENDPOINTS.CATEGORIES}${categoryId}`,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    // Category Groups
    async getCategoryGroupsByCategoryId(
        categoryId: number,
    ): Promise<Either<FallbackError, BudgetCategoryGroups>> {
        const query: CategoryGroupsQuery = {
            category: categoryId,
        };
        const responseResult = await this.http.get<BudgetCategoriesDto>(
            ENDPOINTS.CATEGORY_GROUP,
            { query },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.budgetCategoryGroupsMapper.map(
                    plainToClass(BudgetCategoryGroupsDto, response.data),
                ),
            );
    }

    async createCategoryGroup(
        newCategoryGroup: CreateBudgetCategoryGroup,
    ): Promise<Either<ValidationError | FallbackError, BudgetCategoryGroup>> {
        const createBudgetCategoryGroupDto =
            this.createBudgetCategoryGroupMapper.mapToDto(newCategoryGroup);
        const responseResult = await this.http.post(
            ENDPOINTS.CATEGORY_GROUP,
            createBudgetCategoryGroupDto,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdCategoryGroup =
                    this.createBudgetCategoryGroupMapper.map(
                        plainToClass(CategoryGroupBudgetDto, response.data),
                    );
                if (!createdCategoryGroup)
                    return Either.Left(new FallbackError());
                return Either.Right(createdCategoryGroup);
            });
    }

    async editCategoryGroup(
        categoryGroup: EditBudgetCategoryGroup,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetCategoryGroup>>
    > {
        const responseResult = await this.http.put<CategoryGroupBudgetDto>(
            `${ENDPOINTS.CATEGORY_GROUP}${categoryGroup.id}/`,
            categoryGroup,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .map((response) =>
                this.budgetCategoryGroupMapper.map(response.data),
            );
    }
    async deleteCategoryGroup(
        categoryGroupId: number,
    ): Promise<Either<FallbackError, true>> {
        const responseResult = await this.http.delete(
            `${ENDPOINTS.CATEGORY_GROUP}${categoryGroupId}`,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    // Concepts
    async getConceptsByCategoryId(
        categoryId: number,
    ): Promise<Either<FallbackError, BudgetCategoryConcepts>> {
        const query: BudgetCategoryConceptsQuery = {
            category: categoryId,
        };
        const responseResult = await this.http.get<BudgetCategoryConceptsDto>(
            ENDPOINTS.CATEGORY_CONCEPTS,
            { query },
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                this.budgetCategoryConceptsMapper.map(
                    plainToClass(BudgetCategoryConceptsDto, response.data),
                ),
            );
    }

    async createConcept(
        newConcept: CreateBudgetCategoryConcept,
    ): Promise<Either<ValidationError | FallbackError, BudgetCategoryConcept>> {
        const createBudgetCategoryConceptDto =
            this.createBudgetCategoryConceptMapper.mapToDto(newConcept);
        const responseResult = await this.http.post(
            ENDPOINTS.CATEGORY_CONCEPTS,
            createBudgetCategoryConceptDto,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdConcept =
                    this.createBudgetCategoryConceptMapper.map(
                        plainToClass(CategoryConceptBudgetDto, response.data),
                    );
                if (!createdConcept) return Either.Left(new FallbackError());
                return Either.Right(createdConcept);
            });
    }

    async editConcept(
        concept: EditBudgetCategoryConcept,
    ): Promise<
        Either<ValidationError | FallbackError, Nullable<BudgetCategoryConcept>>
    > {
        const responseResult = await this.http.put<CategoryConceptBudgetDto>(
            `${ENDPOINTS.CATEGORY_CONCEPTS}${concept.id}/`,
            concept,
        );
        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .map((categoryConcept) =>
                this.createBudgetCategoryConceptMapper.map(
                    categoryConcept.data,
                ),
            );
    }
    async deleteConcept(
        conceptId: number,
    ): Promise<Either<FallbackError, true>> {
        const responseResult = await this.http.delete(
            `${ENDPOINTS.CATEGORY_CONCEPTS}${conceptId}`,
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map(() => true);
    }

    async getValidationsByBudgetId(
        budgetId: number,
    ): Promise<Either<FallbackError, BudgetValidations>> {
        const query: BudgetValidationsQuery = {
            budget: budgetId,
        };
        const responseResult = await this.http.get<BudgetValidationsDto>(
            ENDPOINTS.VALIDATIONS,
            { query },
        );
        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) => this.budgetValidationsMapper.map(response.data));
    }

    async createValidation(
        createValidation: CreateBudgetValidation,
    ): Promise<Either<ValidationError | FallbackError, BudgetValidation>> {
        const body =
            this.budgetValidationMapper.mapToCreateDto(createValidation);

        const responseResult = await this.http.post<
            BudgetValidationDto,
            CreateBudgetValidationBody
        >(ENDPOINTS.VALIDATIONS, body);

        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdValidation = this.budgetValidationMapper.map(
                    plainToClass(BudgetValidationDto, response.data),
                );

                if (!createdValidation) return Either.Left(new FallbackError());

                return Either.Right(createdValidation);
            });
    }

    async editValidation(
        validation: BudgetValidation,
    ): Promise<Either<ValidationError | FallbackError, BudgetValidation>> {
        const body = this.budgetValidationMapper.mapToEditDto(validation);

        const responseResult = await this.http.put<BudgetValidationDto>(
            `${ENDPOINTS.VALIDATIONS}${validation.id}/`,
            body,
        );

        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .flatMap((response) => {
                const createdValidation = this.budgetValidationMapper.map(
                    plainToClass(BudgetValidationDto, response.data),
                );

                if (!createdValidation) return Either.Left(new FallbackError());

                return Either.Right(createdValidation);
            });
    }

    async deleteValidation(
        id: number,
    ): Promise<Either<ValidationError | FallbackError, true>> {
        const responseResult = await this.http.delete(
            `${ENDPOINTS.VALIDATIONS}${id}/`,
        );

        return responseResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                )
                    return new ValidationError(error.data);
                return new FallbackError();
            })
            .map(() => true);
    }
}
