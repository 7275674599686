import type { Nullable } from "@core/domain/types/nullable.type";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import {
    BudgetCategory,
    BudgetCategoryGroup,
    BudgetCategoryGroups,
    BudgetColumn,
} from "@project/domain/models/budget/financial-entity-budget.model";
import { GetAllBudgetCategoriesByBudgetIdUseCase } from "@project/domain/usecases/budget/categories/get-all-budget-categories-by-budget-id.usecase";
import { GetAllCategoryGroupsByCategoryIdUseCase } from "@project/domain/usecases/budget/categories/groups/get-all-category-groups-by-category-id.usecase";
import { GetAllBudgetColumnsByBudgetIdUseCase } from "@project/domain/usecases/budget/columns/get-all-budget-columns-by-budget-id.usecase";
import { inject, injectable } from "inversify";
import { action, makeObservable, observable, runInAction } from "mobx";

@injectable()
export class BudgetValidationFormViewModel extends BaseViewModel {
    _budgetId: Nullable<number> = null;

    // Used to initialize group combos when editing a validation
    _initialCategoryId: Nullable<number> = null;
    _initialCategoryIdToCompare: Nullable<number> = null;

    constructor(
        @inject(GetAllBudgetColumnsByBudgetIdUseCase)
        private readonly getAllBudgetColumnsByBudgetIdUseCase: GetAllBudgetColumnsByBudgetIdUseCase,
        @inject(GetAllBudgetCategoriesByBudgetIdUseCase)
        private readonly getAllBudgetCategoriesByBudgetIdUseCase: GetAllBudgetCategoriesByBudgetIdUseCase,
        @inject(GetAllCategoryGroupsByCategoryIdUseCase)
        private readonly getAllCategoryGroupsByCategoryIdUseCase: GetAllCategoryGroupsByCategoryIdUseCase,
    ) {
        super();
        makeObservable(this);
    }

    @observable
    budgetColumns: BudgetColumn[] = [];

    @observable
    budgetCategories: BudgetCategory[] = [];

    @observable
    budgetCategoryGroups: BudgetCategoryGroup[] = [];

    @observable
    loadingBudgetCategoryGroups: boolean = false;

    @observable
    budgetCategoryGroupsToCompare: BudgetCategoryGroup[] = [];

    @observable
    loadingBudgetCategoryGroupsToCompare: boolean = false;

    override async didMount(): Promise<void> {
        await super.didMount();
        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        const requests = [this.getBudgetColumns(), this.getBudgetCategories()];

        if (this._initialCategoryId) {
            requests.push(
                this.getBudgetCategoryGroups(this._initialCategoryId),
            );
        }

        if (this._initialCategoryIdToCompare) {
            requests.push(
                this.getBudgetCategoryGroupsToCompare(
                    this._initialCategoryIdToCompare,
                ),
            );
        }

        await Promise.all(requests);
    }

    async getBudgetColumns(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const budgetColumns =
            await this.getAllBudgetColumnsByBudgetIdUseCase.execute(
                this._budgetId,
            );

        runInAction(() => {
            this.budgetColumns = budgetColumns.budgetColumns;
        });
    }

    async getBudgetCategories(): Promise<void> {
        if (!this._budgetId) {
            return;
        }
        const budgetCategories =
            await this.getAllBudgetCategoriesByBudgetIdUseCase.execute(
                this._budgetId,
            );

        runInAction(() => {
            this.budgetCategories = budgetCategories.budgetCategories;
        });
    }

    private async loadBudgetCategoryGroups(
        categoryId: number,
    ): Promise<BudgetCategoryGroups> {
        return this.getAllCategoryGroupsByCategoryIdUseCase.execute(categoryId);
    }

    async getBudgetCategoryGroups(categoryId: number): Promise<void> {
        runInAction(() => {
            this.loadingBudgetCategoryGroups = true;
        });

        const categoryGroups = await this.loadBudgetCategoryGroups(categoryId);

        runInAction(() => {
            this.budgetCategoryGroups = categoryGroups.budgetCategoryGroups;
            this.loadingBudgetCategoryGroups = false;
        });
    }

    @action
    clearCategoryGroups(): void {
        this.budgetCategoryGroups = [];
    }

    async getBudgetCategoryGroupsToCompare(categoryId: number): Promise<void> {
        runInAction(() => {
            this.loadingBudgetCategoryGroupsToCompare = true;
        });

        const categoryGroups = await this.loadBudgetCategoryGroups(categoryId);

        runInAction(() => {
            this.budgetCategoryGroupsToCompare =
                categoryGroups.budgetCategoryGroups;
            this.loadingBudgetCategoryGroupsToCompare = false;
        });
    }

    @action
    clearCategoryGroupsToCompare(): void {
        this.budgetCategoryGroupsToCompare = [];
    }
}
