import {
    Undefinable,
    convertEmptyToUndefined,
} from "@core/domain/types/undefinable.type";
import { CreateProjectBody } from "@project/data/dto/create-project.body";
import { CatalogueEnumMapper } from "@project/data/mappers/catalogue-enum.mapper";
import { StatusEnumMapper } from "@project/data/mappers/status-enum.mapper";
import { CreateProject } from "@project/domain/models/create-project.model";
import { DesignPlanification } from "@project/domain/models/project.model";
import { CreateProjectFormValuesValidated } from "@project/presentation/components/project-form/project-form.component";
import { inject, injectable } from "inversify";
import { TerritorialScopeEnumMapper } from "./territorial-scope-enum.mapper";

@injectable()
export class CreateProjectMapper {
    constructor(
        @inject(CatalogueEnumMapper)
        private readonly catalogueEnumMapper: CatalogueEnumMapper,
        @inject(StatusEnumMapper)
        private readonly statusEnumMapper: StatusEnumMapper,
        @inject(TerritorialScopeEnumMapper)
        private readonly territorialScopeTypeEnumMapper: TerritorialScopeEnumMapper,
    ) {}

    mapFromProjectFormValues(
        projectForm: CreateProjectFormValuesValidated,
    ): CreateProject {
        let designPlanification: Undefinable<DesignPlanification> = undefined;
        if (
            Boolean(projectForm.general.designCreationDate) ||
            Boolean(projectForm.general.designResolutionDate)
        ) {
            designPlanification = new DesignPlanification(
                projectForm.general.designCreationDate,
                projectForm.general.designResolutionDate,
            );
        }

        const newProject = new CreateProject(
            projectForm.general.name,
            projectForm.general.year,
            projectForm.general.statusId,
        );

        if (projectForm.general.notes)
            newProject.notes = projectForm.general.notes;
        if (projectForm.general.description)
            newProject.description = projectForm.general.description;
        if (projectForm.general.territorialScopeId)
            newProject.territorialScopeId =
                projectForm.general.territorialScopeId;
        if (projectForm.general.serviceIds)
            newProject.catalogueIds = projectForm.general.serviceIds;
        if (projectForm.general.documents)
            newProject.documents = projectForm.general.documents;
        if (projectForm.general.isActive)
            newProject.isActive = projectForm.general.isActive;

        return newProject;
    }

    mapToDto(
        entityId: number,
        createProject: CreateProject,
    ): CreateProjectBody {
        const catalogue = createProject.catalogueIds?.map((catalogueDto) =>
            this.catalogueEnumMapper.mapToDto(catalogueDto),
        );
        const status = this.statusEnumMapper.mapToDto(createProject.statusId);

        const territorialScopeId =
            createProject.territorialScopeId &&
            this.territorialScopeTypeEnumMapper.mapToDto(
                createProject.territorialScopeId,
            );

        return {
            entity: entityId,
            name: createProject.name,
            year: createProject.year,
            notes: convertEmptyToUndefined(createProject.notes),
            description: convertEmptyToUndefined(createProject.description),
            catalogue: convertEmptyToUndefined(catalogue),
            status,
            territorial_scope: convertEmptyToUndefined(territorialScopeId),
            documents: convertEmptyToUndefined(
                createProject.documents?.map((document) => document.id),
            ),
            is_active: convertEmptyToUndefined(createProject.isActive),
        };
    }
}
