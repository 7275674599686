import { Pagination } from "@core/domain/models/pagination";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { SupplierType } from "@entity/domain/models/supplier/supplier-type.model";
import {
    SupplierSearchFilters,
    Suppliers,
} from "@entity/domain/models/supplier/suppliers.model";
import { DeleteSupplierUseCase } from "@entity/domain/usecases/supplier/delete-supplier.usecase";
import { GetAllSupplierTypesUseCase } from "@entity/domain/usecases/supplier/get-all-suppplier-types.usecase";
import { GetSuppliersByUseCase } from "@entity/domain/usecases/supplier/get-suppliers-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface SupplierListTable {
    id: number;
    companyName: string;
    identification: string;
    type: string;
}

interface SuppliersListTable {
    suppliers: SupplierListTable[];
    count: number;
}
@injectable()
export class SupplierListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    supplierTypes: SupplierType[] = [];

    @computed
    get suppliersTable(): SuppliersListTable {
        return {
            count: this._suppliers.count,
            suppliers: this._suppliers.suppliers.map((supplier) => ({
                id: supplier.id,
                companyName: supplier.companyName,
                identification: supplier.identification,
                type: supplier.type,
            })),
        };
    }

    @observable
    private _suppliers: Suppliers = new Suppliers([], 0);

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: SupplierSearchFilters = {
        search: undefined,
        active: true,
        dateTo: undefined,
        dateFrom: undefined,
    };

    @observable
    filters: SupplierSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(DeleteSupplierUseCase)
        private readonly deleteSupplierUseCase: DeleteSupplierUseCase,
        @inject(GetSuppliersByUseCase)
        private readonly getSuppliersByUseCase: GetSuppliersByUseCase,
        @inject(GetAllSupplierTypesUseCase)
        private readonly getAllSupplierTypesUseCase: GetAllSupplierTypesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchSuppliersBy(this.filters, this.pagination),
            this.getAllSupplierTypes(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: SupplierSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchSuppliersBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchSuppliersBy(this.filters, this.pagination);
    }

    async searchSuppliersBy(
        filters: SupplierSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const suppliers = await this.getSuppliersByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._suppliers = suppliers;
        });
    }

    async getAllSupplierTypes(): Promise<void> {
        const supplierTypes = await this.getAllSupplierTypesUseCase.execute();

        runInAction(() => {
            this.supplierTypes = supplierTypes;
        });
    }

    async deleteSupplier(supplierId: number): Promise<void> {
        LoadLayoutStore.start();
        await this.deleteSupplierUseCase.execute(supplierId);
        LoadLayoutStore.finish();
    }
}
