import { Nullable } from "@core/domain/types/nullable.type";
import { LoadLayoutStore } from "@core/presentacion/component/feedback/load-layout/load-layout.store";
import { ToastManagerStore } from "@core/presentacion/component/feedback/toast-manager/toast-manager.store";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { CreateEntityMapper } from "@entity/data/mappers/create-entity.mapper";
import { Entity } from "@entity/domain/models/entity.model";
import { CreateEntityUseCase } from "@entity/domain/usecases/entity/create-entity.usecase";
import { inject, injectable } from "inversify";
import { makeObservable } from "mobx";
import { CreateEntityFormValuesValidated } from "../../../component/entity-form/entity-form.component";

@injectable()
export class CreateEntityPageViewModel extends BaseViewModel {
    constructor(
        @inject(CreateEntityUseCase)
        private readonly createEntityUseCase: CreateEntityUseCase,
        private readonly createEntityMapper: CreateEntityMapper,
    ) {
        super();
        makeObservable(this);
    }

    async createEntity(
        values: CreateEntityFormValuesValidated,
    ): Promise<Nullable<Entity>> {
        LoadLayoutStore.start();

        const createEntityResult = await this.createEntityUseCase.execute(
            this.createEntityMapper.mapFromEntityFormValues(values),
        );

        if (createEntityResult) {
            ToastManagerStore.success();
        }

        LoadLayoutStore.finish();

        return createEntityResult;
    }
}
