import { Pagination } from "@core/domain/models/pagination";
import { BaseViewModel } from "@core/presentacion/view-model/base/base.viewmodel";
import { GlobalEntity } from "@entity/domain/models/global-entity.model";
import { MaterialType } from "@entity/domain/models/material/material-type.model";
import {
    Materials,
    type MaterialSearchFilters,
} from "@entity/domain/models/material/material.model";
import { GetAllGlobalEntitiesUseCase } from "@entity/domain/usecases/get-all-global-entities.usecase";
import { GetAllMaterialTypesUseCase } from "@entity/domain/usecases/material/get-all-material-types.usecase";
import { SearchMaterialsByUseCase } from "@entity/domain/usecases/material/search-materials-by.usecase";
import { inject, injectable } from "inversify";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";

export interface MaterialListTable {
    id: number;
    name: string;
    typeLabel: string;
    quantity: number;
    totalCost: number;
}

interface MaterialsListTable {
    materials: MaterialListTable[];
    count: number;
}

@injectable()
export class MaterialListPageViewModel extends BaseViewModel {
    @observable
    initialLoading: boolean = true;

    @observable
    materialTypes: MaterialType[] = [];

    @observable
    globalEntities: GlobalEntity[] = [];

    @computed
    get materialsTable(): MaterialsListTable {
        return {
            count: this._materials.count,
            materials: this._materials.materials.map((material) => ({
                id: material.id,
                name: material.name,
                typeLabel: material.typeLabel,
                quantity: material.count,
                totalCost: material.anualCost,
            })),
        };
    }

    @observable
    private _materials: Materials = new Materials([], 0);

    @observable
    pagination: Pagination = new Pagination();

    initialFiltersValue: MaterialSearchFilters = {
        name: "",
        type: undefined,
    };

    @observable
    filters: MaterialSearchFilters = this.initialFiltersValue;

    constructor(
        @inject(GetAllMaterialTypesUseCase)
        private readonly getAllMaterialTypesUseCase: GetAllMaterialTypesUseCase,
        @inject(SearchMaterialsByUseCase)
        private readonly searchMaterialsByUseCase: SearchMaterialsByUseCase,
        @inject(GetAllGlobalEntitiesUseCase)
        private readonly getAllGlobalEntitiesUseCase: GetAllGlobalEntitiesUseCase,
    ) {
        super();
        makeObservable(this);
    }

    override async didMount(): Promise<void> {
        await super.didMount();

        await this.initViewData();
    }

    async initViewData(): Promise<void> {
        await Promise.all([
            this.searchMaterialsBy(this.filters, this.pagination),
            this.getAllMaterialTypes(),
            this.getAllGlobalEntities(),
        ]);

        runInAction(() => {
            this.initialLoading = false;
        });
    }

    @action
    setFilters(filters: MaterialSearchFilters): void {
        this.filters = filters;
        this.pagination.reset();

        this.searchMaterialsBy(this.filters, this.pagination);
    }

    @action
    setPagination(page: number, pageSize: number): void {
        this.pagination.page = page;
        this.pagination.pageSize = pageSize;

        this.searchMaterialsBy(this.filters, this.pagination);
    }

    async searchMaterialsBy(
        filters: MaterialSearchFilters,
        pagination: Pagination,
    ): Promise<void> {
        const materials = await this.searchMaterialsByUseCase.execute(
            pagination,
            filters,
        );
        runInAction(() => {
            this._materials = materials;
        });
    }

    async getAllMaterialTypes(): Promise<void> {
        const materialTypes = await this.getAllMaterialTypesUseCase.execute();

        runInAction(() => {
            this.materialTypes = materialTypes;
        });
    }

    async getAllGlobalEntities(): Promise<void> {
        const globalEntities = await this.getAllGlobalEntitiesUseCase.execute();

        runInAction(() => {
            this.globalEntities = globalEntities;
        });
    }
}
