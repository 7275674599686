import { Undefinable } from "@core/domain/types/undefinable.type";
import { OrderedMap } from "immutable";

export enum BudgetSpreadsheetValidationTypeEnum {
    Amount = "amount",
    Percentage = "percentage",
}

export class BudgetSpreadsheetColumn {
    constructor(
        public readonly id: number,
        public readonly label: string,
        public readonly type: BudgetSpreadsheetValidationTypeEnum,
    ) {}
}

export class BudgetSpreadsheetPercentageValidation {
    constructor(
        public readonly validateAgainstTotal: boolean,
        public readonly categoryIdToCompare: Undefinable<number>,
        public readonly groupIdToCompare: Undefinable<number>,
    ) {}
}

export class BudgetSpreadsheetValidation {
    constructor(
        public readonly columnId: number,
        public readonly type: BudgetSpreadsheetValidationTypeEnum,
        public readonly valueToCompare: number,
        public message: string,
        public percentage?: BudgetSpreadsheetPercentageValidation,
    ) {}
}

export type ColumnId = number;

export type BudgetSpreadsheetValidations = OrderedMap<
    ColumnId,
    BudgetSpreadsheetValidation
>;

export class BudgetSpreadsheetValue {
    constructor(
        public readonly id: number,
        public readonly columnId: number,
        public readonly value: number,
    ) {}
}

type ValueId = number;

export class BudgetSpreadsheetItem {
    constructor(
        public readonly id: number,
        public readonly label: string,
        public readonly values: OrderedMap<ValueId, BudgetSpreadsheetValue>,
    ) {}
}

type ItemId = number;

export class BudgetSpreadsheetGroup {
    constructor(
        public readonly id: number,
        public readonly label: string,
        public readonly items: OrderedMap<ItemId, BudgetSpreadsheetItem>,
        public readonly validations?: BudgetSpreadsheetValidations,
    ) {}
}

type GroupId = number;

export class BudgetSpreadsheetCategory {
    constructor(
        public readonly id: number,
        public readonly label: string,
        public readonly group: OrderedMap<GroupId, BudgetSpreadsheetGroup>,
        public readonly itemsWithoutGroup?: OrderedMap<
            ItemId,
            BudgetSpreadsheetItem
        >,
        public readonly validations?: BudgetSpreadsheetValidations,
    ) {}
}

type CategoryId = number;

export class BudgetSpreadsheet {
    constructor(
        public readonly columns: OrderedMap<number, BudgetSpreadsheetColumn>,
        public readonly categories: OrderedMap<
            CategoryId,
            BudgetSpreadsheetCategory
        >,
    ) {}
}
