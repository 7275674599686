import { DocumentDto } from "@core/data/dto/document.dto";
import { PaginatedDto } from "@core/data/dto/paginated.dto";
import type { Nullable } from "@core/domain/types/nullable.type";
import { Type } from "class-transformer";
import {
    IsArray,
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsNumberString,
    IsOptional,
    IsString,
} from "class-validator";
import { FinancingTypeEnumDto } from "./financial-entity/financing-type.dto";

export class ProjectFinancialEntityDto {
    @IsNumber()
    @IsNotEmpty()
    public readonly id!: number;

    @IsNumber()
    @IsNotEmpty()
    public readonly project!: number;

    @IsBoolean()
    @IsOptional()
    public readonly is_granted!: Nullable<boolean>;

    @IsEnum(FinancingTypeEnumDto)
    @IsOptional()
    public readonly financing_type!: Nullable<FinancingTypeEnumDto>;

    @IsString()
    @IsOptional()
    public readonly other_financing_type!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    public readonly finantial_entity!: number;

    @IsNumberString()
    @IsOptional()
    public readonly amount_request!: Nullable<string>;

    @IsNumberString()
    @IsOptional()
    public readonly amount!: Nullable<string>;

    @IsNumberString()
    @IsOptional()
    public readonly percentage!: Nullable<string>;

    @IsNumber()
    @IsNotEmpty()
    public readonly budget!: number;

    @IsString()
    @IsOptional()
    public readonly convocation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly publication_convocation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly presentation_convocation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly requirement_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly end_requirement_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly presentation_requirement_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly communication_resolution_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly communication_reformulation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly end_reformulation_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly presentation_resolution_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly end_allegations_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    public readonly end_resource_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    intermediate_justification_deadline!: Nullable<string>;

    @IsString()
    @IsOptional()
    intermediate_justification_delivery_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    final_justification_deadline!: Nullable<string>;

    @IsString()
    @IsOptional()
    final_justification_delivery_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    requirement_reception_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    requirement_response_deadline!: Nullable<string>;

    @IsString()
    @IsOptional()
    requirement_response_date!: Nullable<string>;

    @IsString()
    @IsOptional()
    justification_additional_notes!: Nullable<string>;

    @IsBoolean()
    @IsOptional()
    has_intermediate_justification!: Nullable<boolean>;

    @IsArray()
    @IsNumber(undefined, { each: true })
    @IsOptional()
    public readonly supporting_documents!: Nullable<number[]>;

    @IsArray()
    @IsOptional()
    @Type(() => DocumentDto)
    public readonly supporting_documents_data!: Nullable<DocumentDto[]>;
}

export class ProjectFinancialEntitiesDto extends PaginatedDto {
    @IsArray()
    @Type(() => ProjectFinancialEntityDto)
    public readonly results!: ProjectFinancialEntityDto[];
}

export type ProjectFinancialEntitiesQuery = {
    project?: number;
    finantial_entity?: number;
    budget?: number;
};
