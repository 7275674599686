import { coreTypes } from "@core/core-types.di";
import { HttpFailedRequestError } from "@core/data/infrastructures/http/errors/http-failed-request.error";
import { type Http } from "@core/data/infrastructures/http/http";
import { HttpErrorCodeEnum } from "@core/data/infrastructures/http/http-error-response";
import { FallbackError } from "@core/domain/errors/fallback.error";
import { ValidationError } from "@core/domain/errors/validation.error";
import { Pagination } from "@core/domain/models/pagination";
import { Either } from "@core/domain/types/either";
import { MaterialSubtype } from "@entity/domain/models/material/material-subtype.model";
import { plainToClass } from "class-transformer";
import { inject, injectable } from "inversify";
import { MaterialType } from "../../domain/models/material/material-type.model";
import {
    CreateMaterial,
    Material,
    MaterialSearchFilters,
    Materials,
} from "../../domain/models/material/material.model";
import { MetricUnit } from "../../domain/models/material/metric-unit.model";
import { MaterialSubtypeDto } from "../dto/material/material-subtype-enum.dto";
import {
    MaterialTypeDto,
    MaterialTypeEnumDto,
} from "../dto/material/material-type-enum.dto";
import { MaterialDto } from "../dto/material/material.dto";
import {
    MaterialsDto,
    MaterialsSummaryQuery,
} from "../dto/material/materials.dto";
import { MetricUnitDto } from "../dto/material/metric-unit-enum.dto";
import { MaterialSubtypeMapper } from "../mappers/material/material-subtype.mapper";
import { MaterialTypeEnumMapper } from "../mappers/material/material-type-enum.mapper";
import { MaterialTypeMapper } from "../mappers/material/material-type.mapper";
import { MaterialMapper } from "../mappers/material/material.mapper";
import { MaterialsMapper } from "../mappers/material/materials.mapper";
import { MetricUnitMapper } from "../mappers/material/metric-unit.mapper";

const MATERIAL_PATH = "/materials/";
@injectable()
export class MaterialDatasource {
    constructor(
        @inject(coreTypes.infrastructure.Http)
        private readonly http: Http,
        @inject(MaterialTypeEnumMapper)
        private readonly materialTypeEnumMapper: MaterialTypeEnumMapper,
        @inject(MaterialTypeMapper)
        private readonly materialTypeMapper: MaterialTypeMapper,
        @inject(MaterialSubtypeMapper)
        private readonly materialSubtypeMapper: MaterialSubtypeMapper,
        @inject(MaterialsMapper)
        private readonly materialsMapper: MaterialsMapper,
        @inject(MaterialMapper)
        private readonly materialMapper: MaterialMapper,
        @inject(MetricUnitMapper)
        private readonly metricUnitMapper: MetricUnitMapper,
    ) {}

    async fetchById(
        materialId: number,
    ): Promise<Either<FallbackError, Material>> {
        const materialResult = await this.http.get<MaterialDto>(
            `${MATERIAL_PATH}${materialId}`,
        );

        return materialResult
            .mapLeft(() => new FallbackError())
            .flatMap((response) => {
                const material = this.materialMapper.map(
                    plainToClass(MaterialDto, response.data),
                );

                if (!material) return Either.Left(new FallbackError());

                return Either.Right(material);
            });
    }

    async fetchAllBy(
        pagination: Pagination,
        filters?: MaterialSearchFilters,
    ): Promise<Either<FallbackError, Materials>> {
        const query: MaterialsSummaryQuery = {
            limit: pagination.pageSize,
            offset: pagination.offset,
        };
        if (filters?.name) query.search = filters.name;
        if (filters?.type)
            query.type = this.materialTypeEnumMapper.mapToDto(filters.type);
        if (filters?.entityIds) query.entities = filters.entityIds.join(",");

        const materialsResult = await this.http.get<MaterialsDto>(
            MATERIAL_PATH,
            {
                query,
            },
        );

        return materialsResult
            .map((response) =>
                this.materialsMapper.map(
                    plainToClass(MaterialsDto, response.data),
                ),
            )
            .mapLeft(() => new FallbackError());
    }

    async fetchAllTypes(): Promise<Either<FallbackError, MaterialType[]>> {
        const responseResult = await this.http.get<MaterialTypeDto[]>(
            `${MATERIAL_PATH}types/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((typeDto) =>
                    this.materialTypeMapper.map(
                        plainToClass(MaterialTypeDto, typeDto),
                    ),
                ),
            );
    }

    async fetchAllSubtypesByTypeId(
        typeId: MaterialTypeEnumDto,
    ): Promise<Either<FallbackError, MaterialSubtype[]>> {
        const responseResult = await this.http.get<MaterialSubtypeDto[]>(
            `${MATERIAL_PATH}subtypes/?type=${typeId}`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((subtypeDto) =>
                    this.materialSubtypeMapper.map(
                        plainToClass(MaterialSubtypeDto, subtypeDto),
                    ),
                ),
            );
    }

    async fetchAllMetricUnits(): Promise<Either<FallbackError, MetricUnit[]>> {
        const responseResult = await this.http.get<MetricUnitDto[]>(
            `${MATERIAL_PATH}metric_unit/`,
        );

        return responseResult
            .mapLeft(() => new FallbackError())
            .map((response) =>
                response.data.mapNotNull((metricUnitDto) =>
                    this.metricUnitMapper.map(
                        plainToClass(MetricUnitDto, metricUnitDto),
                    ),
                ),
            );
    }

    async create(
        material: CreateMaterial,
    ): Promise<Either<ValidationError | FallbackError, Material>> {
        const materialDto =
            this.materialMapper.mapToCreateMaterialDto(material);
        const createMaterialResult = await this.http.post<MaterialDto>(
            MATERIAL_PATH,
            materialDto,
        );
        return createMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const materialCreated = this.materialMapper.map(
                    plainToClass(MaterialDto, response.data),
                );
                if (!materialCreated) return Either.Left(new FallbackError());
                return Either.Right(materialCreated);
            });
    }

    async edit(
        material: Material,
    ): Promise<Either<ValidationError | FallbackError, Material>> {
        const materialDto = this.materialMapper.mapToMaterialDto(material);
        const editMaterialResult = await this.http.patch<MaterialDto>(
            `${MATERIAL_PATH}${material.id}/`,
            materialDto,
        );
        return editMaterialResult
            .mapLeft((error) => {
                if (
                    error instanceof HttpFailedRequestError &&
                    error.errorCode === HttpErrorCodeEnum.GenericError
                ) {
                    return new ValidationError(error.data);
                }

                return new FallbackError();
            })
            .flatMap((response) => {
                const materialEdited = this.materialMapper.map(
                    plainToClass(MaterialDto, response.data),
                );
                if (!materialEdited) return Either.Left(new FallbackError());
                return Either.Right(materialEdited);
            });
    }
}
